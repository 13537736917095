import { Box } from "@mui/material"
import type { FC } from "react"
import { useFormContext } from "react-hook-form"

import AutoComplete from "@/components/common/Autocomplete/AutoComplete.tsx"
import { Button } from "@/components/common/Button"
import type { AccountManager } from "@/graphql/codegen/graphql.ts"
import AccountManagerSelect from "@/screens/Companies/components/AccountManagerSelect"
import CompanyTypeSelect from "@/screens/Companies/components/CompanyTypeSelect.tsx"
import type { TreeNode } from "@/types/tree.ts"

interface Props {
  companyNameList: string[]
  companyTypeList: TreeNode<string>[]
  accountsManagerList: AccountManager[]
  onChange: (name: string) => void
  handleCancel: () => void
  onSubmit: () => void
}

export const EditCompanyForm: FC<Props> = ({
  companyNameList,
  companyTypeList,
  accountsManagerList,
  onChange,
  handleCancel,
  onSubmit,
}) => {
  const { control } = useFormContext()

  return (
    <form onSubmit={onSubmit} className='space-y-8 p-10'>
      <Box>
        <AutoComplete
          onInputChange={onChange}
          label='Company Name'
          placeholder='Company Name'
          id='company-name-field'
          options={companyNameList}
          name='name'
          control={control}
        />
      </Box>
      <Box>
        <CompanyTypeSelect companyTypeTree={companyTypeList} name='types' />
      </Box>
      <Box>
        <AccountManagerSelect accountManagers={accountsManagerList} name='accountManagers' />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Button appearance='outlined' onClick={handleCancel}>
          Cancel
        </Button>
        <Button appearance='contained' type='submit'>
          Submit
        </Button>
      </Box>
    </form>
  )
}
