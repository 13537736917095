import { useMemo } from "react"

import { getConsolidatedValue } from "../../../../utils/getConsolidatedValue.ts"
import type { ProductSelectionItem } from "../../Supplier/types/types"

import { useGetPurchaseOrderIncotermsLocations } from "./useGetPurchaseOrderIncotermsLocations"
import { useGetPurchaseOrderShipToLocation } from "./useGetPurchaseOrderShipToLocation"

import { type Company, type FreightTerms, type Incoterms, type Location } from "@/graphql/codegen/graphql"
import { useGetAssociatedLocations } from "@/screens/Companies/hooks/useGetAssociatedLocations.tsx"
import { useMergedLocations } from "@/screens/Companies/products/hooks/__tests__/useMergedLocations.ts"
import type { LocationAssociation } from "@/screens/Products/components/ProductShipLocationSelect"
import { useGetMultipleProductsPricing } from "@/screens/Products/hooks/useGetMultipleProductsPricing.tsx"

type UseGetStartPurchaseOrderDataReturn = {
  shipToLocations: LocationAssociation[]
  shipToLocationListFetching: boolean
  loadMore: () => void
  freightTerms: FreightTerms | undefined
  incoterms: Incoterms | undefined
  incotermsLocation: Location | undefined
  pricingListFetching: boolean
  shippingViaCompanyId: string | null
}

// Facade hook to facilitate fetching pricing data for the purchase order when location association is not present
const usePricingData = (selectedCompany: Company, selectedProducts: ProductSelectionItem[]) => {
  // Get the location association ID from the product's locations associations
  const locationAssociationId = useMemo(() => {
    const productWithLocations = selectedProducts.find(
      (product) => Array.isArray(product.locationsAssociations) && product.locationsAssociations.length > 0
    )

    return productWithLocations?.locationsAssociations?.[0]?.locationAssociationId || null
  }, [selectedProducts])

  const productIds = useMemo(() => selectedProducts.map(({ productId }) => productId), [selectedProducts])

  const { pricingList, pricingListFetching } = useGetMultipleProductsPricing({
    filterSpecs: {
      companyId: selectedCompany?.companyId ?? null,
      productIds,
      sourceCompanyLocationAssociationId: locationAssociationId ?? "",
    },
  })

  if (!locationAssociationId) {
    return {
      pricingList: { currentPricing: undefined, futurePricing: [] },
      pricingListFetching,
    }
  }

  return { pricingList, pricingListFetching }
}

export const useGetStartPurchaseOrderData = ({
  selectedProducts,
  selectedCompany,
}: {
  selectedProducts: ProductSelectionItem[]
  selectedCompany: Company
}): UseGetStartPurchaseOrderDataReturn => {
  const { shipToLocations, shipToLocationListFetching, loadMore } = useGetPurchaseOrderShipToLocation()
  const { locations: incotermLocations } = useGetPurchaseOrderIncotermsLocations()
  const { associatedLocationList } = useGetAssociatedLocations({
    companyId: selectedCompany?.companyId || "",
  })

  const shippingViaCompanyId = useMemo(() => {
    if (!associatedLocationList || !associatedLocationList.length) {
      return null
    }

    const ids = associatedLocationList.map((item) => item.shippingVia?.companyId ?? null).filter(Boolean)

    if (!ids.length) {
      return null
    }

    const firstId = ids[0]
    const allSame = ids.every((id) => id === firstId)

    return allSame ? firstId : null
  }, [associatedLocationList])

  const { pricingList, pricingListFetching } = usePricingData(selectedCompany, selectedProducts)

  const pricing = useMemo(() => {
    if (pricingList.currentPricing) return pricingList.currentPricing
    if (pricingList.futurePricing.length > 0) return pricingList.futurePricing
    return []
  }, [pricingList])

  const consolidatedData = useMemo(() => {
    return {
      freightTerms: getConsolidatedValue(pricing, "freightTerms"),
      incoterms: getConsolidatedValue(pricing, "incoterms"),
      incotermsLocationId: getConsolidatedValue(pricing, "incotermsLocationId"),
    }
  }, [pricing])

  const freightTerms = useMemo(() => consolidatedData?.freightTerms as FreightTerms, [consolidatedData])
  const incoterms = useMemo(() => consolidatedData?.incoterms as Incoterms, [consolidatedData])

  const mergedLocations = useMergedLocations(selectedProducts, incotermLocations)

  const incotermsLocation = useMemo(
    () => mergedLocations.find(({ locationId }) => locationId === consolidatedData?.incotermsLocationId),
    [mergedLocations, consolidatedData]
  )

  return {
    shipToLocations,
    shipToLocationListFetching,
    loadMore,
    pricingListFetching,
    incotermsLocation,
    freightTerms,
    incoterms,
    shippingViaCompanyId,
  }
}
