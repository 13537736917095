import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material"
import { DeleteOutlined, EditOutlined, MoreVertOutlined } from "@mui-symbols-material/w300"
import type { FC } from "react"
import { useCallback, useState } from "react"

import type { ExpanderCellProps } from "../types"

import type { RelatedProductInstruction } from "@/graphql/codegen/graphql"

export const InstructionGroupDropdown: FC<ExpanderCellProps> = ({ row, onEdit, onDelete }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleEdit = useCallback(
    (instruction: RelatedProductInstruction) => {
      onEdit(instruction)
      handleClose()
    },
    [onEdit]
  )

  const handleDelete = useCallback(
    (instruction: RelatedProductInstruction) => {
      onDelete(instruction)
      handleClose()
    },
    [onDelete]
  )

  return (
    <>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <MoreVertOutlined />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleEdit(row.original)}>
          <ListItemIcon>
            <EditOutlined />
          </ListItemIcon>
          <ListItemText primary='Edit' />
        </MenuItem>
        <MenuItem onClick={() => handleDelete(row.original)}>
          <ListItemIcon>
            <DeleteOutlined />
          </ListItemIcon>
          <ListItemText primary='Delete' />
        </MenuItem>
      </Menu>
    </>
  )
}
