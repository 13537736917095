import { Box, TextField } from "@mui/material"
import { HelpOutlined } from "@mui-symbols-material/w300"
import React, { type FC } from "react"
import { Controller, useFormContext } from "react-hook-form"

import { InputFieldClasses } from "@/components/Contact/PhoneNumberField.tsx"
import type { ContactCreateInput } from "@/graphql/codegen/graphql.ts"

const EmailField: FC = () => {
  const { control } = useFormContext<ContactCreateInput>()

  return (
    <Controller
      name='email'
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Box className='relative'>
          <label className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>Email</label>
          <TextField
            {...field}
            InputProps={{
              endAdornment: <HelpOutlined />,
              classes: {
                input: InputFieldClasses,
              },
            }}
            placeholder='name@domain.com'
            fullWidth
            variant='outlined'
            helperText={error?.message}
            error={!!error}
          />
        </Box>
      )}
    />
  )
}

export default EmailField
