import { Collapse, Paper } from "@mui/material"
import { AddOutlined, WarningOutlined } from "@mui-symbols-material/w300"
import { useParams } from "@tanstack/react-router"
import dayjs from "dayjs"
import type { ReactElement } from "react"
import { useCallback, useMemo, useState } from "react"
import { useMutation, useQuery } from "urql"

import type { SignedUrl } from "../ProductForm/types"

import { Header } from "./components/Header"
import { LabelInformationContent } from "./LabelInformationContent"
import { LabelInformationDialog } from "./LabelInformationDialog"
import type { LabelInformation, LabelInformationFormData } from "./types"
import { mapSignedUrlsToPDFSizePattern } from "./types"
import { ViewLabelsDialog } from "./ViewLabelsDialog"

import { InformationMissingAlert } from "@/components/common/Alerts/InformationMissingAlert"
import { Button } from "@/components/common/Button"
import type { GetFileInfoQuery, GetFileInfoQueryVariables } from "@/graphql/codegen/graphql"
import {
  FieldGroupEnum,
  GetFileInfoDocument,
  ProductLabelInfoDocument,
  ProductUpdateDocument,
  ReferenceTableName,
} from "@/graphql/codegen/graphql"
import { useImageUpload } from "@/hooks/useImageUpload"
import { usePDFUpload } from "@/hooks/usePDFUpload"
import { queryClient } from "@/providers/GraphqlRouterProvider.tsx"
import { getLatestSignedUrls } from "@/screens/Products/routeLoader"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

const getLatestImageSignedUrl = (signedUrls: SignedUrl[]): SignedUrl => {
  return getLatestSignedUrls(
    signedUrls.filter(
      (signedUrl: SignedUrl) =>
        signedUrl.file?.originalName?.includes(".jpg") ||
        signedUrl.file?.originalName?.includes(".png") ||
        signedUrl.file?.originalName?.includes(".jpeg") ||
        signedUrl.file?.originalName?.includes(".gif")
    )
  ).sort((a, b) => {
    const aDate = dayjs(a.file?.createdOn)
    const bDate = dayjs(b.file?.createdOn)
    return bDate.diff(aDate) // Sort descending (newest first)
  })[0] as SignedUrl
}

export const LabelInformationContainer = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(false)
  const { productId } = useParams({ from: "/product/$productId/edit/_layout/information" })

  const [{ data }, getProductLabelInfo] = useQuery({
    query: ProductLabelInfoDocument,
    variables: { productId: Number(productId) },
  })

  const [{ data: fileUploadData }] = useQuery<GetFileInfoQuery, GetFileInfoQueryVariables>({
    query: GetFileInfoDocument,
    variables: {
      fileInfoInput: {
        referenceTableName: ReferenceTableName.Product,
        referenceId: productId.toString(),
        fieldGroups: [FieldGroupEnum.ProductLabel],
      },
    },
  })

  const getFileInfo = useCallback(async (): Promise<{ data?: GetFileInfoQuery }> => {
    const result = await queryClient
      .query<GetFileInfoQuery, GetFileInfoQueryVariables>(
        GetFileInfoDocument,
        {
          fileInfoInput: {
            referenceTableName: ReferenceTableName.Product,
            referenceId: productId.toString(),
            fieldGroups: [FieldGroupEnum.ProductLabel],
          },
        },
        { requestPolicy: "network-only" }
      )
      .toPromise()

    return { data: result.data }
  }, [productId])

  const { handleGetSignedUrl: handleGetPDFSignedUrl, handleUploadToS3: handleUploadPDFToS3 } = usePDFUpload({
    referenceTableName: ReferenceTableName.Product,
    getReferenceId: () => productId.toString(),
  })
  const { handleGetSignedUrl: handleGetImageSignedUrl, handleUploadToS3: handleUploadImageToS3 } = useImageUpload({
    referenceTableName: ReferenceTableName.Product,
    getReferenceId: () => productId.toString(),
    type: FieldGroupEnum.ProductLabel,
  })
  const [, updateProduct] = useMutation(ProductUpdateDocument)

  const notify = useNotificationsStore((state) => state.enqueueNotification)

  const fileData = useMemo(() => {
    if (!fileUploadData) return []

    if (fileUploadData.fileUpload.getFileInfo.__typename === "GetFileInfoFailure") {
      return []
    }

    return fileUploadData.fileUpload.getFileInfo.signedUrls
  }, [fileUploadData])

  const labelInfo = useMemo((): LabelInformation => {
    if (data?.product.get.__typename === "ProductGetSuccess") {
      return {
        isPrivateLabel: data.product.get.product.privateLabel ?? false,
        isPrintedBySupplier: data.product.get.product.labelPrintedBySupplier ?? null,
        isAppliedBySupplier: data.product.get.product.labelAppliedBySupplier ?? null,
      }
    }
    return {
      isPrivateLabel: false,
      isPrintedBySupplier: null,
      isAppliedBySupplier: null,
    }
  }, [data])

  const [expanded, setExpanded] = useState(true)
  const [openUpdateLabelInfo, setOpenUpdateLabelInfo] = useState(false)
  const [openViewLabels, setOpenViewLabels] = useState(false)
  const files = useMemo(
    () => ({
      pdfs: mapSignedUrlsToPDFSizePattern(fileData?.filter(({ file }) => file?.originalName?.includes(".pdf")) ?? []),
      image: getLatestImageSignedUrl(fileData ?? []),
    }),
    [fileData]
  )

  const hasFiles = useMemo(
    () => Boolean(Object.keys(files.pdfs)?.length) || Boolean(files.image),
    [files.pdfs, files.image]
  )

  const toggleExpanded = useCallback(() => setExpanded((prev) => !prev), [])
  const handleCloseUpdateLabelInfo = useCallback(() => setOpenUpdateLabelInfo(false), [])
  const handleCloseViewLabels = useCallback(() => setOpenViewLabels(false), [])
  const handleOpenViewLabels = useCallback(() => {
    setOpenViewLabels(true)
  }, [])

  const handleOpenUpdateLabelInfo = useCallback(() => {
    setOpenUpdateLabelInfo(true)
  }, [])

  const handleSubmit = useCallback(
    async (data: LabelInformationFormData) => {
      setIsLoading(true)
      try {
        // Track if we need to wait for file propagation
        let hasFileUploads = false

        // First update product metadata
        const updateResult = await updateProduct({
          input: {
            productId: Number(productId),
            privateLabel: data.isPrivateLabel,
            labelPrintedBySupplier: data.isPrintedBySupplier,
            labelAppliedBySupplier: data.isAppliedBySupplier,
          },
        })

        if (updateResult.error || updateResult.data?.product.update.__typename === "ProductUpdateFailure") {
          const errorMessage =
            updateResult.data?.product.update.__typename === "ProductUpdateFailure"
              ? updateResult.data.product.update.error.message
              : "Unable to update product information. Please try again."
          throw new Error(errorMessage)
        }

        // Handle file uploads
        if (data.image) {
          // Upload image
          const signedImageUrl = await handleGetImageSignedUrl(
            data.image,
            FieldGroupEnum.ProductLabel,
            "supplier_label"
          )
          if (!signedImageUrl) {
            throw new Error("Unable to prepare image upload. Please try again.")
          }

          const uploadSuccess = await handleUploadImageToS3(signedImageUrl, FieldGroupEnum.ProductLabel, data.image)
          if (!uploadSuccess) {
            throw new Error(`Unable to upload image: ${data.image.name}. Please try again.`)
          }
          hasFileUploads = true
        } else if (Object.keys(data.pdfs).length > 0) {
          // Get signed URLs for all PDFs
          const signedPdfUrls = await Promise.all(
            Object.entries(data.pdfs).map(async ([label, file]) => {
              const signedUrl = await handleGetPDFSignedUrl(file, FieldGroupEnum.ProductLabel, label)
              if (!signedUrl) {
                return null
              }
              return { signedUrl, file, label }
            })
          )

          const validUrls = signedPdfUrls.filter((item): item is NonNullable<typeof item> => item !== null)
          if (validUrls.length === 0) {
            throw new Error("Unable to prepare PDF uploads. Please try again.")
          }

          if (validUrls.length !== Object.keys(data.pdfs).length) {
            console.warn(`Only ${validUrls.length} of ${Object.keys(data.pdfs).length} PDFs prepared for upload`)
          }

          // Upload PDFs
          const uploadResults = await Promise.all(
            validUrls.map(async ({ signedUrl, file, label }) => {
              const success = await handleUploadPDFToS3(signedUrl, FieldGroupEnum.ProductLabel, file)
              return {
                success,
                label,
                fileName: file.name,
              }
            })
          )

          const failures = uploadResults.filter((result) => !result.success)
          if (failures.length > 0) {
            const failureMessages = failures.map((failure) => `Unable to upload ${failure.fileName}`).join("\n")
            throw new Error(`Some files could not be uploaded:\n${failureMessages}\nPlease try again.`)
          }

          hasFileUploads = uploadResults.length > 0
        }

        // Refresh product info after successful update
        getProductLabelInfo({ requestPolicy: "network-only" })

        // Only wait for S3 propagation if we uploaded files
        if (hasFileUploads) {
          // Wait for S3 propagation
          await new Promise((resolve) => setTimeout(resolve, 4000))

          // Verify files were uploaded successfully
          const fileInfoResult = await getFileInfo()
          const uploadedFiles = fileInfoResult.data?.fileUpload.getFileInfo

          if (uploadedFiles?.__typename === "GetFileInfoFailure" || !uploadedFiles) {
            throw new Error(
              "The product information was updated, but we couldn't verify the file uploads. Please check if the files appear correctly."
            )
          }
        }

        setOpenUpdateLabelInfo(false)
        setIsLoading(false)

        notify({
          message: hasFileUploads
            ? "Label information and files updated successfully"
            : "Label information updated successfully",
          type: "success",
        })
      } catch (error) {
        setIsLoading(false)
        notify({
          message: error instanceof Error ? error.message : "Unable to update label information. Please try again.",
          type: "error",
        })
      }
    },
    [
      updateProduct,
      productId,
      handleGetPDFSignedUrl,
      handleUploadPDFToS3,
      getProductLabelInfo,
      getFileInfo,
      handleGetImageSignedUrl,
      handleUploadImageToS3,
      notify,
      setIsLoading,
      setOpenUpdateLabelInfo,
    ]
  )

  const handleViewLabelsSubmit = useCallback(
    (data: Pick<LabelInformationFormData, "pdfs">) => {
      handleSubmit({
        ...labelInfo,
        ...data,
        image: null,
      })
    },
    [handleSubmit, labelInfo]
  )

  return (
    <>
      <Paper className='border border-gray-300 p-6' elevation={0}>
        <Header expanded={expanded} onExpandClick={toggleExpanded} />
        <Collapse in={expanded}>
          {!hasFiles ? (
            <InformationMissingAlert
              icon={<WarningOutlined color='primary' />}
              title='Add Product Label Information'
              action={
                <Button
                  color='inherit'
                  size='small'
                  appearance='outlined'
                  className='text-primary-500'
                  startIcon={<AddOutlined />}
                  onClick={handleOpenUpdateLabelInfo}
                  data-testid='add-label-info-button'
                >
                  Labeling Info
                </Button>
              }
              description={""}
              data-testid='product-label-info-alert'
            />
          ) : (
            <LabelInformationContent
              labelInfo={labelInfo}
              hasFiles={hasFiles}
              onEdit={handleOpenUpdateLabelInfo}
              onViewLabels={handleOpenViewLabels}
            />
          )}
        </Collapse>
      </Paper>

      <LabelInformationDialog
        open={openUpdateLabelInfo}
        onClose={handleCloseUpdateLabelInfo}
        onSave={handleSubmit}
        pdfFiles={files.pdfs}
        imageFile={files.image}
        labelInfo={labelInfo}
        isLoading={isLoading}
      />

      <ViewLabelsDialog
        open={openViewLabels}
        onClose={handleCloseViewLabels}
        onSave={handleViewLabelsSubmit}
        pdfFiles={files.pdfs}
        imageFile={files.image}
        labelInfo={labelInfo}
      />
    </>
  )
}
