import { Box, FormControlLabel, MenuItem, Switch, TextField } from "@mui/material"
import { AddOutlined, HelpOutlined } from "@mui-symbols-material/w300"
import React, { type FC, useCallback } from "react"
import { Controller, useFieldArray, useFormContext } from "react-hook-form"

import { Button } from "@/components/common/Button"
import { PhoneNumberInput } from "@/components/common/Phone/PhoneInput"
import { EndAdornmentSelect } from "@/components/TextFieldWithSelect/TextFieldWithSelect.tsx"
import { type ContactCreateInput, ContactType } from "@/graphql/codegen/graphql"

export const InputFieldClasses = "p-0 border-0 ring-0 outline-0 focus:border-0 focus:outline-none h-6 px-4 py-2"

const PhoneNumberInputWrapper = React.forwardRef((props: any, ref) => (
  <PhoneNumberInput
    {...props}
    ref={ref}
    inputProps={{
      classes: {
        input: "border-r-none",
      },
    }}
  />
))

const PhoneNumberField: FC = () => {
  const { control, getValues } = useFormContext<ContactCreateInput>()
  const { fields, append, update } = useFieldArray({
    control,
    name: "phoneNumber",
  })

  const handleAddPhone = useCallback(() => {
    append({ number: "", type: "Text", isPreferred: false })
  }, [append])

  const handlePreferredChange = useCallback(
    (index: number) => {
      fields.forEach((_, idx) => {
        const currentField = getValues(`phoneNumber.${idx}` as keyof ContactCreateInput) // Get current field values from form state
        update(idx, { ...currentField, isPreferred: idx === index }) // Update with current values
      })
    },
    [fields, getValues, update]
  )

  return (
    <Box>
      {fields.map((item, index) => (
        <Controller
          key={item.id}
          name={`phoneNumber.${index}.number` as keyof ContactCreateInput}
          control={control}
          render={({ field }) => (
            <Box className='relative'>
              <label className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>Phone</label>
              <TextField
                {...field}
                placeholder='+1 (123) 456-7890'
                fullWidth
                variant='outlined'
                InputProps={{
                  inputComponent: PhoneNumberInputWrapper, // Use the wrapper here
                  endAdornment: (
                    <Box className='flex items-center gap-4'>
                      <HelpOutlined />
                      <Controller
                        name={`phoneNumber.${index}.type` as keyof ContactCreateInput}
                        control={control}
                        render={({ field }) => (
                          <EndAdornmentSelect selectField={field}>
                            {Object.keys(ContactType).map((type) => (
                              <MenuItem key={type} value={type}>
                                {type}
                              </MenuItem>
                            ))}
                          </EndAdornmentSelect>
                        )}
                      />
                    </Box>
                  ),
                  classes: {
                    input: InputFieldClasses,
                  },
                }}
              />
              <Box className='mt-4 flex justify-between'>
                <Controller
                  name={`phoneNumber.${index}.isPreferred` as keyof ContactCreateInput}
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Switch {...field} checked={item.isPreferred} onChange={() => handlePreferredChange(index)} />
                      }
                      label='Preferred'
                    />
                  )}
                />
                {index === fields.length - 1 && (
                  <Button variant='primary' appearance='text' startIcon={<AddOutlined />} onClick={handleAddPhone}>
                    Phone
                  </Button>
                )}
              </Box>
            </Box>
          )}
        />
      ))}
    </Box>
  )
}

export default PhoneNumberField
