import { Box } from "@mui/material"
import { ArrowDownwardOutlined, ArrowUpwardOutlined } from "@mui-symbols-material/w300"
import type { Header } from "@tanstack/react-table"
import { flexRender } from "@tanstack/react-table"
import type { ReactElement } from "react"
import { twMerge } from "tailwind-merge"

import { AerosTableCell } from "./AerosTableCell"

interface AerosSortableTableHeadCellProps<TData> {
  header: Header<TData, unknown>
  align?: "left" | "center" | "right"
  className?: string
}

export const AerosSortableTableHeadCell = <TData,>({
  header,
  align = "left",
  className,
}: AerosSortableTableHeadCellProps<TData>): ReactElement => {
  return (
    <AerosTableCell
      key={header.id}
      align={header.id === "select" ? "center" : align}
      sx={{
        width: header.getSize(),
        minWidth: header.getSize(),
        maxWidth: header.getSize(),
      }}
      className={twMerge(
        "bg-gray-100 text-gray-700",
        className,
        header.column.getCanSort() ? "cursor-pointer select-none" : ""
      )}
      onClick={header.column.getToggleSortingHandler()}
    >
      <Box className='flex items-center'>
        {header.column.getCanSort() && (
          <Box className='mr-2'>
            {(header.column.getCanSort() &&
              {
                asc: <ArrowUpwardOutlined fontSize='small' />,
                desc: <ArrowDownwardOutlined fontSize='small' />,
                false: null,
              }[header.column.getIsSorted() as string]) ||
              (header.column.getCanSort() ? <span className='invisible mr-2 w-5' /> : null)}
          </Box>
        )}
        {flexRender(header.column.columnDef.header, header.getContext())}
      </Box>
    </AerosTableCell>
  )
}
