import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material"
import { EditOutlined, UploadFileOutlined } from "@mui-symbols-material/w300"
import { useNavigate } from "@tanstack/react-router"

interface PurchaseOrderDropdownMenuProps {
  anchorEl: HTMLElement | null
  onClose: () => void
  open: boolean
  purchaseOrderId: number
}

const PurchaseOrderDropdownMenu: React.FC<PurchaseOrderDropdownMenuProps> = ({
  anchorEl,
  onClose,
  open,
  purchaseOrderId,
}) => {
  const navigate = useNavigate()

  const handleUpdatePO = (e: React.MouseEvent) => {
    e.stopPropagation()
    navigate({
      to: "/procurement/purchase-orders/$purchaseOrderId",
      params: {
        purchaseOrderId: purchaseOrderId.toString(),
      },
    })
  }

  const handleAttachFile = (e: React.MouseEvent) => {
    e.stopPropagation()
    alert("Functionality is upcoming")
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <MenuItem
        onClick={(e) => {
          handleUpdatePO(e)
        }}
      >
        <ListItemIcon>
          <EditOutlined sx={{ fontVariationSettings: '"wght" 300' }} />
        </ListItemIcon>
        <ListItemText primary='Update PO' />
      </MenuItem>
      <MenuItem
        onClick={(e) => {
          handleAttachFile(e)
        }}
      >
        <ListItemIcon>
          <UploadFileOutlined />
        </ListItemIcon>
        <ListItemText primary='Attach File to PO' />
      </MenuItem>
    </Menu>
  )
}

export default PurchaseOrderDropdownMenu
