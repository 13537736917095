import { Checkbox, Divider, ListSubheader, MenuItem } from "@mui/material"
import { memo, useCallback } from "react"

import { FormField } from "@/components/Forms"
import MultiSelect from "@/components/MultiSelect/MultiSelect"
import type { TreeNode } from "@/types/tree"

type CompanyTypeSelectProps = {
  companyTypeTree: TreeNode<string>[]
  name: string
}

type CategoryItemProps = {
  category: TreeNode<string>
  field: { value: string[]; onChange: (value: string[]) => void }
  handleToggle: (field: { value: string[]; onChange: (value: string[]) => void }, value: string) => void
}

const CategoryItem = memo(({ category, field, handleToggle }: CategoryItemProps) => (
  <MenuItem key={category.id} value={category.id} className='pl-4' onClick={() => handleToggle(field, category.id)}>
    <Checkbox checked={field.value.includes(category.id)} onChange={() => handleToggle(field, category.id)} />
    {category.name}
  </MenuItem>
))

type SubCategoryItemsProps = {
  category: TreeNode<string>
  field: { value: string[]; onChange: (value: string[]) => void }
  handleToggle: (field: { value: string[]; onChange: (value: string[]) => void }, value: string) => void
}

const SubCategoryItems = memo(({ category, field, handleToggle }: SubCategoryItemsProps) => (
  <>
    <ListSubheader key={`${category.id}-subheader`} className='py-1 pl-4 font-medium'>
      {category.name}
    </ListSubheader>
    {category.children?.map((subCategory) => (
      <MenuItem
        key={subCategory.id}
        value={subCategory.id}
        className='pl-8'
        onClick={() => handleToggle(field, subCategory.id)}
      >
        <Checkbox checked={field.value.includes(subCategory.id)} onChange={() => handleToggle(field, subCategory.id)} />
        {subCategory.name}
      </MenuItem>
    ))}
  </>
))

const CompanyTypeSelect = ({ companyTypeTree, name }: CompanyTypeSelectProps) => {
  const isLeafNode = useCallback((node: TreeNode<string>) => !node.children || node.children.length === 0, [])

  const options = companyTypeTree.flatMap((category) => [
    ...(isLeafNode(category) ? [{ value: category.id, label: category.name }] : []),
    ...(category.children?.map((subCategory) => ({
      value: subCategory.id,
      label: subCategory.name,
    })) || []),
  ])

  const handleToggle = useCallback((field: { value: string[]; onChange: (value: string[]) => void }, value: string) => {
    const newValue = field.value.includes(value)
      ? field.value.filter((v: string) => v !== value)
      : [...field.value, value]
    field.onChange(newValue)
  }, [])

  return (
    <FormField
      name={name}
      label='Company Type'
      required
      render={({ field, fieldState: { error } }) => (
        <MultiSelect
          id='company-type-select'
          value={field.value}
          onChange={field.onChange}
          options={options}
          error={Boolean(error)}
        >
          {companyTypeTree.map((category, index) => [
            index > 0 && <Divider key={`divider-${category.id}`} className='my-2' />,
            isLeafNode(category) ? (
              <CategoryItem key={category.id} category={category} field={field} handleToggle={handleToggle} />
            ) : (
              <SubCategoryItems key={category.id} category={category} field={field} handleToggle={handleToggle} />
            ),
          ])}
        </MultiSelect>
      )}
    />
  )
}

export default memo(CompanyTypeSelect)
