import type { CellContext } from "@tanstack/react-table"
import type { FC } from "react"

import type { GroupedInstruction } from "../../../hooks/useInstructionGroups"

import { ExpanderCell } from "."

import type { RelatedProductInstruction } from "@/graphql/codegen/graphql"

type Props = CellContext<GroupedInstruction, unknown> & {
  onEdit: (instruction: RelatedProductInstruction) => void
  onDelete: (instruction: RelatedProductInstruction) => void
}

const ExpanderCellWrapper: FC<Props> = ({ row, onEdit, onDelete }) => (
  <ExpanderCell row={row} onEdit={onEdit} onDelete={onDelete} />
)

export const createExpanderCell =
  (
    onEdit: (instruction: RelatedProductInstruction) => void,
    onDelete: (instruction: RelatedProductInstruction) => void
  ): ((props: CellContext<GroupedInstruction, unknown>) => JSX.Element) =>
  (props: CellContext<GroupedInstruction, unknown>) => (
    <ExpanderCellWrapper {...props} onEdit={onEdit} onDelete={onDelete} />
  )
