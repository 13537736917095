import { useCallback, useEffect, useRef } from "react"

export const useThrottle = <T extends (...args: any[]) => any>(callback: T, delay: number): [T] => {
  const lastExecuted = useRef<number>(0)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const callbackRef = useRef(callback)

  // Keep callback reference up to date
  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const throttledCallback = useCallback(
    (...args: Parameters<T>) => {
      const now = Date.now()
      const timeSinceLastExecution = now - lastExecuted.current

      // If enough time has passed, execute immediately
      if (timeSinceLastExecution >= delay) {
        lastExecuted.current = now
        callbackRef.current(...args)
        return
      }

      // Otherwise, schedule for later if not already scheduled
      if (!timeoutRef.current) {
        timeoutRef.current = setTimeout(() => {
          lastExecuted.current = Date.now()
          callbackRef.current(...args)
          timeoutRef.current = null
        }, delay - timeSinceLastExecution)
      }
    },
    [delay] // callback is handled via ref
  ) as T

  return [throttledCallback]
}
