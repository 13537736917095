import type { MenuProps, SelectChangeEvent } from "@mui/material"
import { Select } from "@mui/material"
import { KeyboardArrowDownOutlined } from "@mui-symbols-material/w300"
import { memo, useCallback } from "react"
import { twMerge } from "tailwind-merge"

import {
  multiSelectButtonVariants,
  multiSelectListboxVariants,
  multiSelectPopupVariants,
  multiSelectVariants,
} from "./multiSelect.variants"

import ChipStack, { type ChipData } from "@/components/ChipStack/ChipStack"

export interface Option {
  value: string
  label: string
}

export interface MultiSelectProps {
  id?: string
  value?: string[]
  onChange?: (value: string[]) => void
  options: Option[]
  error?: boolean
  disabled?: boolean
  children: React.ReactNode
  width?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "full"
  variant?: "default" | "inline"
  success?: boolean
  className?: string
  MenuProps?: Partial<MenuProps>
}

const MultiSelect = ({
  id,
  value = [],
  onChange,
  options,
  error,
  disabled,
  children,
  width = "md",
  variant = "default",
  success,
  className,
  MenuProps: customMenuProps,
}: MultiSelectProps) => {
  const handleChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const selectedValues = event.target.value as string[]
      onChange?.(selectedValues)
    },
    [onChange]
  )

  const handleDelete = useCallback(
    (chipToDelete: ChipData) => {
      const newValue = value.filter((val) => val !== chipToDelete.value)
      onChange?.(newValue)
    },
    [value, onChange]
  )

  const chips: ChipData[] = value.map((val) => {
    const option = options.find((opt) => opt.value === val)
    return {
      key: val,
      label: option?.label ?? val,
      value: val,
    }
  })

  const defaultMenuProps = {
    PaperProps: {
      className: multiSelectPopupVariants({ width }),
    },
    MenuListProps: {
      className: multiSelectListboxVariants(),
    },
  }

  return (
    <Select
      id={id}
      multiple
      fullWidth
      variant='outlined'
      IconComponent={KeyboardArrowDownOutlined}
      value={value}
      onChange={handleChange}
      disabled={disabled}
      renderValue={() => (
        <ChipStack chips={chips} onDelete={handleDelete} state={error ? "error" : "default"} disabled={disabled} />
      )}
      className={twMerge(
        multiSelectVariants({ error, success, disabled, width, variant }),
        multiSelectButtonVariants(),
        className
      )}
      MenuProps={{
        ...defaultMenuProps,
        ...customMenuProps,
        PaperProps: {
          ...defaultMenuProps.PaperProps,
          ...customMenuProps?.PaperProps,
          className: twMerge(defaultMenuProps.PaperProps?.className, customMenuProps?.PaperProps?.className),
        },
        MenuListProps: {
          ...defaultMenuProps.MenuListProps,
          ...customMenuProps?.MenuListProps,
          className: twMerge(defaultMenuProps.MenuListProps?.className, customMenuProps?.MenuListProps?.className),
        },
      }}
      sx={{
        "& .MuiSelect-select": {
          padding: "0 !important",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      }}
    >
      {children}
    </Select>
  )
}

MultiSelect.displayName = "MultiSelect"

export default memo(MultiSelect)
