import { zodResolver } from "@hookform/resolvers/zod/dist/zod.js"
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from "@mui/material"
import { CloseOutlined } from "@mui-symbols-material/w300"
import type { FC } from "react"
import { useCallback, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"

import { LocationAddressDetailsForm } from "../LocationAddressDetailsForm"

import { Button } from "@/components/common/Button"
import { useLocations } from "@/screens/Companies/hooks/useLocations"
import {
  type AddAddressDialogProps,
  type AddAddressQueryProps,
  AddAddressQuerySchema,
} from "@/screens/Companies/types/dialogProps"

const US_COUNTRY_ISO_CODE = "840"

export const AddAddressDialog: FC<AddAddressDialogProps> = ({ data, open, onClose, onSubmit }) => {
  const methods = useForm<AddAddressQueryProps>({
    values: { ...data, countryIsoCode: US_COUNTRY_ISO_CODE },
    resolver: zodResolver(AddAddressQuerySchema),
    mode: "onChange",
  })

  const { countryList, regionList, setSelectedCountry } = useLocations()
  const countryIsoCode = methods.watch("countryIsoCode")

  useEffect(() => {
    if (countryIsoCode) {
      setSelectedCountry(countryIsoCode)
    }
  }, [countryIsoCode, setSelectedCountry])

  const handleSave = useCallback(
    async (value: AddAddressQueryProps) => {
      const isValid = await methods.trigger()
      if (isValid) {
        onSubmit(value)
        methods.reset
        onClose({ data: {}, reason: "success" })
      }
      methods.reset()
    },
    [onSubmit, onClose, methods]
  )

  const handleClose = useCallback(() => {
    methods.reset()
    onClose({ data: {}, reason: "cancel" })
  }, [onClose, methods])

  return (
    <Dialog open={open} onClose={handleClose} fullWidth PaperProps={{ "data-testid": "add-address-dialog" }}>
      <DialogTitle className='pl-10 font-normal text-primary'>Add Location Address</DialogTitle>
      <IconButton aria-label='close' onClick={handleClose} className='absolute right-4 top-4 text-primary'>
        <CloseOutlined />
      </IconButton>
      <Divider />
      <DialogContent classes={{ root: "p-10" }}>
        <FormProvider {...methods}>
          <LocationAddressDetailsForm countryList={countryList} regionList={regionList} />
        </FormProvider>
      </DialogContent>
      <DialogActions data-testid='add-address-dialog-actions' className={"justify-between px-10 pb-10"}>
        <Button appearance='outlined' onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={methods.handleSubmit(handleSave)} disabled={!methods.formState.isValid}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
