import { Box, Chip, Typography } from "@mui/material"
import type { CellContext, ColumnDef } from "@tanstack/react-table"

import { ActionsCell } from "./ActionsCell"
import { OrderQuantityColumn } from "./OrderQuantityColumn"
import { PriceUomColumn } from "./PriceUomColumn"
import { OrderQuantityColumnReadOnly } from "./read-only/OrderQuantityColumnReadOnly"
import { PriceUomColumnReadOnly } from "./read-only/PriceUomColumnReadOnly"

import { StatusIndicator } from "@/components/common/StatusIndicator/StatusIndicator"
import type { OrderLine } from "@/graphql/codegen/graphql"
import { ProductStatusReason } from "@/graphql/codegen/graphql"
import { ProductStatusMap } from "@/screens/Companies/products/utils/productStatusMap.ts"

const formatLeadTime = (
  leadTimeInDays: number | null | undefined
): { value: string; unit: "Day" | "Days" | "Week" | "Weeks" } => {
  if (!leadTimeInDays || isNaN(leadTimeInDays) || leadTimeInDays <= 0) return { value: "00", unit: "Days" }

  const normalizedDays = Math.min(Number.MAX_SAFE_INTEGER, Math.round(leadTimeInDays))

  if (normalizedDays < 7) {
    return {
      value: normalizedDays < 10 ? `0${normalizedDays}` : `${normalizedDays}`,
      unit: normalizedDays === 1 ? "Day" : "Days",
    }
  }

  const weeks = Math.floor(normalizedDays / 7)
  return {
    value: weeks < 10 ? `0${weeks}` : `${Math.min(weeks, 99)}`,
    unit: weeks === 1 ? "Week" : "Weeks",
  }
}

export const getPurchaseOrderProductTableColumns = (
  onOpenPriceDialog?: (product: OrderLine) => void,
  isReadOnly?: boolean,
  showCompanyName?: boolean
): ColumnDef<OrderLine>[] => {
  const columns: ColumnDef<OrderLine>[] = [
    {
      header: "Product ID",
      accessorKey: "productId",
      cell: ({ row }) => (
        <Box className='flex items-center gap-x-4'>
          <StatusIndicator
            status={row.original.productDetail?.product?.activityStatusReason ?? ProductStatusReason.Discontinued}
            map={ProductStatusMap}
          />
          {row.original.productId}
        </Box>
      ),
      size: 80,
    },
    ...(showCompanyName
      ? [
          {
            header: "Company Name",
            accessorKey: "companyName",
            cell: ({ row }: CellContext<OrderLine, unknown>) => (
              <Typography component='span'>{row.original.productDetail.product?.company?.name || "-"}</Typography>
            ),
          },
        ]
      : []),
    {
      header: "Description",
      cell: ({ row }) => (
        <Box className='gap-2'>
          <div className='text-wrap'>Akrochem: {row.original.productDetail?.product?.companyProductDescription}</div>
          <div className='text-wrap'>Supplier: {row.original.productDetail?.product?.vendorDescription}</div>
        </Box>
      ),
      size: 120,
    },
    {
      header: "Lead Time",
      accessorKey: "leadTimeInDays",
      cell: ({ row }) => {
        const { value, unit } = formatLeadTime(row.original.productDetail?.product?.leadTimeInDays)
        return <Chip label={`${value} ${unit}`} variant='outlined' className='h-[24px] rounded bg-primary-200 px-2' />
      },
      size: 80,
    },
    {
      header: "Order Quantity",
      cell: ({ row }) => (isReadOnly ? <OrderQuantityColumnReadOnly row={row} /> : <OrderQuantityColumn row={row} />),
      size: 200,
    },
    {
      header: "Price/UoM",
      accessorKey: "pallets",
      cell: ({ row }) =>
        isReadOnly ? (
          <PriceUomColumnReadOnly row={row} />
        ) : (
          <PriceUomColumn row={row} onOpenPriceDialog={onOpenPriceDialog} />
        ),
      size: 100,
    },
  ]

  if (!isReadOnly) {
    columns.push({
      header: "",
      accessorKey: "name",
      cell: ActionsCell,
      size: 50,
    })
  }

  return columns
}
