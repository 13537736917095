import { useNavigate, useSearch } from "@tanstack/react-router"
import type { ExpandedState, OnChangeFn, Row, RowSelectionState, SortingState } from "@tanstack/react-table"
import { ErrorMessage } from "components/common/ErrorMessage/ErrorMessage"
import { useCallback, useEffect, useMemo, useRef } from "react"

import { usePurchaseOrderTable } from "../hooks/usePurchaseOrderTable"
import type { PurchaseOrderSearchFilters } from "../stores/usePurchaseOrdersSearchStore"
import { usePurchaseOrdersSearchStore } from "../stores/usePurchaseOrdersSearchStore"

import { PurchaseOrdersExapndedRowDetails } from "./PurchaseOrdersExapndedRowDetails"

import { AerosBaseTable } from "@/components/Tables/Aeros/components/AerosBaseTable"
import { SortingOrder } from "@/graphql/codegen/graphql"
import type { Order } from "@/graphql/codegen/graphql"
import { useScrollLoadMore } from "@/hooks/useScrollLoadMore"
import { queryClient } from "@/providers/GraphqlRouterProvider"
import { getRefinedSearchParams } from "@/screens/Companies/utils"
import type { SortablePurchaseOrderAccessorKey } from "@/screens/Procurement/PurchaseOrders/types"
import { accessoryKeyToOrderSortField } from "@/screens/Procurement/PurchaseOrders/types"
import { getTableColumns } from "@/screens/Procurement/PurchaseOrders/utils/tableColumns.tsx"
import useDebounce from "@/utils/useDebounce"

const isRow = (value: unknown): value is Row<Order> => {
  return value !== null && typeof value === "object" && "original" in value
}

export const PurchaseOrdersTable = (): JSX.Element => {
  const navigate = useNavigate()
  const currentSearch = useSearch({ from: "/procurement/_layout/purchase-orders" }) as PurchaseOrderSearchFilters
  const { category, query, field, order, ...restSearchParams } = currentSearch

  const data = usePurchaseOrdersSearchStore(({ data }) => data)
  const fetching = usePurchaseOrdersSearchStore(({ fetching }) => fetching)
  const error = usePurchaseOrdersSearchStore(({ error }) => error)
  const executeSearch = usePurchaseOrdersSearchStore(({ executeSearch }) => executeSearch)
  const loadMore = usePurchaseOrdersSearchStore(({ loadMore }) => loadMore)
  const clearStore = usePurchaseOrdersSearchStore(({ clearStore }) => clearStore)

  const columns = useMemo(() => getTableColumns(), [])

  const { expanded, selected, sorting, setExpanded, setSelected } = usePurchaseOrderTable({
    sortBy: field,
    sortOrder: order,
  })

  const tableContainerRef = useRef<HTMLDivElement>(null)

  const debouncedExecuteSearch = useDebounce((filters: PurchaseOrderSearchFilters) => {
    executeSearch(queryClient, filters)
  }, 300)

  const handleLoadMore = useDebounce(() => {
    if (currentSearch) {
      loadMore(queryClient, currentSearch)
    }
  }, 200)

  const handleSortingChange: OnChangeFn<SortingState> = useCallback(
    (updaterOrValue) => {
      const newSorting = typeof updaterOrValue === "function" ? updaterOrValue(sorting) : updaterOrValue
      if (newSorting.length > 0) {
        const sort = newSorting[0]
        const key = sort.id as SortablePurchaseOrderAccessorKey
        const field = accessoryKeyToOrderSortField.get(key)
        const params: PurchaseOrderSearchFilters = {
          ...restSearchParams,
          query,
          category,
          field: field ?? undefined,
          order: sort.desc ? SortingOrder.Desc : SortingOrder.Asc,
        }
        const refinedParams = getRefinedSearchParams(params)
        void navigate({ search: refinedParams.search, to: "/procurement/purchase-orders", replace: true })
      }
    },
    [category, navigate, query, restSearchParams, sorting]
  )

  const handleRowClick = useCallback(
    (value: unknown) => {
      if (isRow(value)) {
        navigate({ to: `/procurement/purchase-orders/${value.original.orderId}` })
      }
    },
    [navigate]
  )

  const onScroll = useScrollLoadMore({
    fetching,
    loadMore: handleLoadMore,
    scrollContainerRef: tableContainerRef,
  })

  // Execute search when filters change
  useEffect(() => {
    debouncedExecuteSearch(currentSearch)
  }, [debouncedExecuteSearch, currentSearch])

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      clearStore()
    }
  }, [clearStore])

  if (error) {
    return <ErrorMessage error={error} />
  }

  return (
    <AerosBaseTable<Order>
      features={{
        table: {
          stickyHeader: true,
        },
        tanstackOptions: {
          columns,
          data,
          state: {
            expanded,
            rowSelection: selected,
            sorting,
            isLoading: fetching,
          },
          enableRowSelection: true,
          enableMultiRowSelection: true,
          enableExpanding: true,
          getRowCanExpand: (row: Row<Order>) => Boolean(row.original.orderLines),
          onRowSelectionChange: setSelected as OnChangeFn<RowSelectionState>,
          onExpandedChange: setExpanded as OnChangeFn<ExpandedState>,
          getRowId: (row) => row.orderId.toString(),
          renderExpandedContent: PurchaseOrdersExapndedRowDetails,
          onSortingChange: handleSortingChange,
          enableSortingRemoval: false,
          manualSorting: true,
        },
      }}
      slotProps={{
        bodyCellExpanded: { className: "bg-primary-100" },
        container: {
          ref: tableContainerRef,
          onScroll,
        },
        bodyRow: {
          className: "hover:bg-primary-100 cursor-pointer",
          onClick: handleRowClick,
        },
      }}
      noDataMessage='No purchase orders found'
    />
  )
}
