import { useCallback } from "react"
import { useMutation } from "urql"

import type { FileMetadata, GeneratePresignedUrlMutation } from "@/graphql/codegen/graphql"
import { GeneratePresignedUrlDocument } from "@/graphql/codegen/graphql"
import { useAuthStore } from "@/stores/authStore"

interface FileUploadParams {
  file: File
  type: string
  metadata: Omit<FileMetadata, "fileName" | "label" | "userId">
  label?: string
}

interface FileUploadHook {
  getSignedUrl: (params: FileUploadParams) => Promise<string | null>
  uploadToS3: (signedUrl: string, file: File) => Promise<boolean>
}

export const useFileUpload = (): FileUploadHook => {
  const [_getSignedURLResponse, getFileSignedURL] =
    useMutation<GeneratePresignedUrlMutation>(GeneratePresignedUrlDocument)
  const userId = useAuthStore.getState().user?.id

  const getSignedUrl = useCallback(
    async ({ file, type, metadata, label }: FileUploadParams): Promise<string | null> => {
      if (!userId) {
        console.error("No user ID available")
        return null
      }

      const fileExtension = file.name.substring(file.name.lastIndexOf("."))
      const baseFileName = `${type}${Date.now()}`

      try {
        const response = await getFileSignedURL({
          input: {
            fileMetadata: {
              ...metadata,
              fileName: file.name.replace(/\s+/g, "_") || baseFileName + fileExtension,
              label: label || baseFileName + fileExtension,
              userId,
            },
          },
        })

        if (!response.data) {
          const requestId = response.operation?.key || "N/A"
          console.error(`No data received from signed URL generation - ref: ${requestId}`)
          return null
        }

        const result = response.data.fileUpload.generatePresignedUrl
        if (result.__typename !== "GetPresignedUrlSuccess") {
          const requestId = response.operation?.key || "N/A"
          console.error(`Failed to generate signed URL - ref: ${requestId}`)
          return null
        }

        return result.presignedUrl || null
      } catch (error) {
        console.error("Error generating signed URL:", error)
        return null
      }
    },
    [getFileSignedURL, userId]
  )

  const uploadToS3 = useCallback(async (signedUrl: string, file: File): Promise<boolean> => {
    try {
      const response = await fetch(signedUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      })

      if (!response.ok) {
        console.error(`Upload failed - status: ${response.status}`)
        return false
      }

      return true
    } catch (error) {
      if (error instanceof Error) {
        console.error(`Network error during upload: ${error.message}`)
      } else {
        console.error("Network error during upload")
      }
      return false
    }
  }, [])

  return {
    getSignedUrl,
    uploadToS3,
  }
}
