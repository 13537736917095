import type { SelectChangeEvent } from "@mui/material"
import { Box, Checkbox, Chip, MenuItem, Select } from "@mui/material"
import { KeyboardArrowDownOutlined, MoreHorizOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import { memo, useCallback, useEffect, useRef, useState } from "react"
import type { Control } from "react-hook-form"
import { useController } from "react-hook-form"

import type { AddProductModalType } from "./AddProductSchema"
import { useGetLocationAssociation } from "./Product/Information/hooks/useGetLocationAssociation"

import type { ProductLocationAssociationInput } from "@/graphql/codegen/graphql"
import { formatLocationAssociationAddress } from "@/utils/utils"

interface ProductShipLocationSelectProps {
  name: "locationsAssociations"
  control: Control<AddProductModalType>
}

export type LocationAssociation = {
  locationAssociationId: string
  address1: string
  city: string
  regionIsoCode: string
}

const ProductShipLocationSelect: FunctionComponent<ProductShipLocationSelectProps> = ({ name, control }) => {
  const { field: companyIdField } = useController({
    name: "companyId",
    control,
  })

  const { field } = useController({
    name,
    control,
  })

  const { locationsAssociationsList } = useGetLocationAssociation({
    companyId: companyIdField.value || "",
  })

  const shippingLocations: LocationAssociation[] = locationsAssociationsList
    .map(({ shipping, companyLocationAssociationId }) => ({
      locationAssociationId: companyLocationAssociationId,
      address1: shipping?.address1 ?? "",
      city: shipping?.city ?? "",
      regionIsoCode: shipping?.regionIsoCode ?? "",
    }))
    .filter(({ address1, city, regionIsoCode }) => Boolean(address1 || city || regionIsoCode))

  const containerRef = useRef<HTMLDivElement>(null)
  const measureRef = useRef<HTMLDivElement>(null)
  const [visibleChips, setVisibleChips] = useState<string[]>([])
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false)

  const updateVisibleChips = useCallback(() => {
    const value = field.value as ProductLocationAssociationInput[] | undefined
    if (!measureRef.current || !containerRef.current || !value?.length) return

    const containerWidth = containerRef.current.clientWidth - 40 // 32 is the width of the MoreHorizOutlined icon
    let totalWidth = 0
    const visibleChipsArray: string[] = []

    Array.from(measureRef.current.children).forEach((chip, index) => {
      const chipWidth = (chip as HTMLElement).offsetWidth
      if (totalWidth + chipWidth <= containerWidth && value[index]) {
        visibleChipsArray.push(value[index].locationAssociationId)
        totalWidth += chipWidth
      }
    })
    setVisibleChips(visibleChipsArray)
    setIsOverflowing(visibleChipsArray.length < value.length)
  }, [field.value])

  useEffect(() => {
    updateVisibleChips()
  }, [updateVisibleChips])

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const selectedIds = event.target.value as string[]
    const selectedLocations = selectedIds
      .map((id) => shippingLocations.find((loc) => loc.locationAssociationId === id))
      .filter((loc): loc is LocationAssociation => Boolean(loc))
      .map((loc) => ({
        locationAssociationId: loc.locationAssociationId,
      }))
    field.onChange(selectedLocations)
  }

  if (!field.value) return null

  const selectedLocations = (field.value as ProductLocationAssociationInput[])
    .map((val) => ({
      ...shippingLocations.find((loc) => loc.locationAssociationId === val.locationAssociationId),
      locationAssociationId: val.locationAssociationId,
    }))
    .filter((loc): loc is LocationAssociation => Boolean(loc.address1))

  return (
    <Box>
      <label className='block text-sm font-normal text-gray-700'>Product Ship From Location</label>
      <Select
        multiple
        fullWidth
        IconComponent={KeyboardArrowDownOutlined}
        renderValue={() => (
          <Box className='flex overflow-hidden align-middle' ref={containerRef}>
            <Box className='flex flex-nowrap gap-1'>
              {visibleChips.map((id) => {
                const location = shippingLocations.find((loc) => loc.locationAssociationId === id)
                return location ? (
                  <Chip
                    key={`selected-${id}`}
                    label={formatLocationAssociationAddress(location)}
                    className='rounded-sm'
                  />
                ) : null
              })}
            </Box>
            {isOverflowing && (
              <Box className='mx-1 flex size-8 items-center justify-center rounded bg-gray-300'>
                <MoreHorizOutlined className='ml-0 shrink-0' />
              </Box>
            )}
          </Box>
        )}
        className='mb-2 h-10 border-none hover:border-gray-400'
        value={selectedLocations.map((loc) => loc.locationAssociationId)}
        onChange={handleChange}
      >
        {shippingLocations.map((location) => (
          <MenuItem key={`option-${location.locationAssociationId}`} value={location.locationAssociationId}>
            <Checkbox
              checked={selectedLocations.some((val) => val.locationAssociationId === location.locationAssociationId)}
            />
            {formatLocationAssociationAddress(location)}
          </MenuItem>
        ))}
      </Select>
      <Box className='invisible absolute' ref={measureRef}>
        {selectedLocations.map((location) => (
          <Chip key={`measure-${location.locationAssociationId}`} label={formatLocationAssociationAddress(location)} />
        ))}
      </Box>
    </Box>
  )
}

export default memo(ProductShipLocationSelect)
