import { useNavigate, useSearch } from "@tanstack/react-router"
import type { ExpandedState, OnChangeFn, Row, RowSelectionState, SortingState } from "@tanstack/react-table"
import { ErrorMessage } from "components/common/ErrorMessage/ErrorMessage"
import { useCallback, useEffect, useMemo, useRef } from "react"
import { useShallow } from "zustand/react/shallow"

import { usePurchaseOrderTable } from "../hooks/usePurchaseOrderTable"
import type { ProductWithOrder } from "../stores/useProductPurchaseOrdersSearchStore"

import { PurchaseOrdersExapndedRowDetails } from "./PurchaseOrdersExapndedRowDetails"

import { AerosBaseTable } from "@/components/Tables/Aeros/components/AerosBaseTable"
import { SortingOrder } from "@/graphql/codegen/graphql"
import { useScrollLoadMore } from "@/hooks/useScrollLoadMore"
import { queryClient } from "@/providers/GraphqlRouterProvider"
import { useProductPurchaseOrdersSearchStore } from "@/screens/Procurement/PurchaseOrders/stores/useProductPurchaseOrdersSearchStore.tsx"
import type {
  ProductPurchaseOrderSearchFilters,
  SortablePurchaseOrderAccessorKey,
} from "@/screens/Procurement/PurchaseOrders/types"
import {
  accessoryKeyToOrderSortField,
  accessoryProductKeyToOrderSortField,
} from "@/screens/Procurement/PurchaseOrders/types"
import { getProductOrderColumns } from "@/screens/Procurement/PurchaseOrders/utils/productOrderColumns.tsx"
import useDebounce from "@/utils/useDebounce"

export const PurchaseProductOrdersTable = (): JSX.Element => {
  const navigate = useNavigate()
  const currentSearch = useSearch({ from: "/procurement/_layout/purchase-orders" }) as ProductPurchaseOrderSearchFilters
  const { category, query, field, order } = currentSearch

  const data = useProductPurchaseOrdersSearchStore(({ data }) => data)
  const fetching = useProductPurchaseOrdersSearchStore(({ fetching }) => fetching)
  const error = useProductPurchaseOrdersSearchStore(({ error }) => error)
  const executeSearch = useProductPurchaseOrdersSearchStore(useShallow((state) => state.executeSearch))
  const loadMore = useProductPurchaseOrdersSearchStore(useShallow((state) => state.loadMore))
  const clearStore = useProductPurchaseOrdersSearchStore(({ clearStore }) => clearStore)

  const columns = useMemo(() => getProductOrderColumns(), [])

  const { expanded, selected, sorting, setExpanded, setSelected } = usePurchaseOrderTable({
    sortBy: field,
    sortOrder: order,
  })

  const tableContainerRef = useRef<HTMLDivElement>(null)

  const debouncedExecuteSearch = useDebounce((filters: ProductPurchaseOrderSearchFilters) => {
    executeSearch(queryClient, filters)
  }, 300)

  const handleLoadMore = useCallback(() => {
    if (currentSearch && !fetching) {
      loadMore(queryClient, currentSearch)
    }
  }, [currentSearch, fetching, loadMore])

  const handleSortingChange = useCallback(
    (updaterFn: (state: SortingState) => SortingState) => {
      const newSorting = updaterFn(sorting)
      if (newSorting.length > 0) {
        const sort = newSorting[0]
        const key = sort.id as SortablePurchaseOrderAccessorKey
        const field = accessoryKeyToOrderSortField.get(key) || accessoryProductKeyToOrderSortField.get(key)
        void navigate({
          search: (prev) => ({
            ...prev,
            query,
            category,
            field,
            order: sort.desc ? SortingOrder.Desc : SortingOrder.Asc,
          }),
          to: "/procurement/purchase-orders",
          replace: true,
        })
      }
    },
    [category, navigate, query, sorting]
  )

  const onScroll = useScrollLoadMore({
    fetching,
    loadMore: handleLoadMore,
    scrollContainerRef: tableContainerRef,
  })

  // Execute search when filters change
  useEffect(() => {
    debouncedExecuteSearch(currentSearch)
  }, [debouncedExecuteSearch, currentSearch])

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      clearStore()
    }
  }, [clearStore])

  if (error) {
    return <ErrorMessage error={error} />
  }

  return (
    <AerosBaseTable<ProductWithOrder>
      error={error}
      features={{
        table: {
          stickyHeader: true,
        },
        tanstackOptions: {
          columns,
          data,
          state: {
            expanded,
            rowSelection: selected,
            sorting,
            isLoading: fetching,
          },
          enableRowSelection: true,
          enableMultiRowSelection: true,
          enableExpanding: true,
          getRowCanExpand: (row: Row<ProductWithOrder>) => Boolean(row.original.orderLineId),
          onRowSelectionChange: setSelected as OnChangeFn<RowSelectionState>,
          onExpandedChange: setExpanded as OnChangeFn<ExpandedState>,
          getRowId: (row) => row.orderId.toString() + row.productId.toString(),
          renderExpandedContent: PurchaseOrdersExapndedRowDetails,
          onSortingChange: handleSortingChange as OnChangeFn<SortingState>,
          enableSortingRemoval: false,
          manualSorting: true,
        },
      }}
      slotProps={{
        bodyCellExpanded: { className: "bg-primary-100" },
        container: {
          ref: tableContainerRef,
          onScroll,
          sx: { height: "inherit", position: "relative" },
        },
      }}
      noDataMessage='No products found in purchase orders'
    />
  )
}
