import { Box, Chip, IconButton, Tooltip, Typography } from "@mui/material"
import { ChatOutlined, MoreVertOutlined } from "@mui-symbols-material/w300"
import React, { useCallback, useState } from "react"

import CompanyHeaderDropdownMenu from "../../components/CompanyHeaderDropdownMenu"
import { NoLocationActivationModal } from "../../components/NoLocationActivationModal"
import { getCompanyStatusColor } from "../../utils/companyStatusColor"

import { CommentHistoryDialog } from "@/components/common/CommentHistory/CommentHistoryDialog"
import type { AccountManager, Comment } from "@/graphql/codegen/graphql"
import { CommentParentTableType, CompanyStatus } from "@/graphql/codegen/graphql"
import { useCompanyStatusUpdate } from "@/screens/Companies/hooks/useCompanyStatusUpdate"
import { useCompanyTypesStore } from "@/screens/Companies/stores/useCompanyTypesStore.ts"
import { findCompanyType } from "@/screens/Companies/utils/findCompanyType.ts"

type CompanyDetails = {
  comments: Comment[]
  accountManagers: Array<AccountManager>
  companyId: string
  externalSystemId: string
  name: string
  status: CompanyStatus
  types?: Array<string>
  fetchComments: () => void
  onEditClick: (value: boolean) => void
  locationsCount: number
}

const CompanyHeader: React.FC<CompanyDetails> = (props) => {
  const {
    companyId,
    externalSystemId,
    name,
    status,
    types = [],
    comments = [],
    accountManagers,
    onEditClick,
    fetchComments,
    locationsCount,
  } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openCommentHistory, setOpenCommentHistory] = useState(false)
  const [showNoLocationModal, setShowNoLocationModal] = useState(false)
  const companyTypes = useCompanyTypesStore((state) => state.companyTypes)
  const getChipName = useCallback((type: string) => findCompanyType(companyTypes, type)?.name, [companyTypes])
  const { updateCompanyStatus, isUpdating } = useCompanyStatusUpdate()

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleActivate = async () => {
    if (isUpdating) return
    const result = await updateCompanyStatus({
      companyId,
      status: CompanyStatus.Active,
      locationsCount,
      types,
    })
    if (result.success) {
      handleCloseMenu()
    } else if (result.noLocations) {
      setShowNoLocationModal(true)
    }
  }

  const handleDeactivate = async () => {
    if (isUpdating) return
    const result = await updateCompanyStatus({
      companyId,
      status: CompanyStatus.Inactive,
      locationsCount,
      types,
    })
    if (result.success) {
      handleCloseMenu()
    }
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleEdit = () => {
    onEditClick(true)
    handleCloseMenu()
  }

  const handleOpenCommentHistory = () => {
    setOpenCommentHistory(true)
  }

  const handleCommentHistorySuccess = () => {
    fetchComments()
  }

  const isActive = status === CompanyStatus.Active
  const hideCommentsHistory = status === CompanyStatus.Inactive || status === CompanyStatus.Pending

  return (
    <>
      <CommentHistoryDialog
        open={openCommentHistory}
        onClose={() => setOpenCommentHistory(false)}
        title={name}
        comments={comments}
        onSuccess={handleCommentHistorySuccess}
        commentParentTableType={CommentParentTableType.Company}
        referenceId={companyId}
      />
      <NoLocationActivationModal
        open={showNoLocationModal}
        onClose={() => setShowNoLocationModal(false)}
        companyId={companyId}
      />
      <Box className='rounded-md border border-solid border-gray-300 p-6'>
        <Box className='flex items-center justify-between gap-3'>
          <Box className='flex items-center gap-3'>
            <Typography variant='h5' className='text-gray-800'>
              {name}
            </Typography>
            <Chip label={status} variant='outlined' color={getCompanyStatusColor(status)} />
            <Typography variant='body1' className='font-medium text-gray-700'>
              Company ID:&nbsp;
              <Tooltip title={companyId}>
                <span>{externalSystemId || "----"}</span>
              </Tooltip>
            </Typography>
          </Box>
          <IconButton color='primary' aria-label='company actions' onClick={handleOpenMenu}>
            <MoreVertOutlined />
          </IconButton>
          <CompanyHeaderDropdownMenu
            anchorEl={anchorEl}
            onClose={handleCloseMenu}
            isActive={isActive}
            onEdit={handleEdit}
            onDeactivate={handleDeactivate}
            onActivate={handleActivate}
            disabled={isUpdating}
          />
        </Box>
        <Box className='mt-6 flex flex-wrap items-center gap-2'>
          <Typography variant='body2' className='text-gray-700'>
            Company Type:
          </Typography>
          {(types ?? []).map((type) => {
            const label = getChipName(type)
            return <Chip key={type} label={label} className='rounded-sm font-[13px]' />
          })}
        </Box>
        <Box className='mt-4 flex flex-wrap items-center justify-between'>
          <Box className='flex flex-wrap items-center gap-2'>
            <Typography variant='body2' className='text-gray-700'>
              Account Manager(s):
            </Typography>
            {accountManagers.length > 0 ? (
              accountManagers.map(({ accountManagerId, firstName, lastName }) => (
                <Chip key={accountManagerId} label={`${firstName} ${lastName}`} className='rounded-sm bg-primary-100' />
              ))
            ) : (
              <Chip label='None' className='rounded-sm bg-primary-100' />
            )}
          </Box>
          {!hideCommentsHistory && (
            <IconButton color='primary' onClick={handleOpenCommentHistory} aria-label='Open comment history'>
              <ChatOutlined />
            </IconButton>
          )}
        </Box>
      </Box>
    </>
  )
}

export default CompanyHeader
