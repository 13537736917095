import { Typography } from "@mui/material"
import type { ColumnDef, Table } from "@tanstack/react-table"
import { getCoreRowModel, useReactTable } from "@tanstack/react-table"
import { useMemo } from "react"

import type { ContactLocation } from "@/graphql/codegen/graphql"

export type ExtendedContactLocation = ContactLocation & {
  contactRoles: string[]
}

export const useContactRolesTable = (contactRoles: ContactLocation[]): Table<ExtendedContactLocation> => {
  const columns = useMemo<ColumnDef<ExtendedContactLocation>[]>(
    () => [
      {
        accessorKey: "address1",
        header: "Location",
        cell: ({ row }) => (
          <Typography variant='body2' className='pr-2 text-gray-900'>
            {`${row.original.location.address1} - ${row.original.location.city}, ${row.original.location.regionIsoCode}`}
          </Typography>
        ),
      },
      {
        accessorKey: "contactRoles",
        header: "Role",
        cell: ({ row }) => (
          <Typography variant='body2' className='text-gray-900'>
            {row?.original?.contactRoles?.join(", ")}
          </Typography>
        ),
      },
    ],
    []
  )

  const groupedContactRoles = useMemo<ExtendedContactLocation[]>(() => {
    return contactRoles.reduce((acc: ExtendedContactLocation[], current) => {
      const locationId = current.location.locationId
      const existingEntry = acc.find((entry) => entry.location.locationId === locationId)

      if (existingEntry) {
        existingEntry.contactRoles.push(current.contactRole as string)
      } else {
        acc.push({
          ...current,
          contactRoles: [current.contactRole as string],
        })
      }

      return acc
    }, [])
  }, [contactRoles])

  return useReactTable({
    data: groupedContactRoles,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })
}
