import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from "@mui/material"
import { CloseOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useFormContext } from "react-hook-form"

import { AddProductInformation } from "./AddProductInformation"
import type { AddProductModalType } from "./AddProductSchema"
import { AddProductSpecifics } from "./AddProductSpecifics"

import { Button } from "@/components/common/Button"

type EditProductModalProps = {
  open: boolean
  onClose: (action: "cancel" | "success" | "backdropClick" | "escapeKeyDown") => void
  onSubmit: (data: AddProductModalType) => Promise<void>
  resetDone: boolean
}

const EditProductModal: FunctionComponent<EditProductModalProps> = ({ open, onClose, onSubmit, resetDone }) => {
  const [step, setStep] = useState(0)
  const {
    formState: { errors },
    reset,
    handleSubmit,
    trigger,
  } = useFormContext<AddProductModalType>()

  useEffect(() => {
    if (!resetDone) return
    if (step === 0) {
      void trigger(["vendorDescription", "companyProductDescription", "productManager", "locationsAssociations"])
    } else if (step === 1) {
      void trigger(["classification", "productFormInfo", "uomId"])
    }
  }, [trigger, step, resetDone])

  const onNextStep = useCallback(async () => {
    const isStepValid = await trigger([
      "vendorDescription",
      "companyProductDescription",
      "productManager",
      "locationsAssociations",
    ])
    if (isStepValid) {
      setStep((prevState) => prevState + 1)
    }
  }, [trigger])

  const onBackClick = useCallback(() => {
    setStep((prevState) => prevState - 1)
  }, [])

  const handleResetClick = useCallback(() => {
    reset()
    setStep(0)
    onClose("cancel")
  }, [reset, onClose])

  const handleOnSubmit = useCallback(
    async (formData: AddProductModalType) => {
      await onSubmit(formData)
      setStep(0)
      onClose("success")
    },
    [onClose, onSubmit]
  )

  const isVendorDescriptionValid = useMemo(() => !errors.vendorDescription, [errors.vendorDescription])
  const isCompanyProductDescriptionValid = useMemo(
    () => !errors.companyProductDescription,
    [errors.companyProductDescription]
  )
  const isProductManagerValid = useMemo(() => !errors.productManager, [errors.productManager])
  const isLocationsAssociationsValid = useMemo(() => !errors.locationsAssociations, [errors.locationsAssociations])

  const isFirstStepValid = useMemo(() => {
    return (
      isVendorDescriptionValid &&
      isCompanyProductDescriptionValid &&
      isProductManagerValid &&
      isLocationsAssociationsValid
    )
  }, [isVendorDescriptionValid, isCompanyProductDescriptionValid, isProductManagerValid, isLocationsAssociationsValid])

  const isClassificationValid = useMemo(() => !errors.classification, [errors.classification])
  const isProductFormInfoValid = useMemo(() => !errors.productFormInfo, [errors.productFormInfo])
  const isUomIdValid = useMemo(() => !errors.uomId, [errors.uomId])

  const isSecondStepValid = useMemo(() => {
    return isClassificationValid && isProductFormInfoValid && isUomIdValid
  }, [isClassificationValid, isProductFormInfoValid, isUomIdValid])

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        reset()
        setStep(0)
        onClose(reason)
      }}
      fullWidth
    >
      <DialogTitle className='pl-10 font-normal text-primary'>Edit Product</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={() => {
          reset()
          setStep(0)
          onClose("cancel")
        }}
        className='absolute right-4 top-4 text-primary'
      >
        <CloseOutlined />
      </IconButton>
      <Divider />
      <DialogContent classes={{ root: "p-10" }}>
        {step === 0 && <AddProductInformation />}
        {step === 1 && <AddProductSpecifics />}
      </DialogContent>
      <DialogActions className={"justify-between px-10 pb-10"}>
        <Button appearance='outlined' onClick={handleResetClick}>
          Cancel
        </Button>
        {step === 0 && (
          <Button onClick={onNextStep} disabled={!isFirstStepValid}>
            Continue
          </Button>
        )}
        {step === 1 && (
          <Box>
            <Button variant='primary' appearance='text' className='mr-6' onClick={onBackClick}>
              Go Back
            </Button>
            <Button disabled={!isSecondStepValid} onClick={handleSubmit(handleOnSubmit)}>
              Save
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default EditProductModal
