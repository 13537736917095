import { IncludeWithEnum } from "@/graphql/codegen/graphql"

export const includedWithLabelLookup: Record<IncludeWithEnum, string> = {
  [IncludeWithEnum.Billoflading]: "Bill of Lading",
  [IncludeWithEnum.Packingslip]: "Packing Slip",
  [IncludeWithEnum.Picklist]: "Picklist",
  [IncludeWithEnum.Proformainvoice]: "Proforma Invoice",
  [IncludeWithEnum.Putaway]: "Putaway",
  [IncludeWithEnum.ShippingLabel]: "Shipping Label",
  [IncludeWithEnum.Po]: "Purchase Order",
}
