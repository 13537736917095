import { Box, Typography } from "@mui/material"
import type { AnyRouter } from "@tanstack/react-router"
import { useNavigate, useSearch } from "@tanstack/react-router"
import type { ReactNode } from "react"
import { useCallback } from "react"

import { PurchaseOrderFilterChip } from "./PurchaseOrderFilterChip"

import { OrderStatus, ShipStatus, type SortingOrder } from "@/graphql/codegen/graphql"
import type { PurchaseOrderSearchCategory } from "@/screens/Procurement/types/enums"

const routePath = "/procurement/_layout/purchase-orders" as const

const orderStatusLabelLookup: Record<OrderStatus, string> = {
  [OrderStatus.Cancelled]: "Cancelled",
  [OrderStatus.Completed]: "Completed",
  [OrderStatus.Confirmed]: "Confirmed",
  [OrderStatus.Draft]: "Draft",
  [OrderStatus.Sent]: "Sent",
}

const shippingStatusLabelLookup: Record<ShipStatus, string> = {
  [ShipStatus.Delivered]: "Delivered",
  [ShipStatus.InTransit]: "In Transit",
  [ShipStatus.PartiallyDelivered]: "Partially Delivered",
  [ShipStatus.Pending]: "Pending",
}

type SearchParams = {
  category: PurchaseOrderSearchCategory
  order: SortingOrder
  statuses?: OrderStatus[]
  shipStatuses?: ShipStatus[]
  query: string
  start?: string
  end?: string
}

export const PurchaseOrderSearchFilterChips = (): ReactNode => {
  const navigate = useNavigate({ from: routePath })
  const currentSearch = useSearch<AnyRouter, undefined, true, SearchParams>({ from: routePath })
  const { statuses = [], shipStatuses = [], start, end } = currentSearch

  const handleRemoveFilter = useCallback(
    (type: "statuses" | "shipStatuses" | "start" | "end", value: OrderStatus | ShipStatus | null) => {
      void navigate({
        search: {
          ...currentSearch,
          [type]:
            type === "start" || type === "end" ? undefined : currentSearch[type]?.filter((status) => status !== value),
          ...(type === "start" && { end: undefined }),
        },
        replace: true,
      })
    },
    [currentSearch, navigate]
  )

  if (!statuses?.length && !shipStatuses?.length && !start && !end) {
    return null
  }

  return (
    <Box data-testid='purchase-order-search-filter-chips' display='flex' flexWrap='wrap' alignItems='center' gap={2}>
      <Typography variant='body1'>Filters:</Typography>
      {statuses.map((status: OrderStatus) => (
        <PurchaseOrderFilterChip
          key={`status-${status}`}
          label={`Status: ${orderStatusLabelLookup[status]}`}
          onDelete={() => handleRemoveFilter("statuses", status)}
        />
      ))}
      {shipStatuses.map((status: ShipStatus) => (
        <PurchaseOrderFilterChip
          key={`shipStatus-${status}`}
          label={`Ship Status: ${shippingStatusLabelLookup[status]}`}
          onDelete={() => handleRemoveFilter("shipStatuses", status)}
        />
      ))}
      {start && (
        <PurchaseOrderFilterChip
          key='date-range'
          label={`PO Sent: ${new Date(start).toLocaleDateString()}${end ? ` - ${new Date(end).toLocaleDateString()}` : ""}`}
          onDelete={() => handleRemoveFilter("start", null)}
        />
      )}
    </Box>
  )
}
