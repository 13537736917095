import { Box, TextField, Typography } from "@mui/material"
import type { Row } from "@tanstack/react-table"
import type { ChangeEvent, ReactElement } from "react"
import { useCallback, useEffect, useRef, useState } from "react"
import { Controller, FormProvider } from "react-hook-form"
import { getConvertedUoMValue } from "utils/conversionUtils"

import { useOrderQuantityColumn } from "../hooks/useOrderQuantityColumn"

import type { OrderLine } from "@/graphql/codegen/graphql"
import { QuantityField } from "@/screens/Procurement/PurchaseOrder/ProductAdded/components/QuantityField.tsx"

interface OrderQuantityColumnProps {
  row: Row<OrderLine>
  isReadOnly?: boolean
}

export const OrderQuantityColumn = ({ row, isReadOnly = false }: OrderQuantityColumnProps): ReactElement => {
  const { filteredUoM, onInputChange, methods } = useOrderQuantityColumn({ row })
  const isUserInputRef = useRef(false)

  const { control, watch } = methods
  const uomId = watch("uomId")
  const numberOfPallets = watch("numberOfPallets")

  const [numberOfPalletsInputValue, setNumberOfPalletsInputValue] = useState<string | number>(numberOfPallets)

  useEffect(() => {
    setNumberOfPalletsInputValue(
      isUserInputRef.current && !numberOfPallets ? "" : parseFloat(numberOfPallets.toFixed(2))
    )
  }, [numberOfPallets])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setNumberOfPalletsInputValue(value ? parseFloat(value) : "")
    onInputChange({ value, name: "numberOfPallets" })
    isUserInputRef.current = true
  }

  const handleOnBlur = () => {
    if (!numberOfPalletsInputValue) {
      setNumberOfPalletsInputValue(0)
    }
    isUserInputRef.current = false
  }

  const convertUomValue = useCallback(
    (value: number) => {
      if (!value) return ""
      return getConvertedUoMValue(value, uomId)
    },
    [uomId]
  )

  return (
    <FormProvider {...methods}>
      <Box className='align-center flex gap-4'>
        <Controller
          name={`numberOfPallets`}
          control={control}
          render={({ field }) => (
            <Box className={isReadOnly ? "w-full" : "relative w-[120px]"}>
              {isReadOnly ? (
                <Box className='flex gap-2'>
                  <Typography>{row.original.numberOfPallets}</Typography>
                  <Typography>Pallets</Typography>
                  <Typography>
                    {row.original.quantityInUom} lb ({convertUomValue(row.original.quantityInUom)})
                  </Typography>
                </Box>
              ) : (
                <>
                  <label className='mb-2 block text-sm font-thin text-gray-700'>Pallets</label>
                  <TextField
                    {...field}
                    value={numberOfPalletsInputValue}
                    fullWidth
                    variant='outlined'
                    type='number'
                    onChange={handleInputChange}
                    onBlur={handleOnBlur}
                    InputProps={{
                      classes: {
                        input:
                          "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2",
                      },
                      inputProps: {
                        className: "hide-stepper",
                      },
                    }}
                  />
                </>
              )}
            </Box>
          )}
        />
        {!isReadOnly && (
          <Controller
            name={`quantityInUom`}
            control={control}
            render={({ field }) => (
              <QuantityField
                field={field}
                onInputChange={onInputChange}
                filteredUoM={filteredUoM}
                control={control}
                convertUomValue={convertUomValue}
              />
            )}
          />
        )}
      </Box>
    </FormProvider>
  )
}
