import React from "react"
import { twMerge } from "tailwind-merge"

interface FormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  children: React.ReactNode
  className?: string
}

/**
 * Displays a form with a flex column layout
 *
 * @param props The component's props
 * @param props.children The children to render (the form fields)
 * @param props.className The class name to apply to the form. Optional
 *
 * @example
 * ```tsx
 * <Form>
 *   <FormRow>
 *     <FormField />
 *   </FormRow>
 * </Form>
 */
const Form = ({ children, className, ...props }: FormProps): React.ReactElement => {
  return (
    <form className={twMerge("flex grow flex-col gap-10", className)} {...props}>
      {children}
    </form>
  )
}

export default Form
export { Form }
export type { FormProps }
