import { Box } from "@mui/material"
import { AddOutlined } from "@mui-symbols-material/w300"
import React, { useCallback } from "react"
import { FormProvider } from "react-hook-form"
import { useShallow } from "zustand/react/shallow"

import { usePricingPackage } from "../hooks/usePricingPackage"

import { PricingPackageTable } from "./PricingPackageTable"

import { Button } from "@/components/common/Button"
import { formattedTierData } from "@/constants/pricingTiers"
import type {
  CreatePricingPackageFailure,
  CreatePricingPackageInput,
  PricingPackage,
  UpdatePricingPackageFailure,
  UpdatePricingPackageInput,
} from "@/graphql/codegen/graphql"
import AddPricingTiersModal from "@/screens/Products/components/PricingTiers/AddPricingTiersModal"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

interface FuturePricingPackageProps {
  companyId: string
  productId: number
  sourceId: string
  pricingList: PricingPackage[]
  openFutureModal?: boolean
  isEmptyFuturePricing?: boolean
  reexecuteQueryWithReset: () => void
  onSubmit?: (showAddMoreModal: boolean) => void
  onFuturePricingSubmit?: (isOpen: boolean) => void
}

export const FuturePricingPackage: React.FC<FuturePricingPackageProps> = ({
  companyId,
  productId,
  sourceId,
  pricingList = [],
  openFutureModal = false,
  reexecuteQueryWithReset,
  isEmptyFuturePricing = false,
  onSubmit,
  onFuturePricingSubmit,
}) => {
  const notify = useNotificationsStore(useShallow(({ enqueueNotification }) => enqueueNotification))
  const { createPricingTier, methods, updatePricingTier, onPricingEditClick } = usePricingPackage()

  const handleSubmit = useCallback(
    async (data: CreatePricingPackageInput | UpdatePricingPackageInput) => {
      const pricingPackageId = methods.getValues("pricingPackageId")
      const { pricingPackage, pricingTiers } = formattedTierData({
        ...data,
        companyId,
        productId,
        sourceCompanyLocationAssociationId: sourceId,
      })
      const { error, data: result } = await (pricingPackageId
        ? updatePricingTier({
            input: {
              pricingPackageToUpdate: {
                ...pricingPackage,
                sourceCompanyLocationAssociationId: sourceId,
                companyId,
                pricingPackageId,
              },
              pricingTiersToUpdate: pricingTiers,
            } as UpdatePricingPackageInput,
          })
        : createPricingTier({
            input: {
              pricingPackageToCreate: {
                ...pricingPackage,
                sourceCompanyLocationAssociationId: sourceId,
                companyId,
              },
              pricingTiersToCreate: pricingTiers,
            } as CreatePricingPackageInput,
          }))

      const failure = pricingPackageId
        ? (result?.pricingPackage?.update as UpdatePricingPackageFailure)?.error
        : (result?.pricingPackage?.create as CreatePricingPackageFailure)?.error
      if (error || failure) {
        notify({
          type: "error",
          message: `Pricing and Shipping terms ${pricingPackageId ? "update" : "creation"} failed`,
        })
      } else {
        await reexecuteQueryWithReset()
        pricingPackageId
          ? notify({ type: "success", message: "Pricing and Shipping terms updated successfully" })
          : onSubmit?.(!pricingPackageId)
      }
      onFuturePricingSubmit?.(false)
      methods.reset()
    },
    [
      methods,
      companyId,
      productId,
      updatePricingTier,
      createPricingTier,
      onFuturePricingSubmit,
      notify,
      reexecuteQueryWithReset,
      onSubmit,
    ]
  )

  if (isEmptyFuturePricing) {
    return null
  }

  return (
    <>
      {pricingList.map((pricingPackage) => (
        <PricingPackageTable
          key={pricingPackage.pricingPackageId}
          title='Future Pricing'
          pricingDetails={pricingPackage} // Passing only the first pricing package as we are showing only the current pricing package
          onEditClick={(uomName) => {
            onPricingEditClick(pricingPackage, uomName)
            onFuturePricingSubmit?.(true)
          }}
        />
      ))}
      <Box className='flex justify-end'>
        <Button
          variant='primary'
          appearance='text'
          startIcon={<AddOutlined />}
          onClick={() => {
            methods.reset()
            onFuturePricingSubmit?.(true)
          }}
        >
          {pricingList.length === 0 ? "Future Pricing" : "Additional Pricing"}
        </Button>
      </Box>
      <FormProvider {...methods}>
        <AddPricingTiersModal
          open={openFutureModal}
          onClose={() => {
            onFuturePricingSubmit?.(false)
          }}
          onSubmit={handleSubmit}
        />
      </FormProvider>
    </>
  )
}
