import { create } from "zustand"

import {
  type Location,
  LocationSearchListDocument,
  type LocationSearchListQuery,
  type LocationSortField,
  type SortingOrder,
} from "@/graphql/codegen/graphql.ts"
import { type SearchFilters, createSearchStore } from "@/stores/createSearchStore"

export type LocationSearchFilters = SearchFilters & {
  category?: string
  field?: LocationSortField
  order?: SortingOrder
}

const deduplicateLocations = (newData: Location[], existingData: Location[]): Location[] => {
  const uniqueIds = new Set(existingData.map((location) => location.locationId))
  return [...existingData, ...newData.filter((location) => !uniqueIds.has(location.locationId))]
}

export const useLocationSearchStore = create(
  createSearchStore<Location, LocationSearchFilters, LocationSearchListQuery>({
    documentNode: LocationSearchListDocument,
    deduplicateData: deduplicateLocations,
    getQueryInput: (filters, offset, limit) => {
      const { category, field, order, ...otherFilters } = filters
      return {
        filter: otherFilters,
        offset,
        limit,
        sort: { field, order },
      }
    },
    getQueryVariables: (queryInput) => ({ input: queryInput }),
    extractDataFromResponse: (data) => ({
      __typename: data?.location?.list?.__typename ?? "LocationListFailure",
      data:
        data?.location?.list?.__typename === "LocationListSuccess" ? (data.location.list.locations as Location[]) : [],
      error: data?.location?.list?.__typename === "LocationListFailure" ? data.location.list.error : undefined,
    }),
  })
)
