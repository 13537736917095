import { Box, Chip, Stack } from "@mui/material"
import { CloseFilledOutlined, MoreHorizOutlined } from "@mui-symbols-material/w300"
import type { ReactElement, ReactNode } from "react"
import { useCallback, useLayoutEffect, useRef, useState } from "react"
import { twMerge } from "tailwind-merge"

import { chipStackVariants, chipVariants } from "./ChipStack.variants"

export interface ChipData {
  key: number | string
  label: string
  value?: string
  icon?: React.ReactElement
}

export interface ChipStackProps {
  state?: "default" | "error"
  disabled?: boolean
  chips?: ChipData[]
  value?: ChipData[]
  onChange?: (value: ChipData[]) => void
  onDelete?: (chipToDelete: ChipData) => void
  children?: ReactNode
  className?: string
  wrap?: boolean
  slotProps?: {
    root?: { className?: string }
    chip?: { className?: string }
    deleteIcon?: { className?: string }
  }
}

const ChipStack = ({
  state = "default",
  disabled = false,
  chips: controlledChips = [],
  value,
  onChange,
  onDelete,
  children,
  className,
  wrap = false,
  slotProps,
}: ChipStackProps): ReactElement => {
  const [localChips, setLocalChips] = useState(controlledChips)
  const chips = value || (controlledChips.length > 0 ? controlledChips : localChips)
  const isControlled = value !== undefined || controlledChips.length > 0

  const containerRef = useRef<HTMLDivElement>(null)
  const measureRef = useRef<HTMLDivElement>(null)
  const [visibleChips, setVisibleChips] = useState<ChipData[]>([])
  const [isOverflowing, setIsOverflowing] = useState(false)

  const updateVisibleChips = useCallback(() => {
    if (measureRef.current && containerRef.current) {
      const containerWidth = containerRef.current.clientWidth - 40 // Reserve space for overflow indicator
      let totalWidth = 0
      const visibleChipsArray: ChipData[] = []

      Array.from(measureRef.current.children).forEach((chip, index) => {
        const chipWidth = (chip as HTMLElement).offsetWidth
        if (totalWidth + chipWidth <= containerWidth) {
          visibleChipsArray.push(chips[index])
          totalWidth += chipWidth
        }
      })

      setVisibleChips(visibleChipsArray)
      setIsOverflowing(visibleChipsArray.length < chips.length)
    }
  }, [chips])

  useLayoutEffect(() => {
    updateVisibleChips()
  }, [chips, updateVisibleChips])

  const handleDelete = useCallback(
    (chipToDelete: ChipData) => {
      if (disabled) return

      const newChips = chips.filter((chip) => chip.key !== chipToDelete.key)

      if (onChange) {
        onChange(newChips)
        return
      }

      if (isControlled && onDelete) {
        onDelete(chipToDelete)
        return
      }

      setLocalChips(newChips)
    },
    [disabled, isControlled, onDelete, onChange, chips]
  )

  const handleDeleteClick = useCallback((event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }, [])

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent, chip: ChipData) => {
      if (event.key === "Delete" || event.key === "Backspace") {
        event.preventDefault()
        event.stopPropagation()
        handleDelete(chip)
      }
    },
    [handleDelete]
  )

  const renderChip = (chip: ChipData) => (
    <Chip
      key={chip.key}
      icon={chip.icon}
      label={chip.label}
      variant='filled'
      size='small'
      disabled={disabled}
      onDelete={!disabled ? () => handleDelete(chip) : undefined}
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => handleKeyDown(e, chip)}
      deleteIcon={
        <button
          onClick={handleDeleteClick}
          onMouseDown={(e) => e.stopPropagation()}
          type='button'
          aria-label={`Remove ${chip.label}`}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              e.preventDefault()
              e.stopPropagation()
              handleDelete(chip)
            }
          }}
          className='flex items-center justify-center'
        >
          <CloseFilledOutlined className='!h-4 !w-4' />
        </button>
      }
      className={twMerge(
        chipVariants({ variant: "default" }),
        "!cursor-default rounded-sm",
        "!bg-gray-100 hover:!bg-gray-200",
        "focus:outline focus:outline-blue-200",
        slotProps?.chip?.className
      )}
      sx={{
        "& .MuiChip-deleteIcon": {
          color: "rgba(0, 0, 0, 0.54)",
          "&:hover": {
            color: "rgba(0, 0, 0, 0.87)",
          },
          "&:focus": {
            outline: "2px solid rgb(59 130 246 / 0.5)",
            outlineOffset: "-2px",
          },
        },
      }}
    />
  )

  return (
    <Stack
      direction='row'
      className={twMerge(chipStackVariants({ state, disabled }), className, slotProps?.root?.className)}
      onClick={(e) => e.stopPropagation()}
    >
      <Box
        ref={containerRef}
        className={twMerge("flex grow items-center gap-2", wrap ? "flex-wrap" : "overflow-hidden whitespace-nowrap")}
      >
        {wrap ? (
          chips.map(renderChip)
        ) : (
          <>
            {visibleChips.map(renderChip)}
            {isOverflowing && (
              <Box className='mx-1 flex size-6 items-center justify-center rounded bg-gray-100'>
                <MoreHorizOutlined className='!h-4 !w-4 text-gray-500' />
              </Box>
            )}
          </>
        )}
      </Box>
      {children && <Box className='flex-none'>{children}</Box>}
      <Box className='invisible absolute' ref={measureRef}>
        {chips.map(renderChip)}
      </Box>
    </Stack>
  )
}

ChipStack.displayName = "ChipStack"

export default ChipStack
