import { z } from "zod"

import { ShelfLifeUom } from "./TechnicalInformationContent"

import { casNumberSchema } from "@/utils/validation/casNumberValidation"

export const TechnicalInformationSchema = z.object({
  specificGravity: z
    .number()
    .min(0, "Specific Gravity must be greater than or equal to 0")
    .max(99, "Specific Gravity must not exceed 99")
    .refine((val) => {
      const decimalPlaces = val.toString().split(".")[1]?.length || 0
      return decimalPlaces <= 5
    }, "Specific Gravity must not exceed 5 decimal places")
    .optional(),

  shelfLifeDays: z.number().min(0).optional(),
  shelfLifeUom: z.nativeEnum(ShelfLifeUom).optional(),

  cassRegistryId: casNumberSchema.optional(),

  harmonizedTariffSchedule: z
    .string()
    .regex(/^(\d{1,10})?$/, "HTS must contain only digits")
    .max(10, "HTS must not exceed 10 digits")
    .optional(),

  usmcaClass: z.boolean().default(false).optional(),

  unNumber: z
    .string()
    .regex(/^(\d{4})?$/, "UN Number must be exactly 4 digits")
    .optional(),
})

export type TechnicalInformationFormData = z.infer<typeof TechnicalInformationSchema>
