import { Box, Typography } from "@mui/material"
import { AddOutlined } from "@mui-symbols-material/w300"
import type { FC } from "react"

import { Button } from "@/components/common/Button"

interface InstructionsHeaderProps {
  onAddClick: () => void
  hasInstructions: boolean
}

export const InstructionsHeader: FC<InstructionsHeaderProps> = ({ hasInstructions, onAddClick }) => (
  <Box className='flex items-center justify-between'>
    <Typography variant='h6'>Instruction Groups</Typography>
    {hasInstructions ? (
      <Button color='primary' appearance='outlined' startIcon={<AddOutlined />} onClick={onAddClick}>
        New Application
      </Button>
    ) : null}
  </Box>
)
