import { useEffect, useMemo } from "react"
import { useQuery } from "urql"

import type { Product, ProductConditionsQueryVariables } from "@/graphql/codegen/graphql"
import { ProductConditionsDocument } from "@/graphql/codegen/graphql"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

interface UseConditionsProps {
  productId: number
}

type ProductConditions = Pick<Product, "stockType" | "excludedFromCombinedPricing" | "isOnAllocation">

interface UseConditionsResponse {
  data: ProductConditions | null
  fetching: boolean
  fetch: (variables: ProductConditionsQueryVariables) => void
}

export const useProductConditions = ({ productId }: UseConditionsProps): UseConditionsResponse => {
  const [{ data, error, fetching }, fetch] = useQuery({
    query: ProductConditionsDocument,
    variables: {
      productId,
    },
  })

  const hasError = error?.message || data?.product.get.__typename === "ProductGetFailure"
  const notify = useNotificationsStore(({ enqueueNotification }) => enqueueNotification)

  useEffect(() => {
    if (hasError) {
      notify({
        message: "Error fetching product conditions",
        type: "error",
        silent: false,
      })
    }
  }, [hasError, notify])

  const conditions = useMemo(() => {
    if (data?.product.get.__typename === "ProductGetSuccess") {
      return data.product.get.product
    }
    return null
  }, [data])

  return {
    data: conditions as ProductConditions | null,
    fetching,
    fetch,
  }
}
