import { z } from "zod"

import type { File as AerosFile, SignedUrl } from "@/graphql/codegen/graphql"
import { PDFSize } from "@/hooks/usePDFUpload"

export type LabelFileBySize = Record<PDFSize, SignedUrl | null>

export const LABEL_SIZES = [
  { size: PDFSize.Small, label: "Small" },
  { size: PDFSize.Medium, label: "Medium" },
  { size: PDFSize.Large, label: "Large" },
  { size: PDFSize.ExtraLarge, label: "Extra Large" },
] as const

export type LabelInformationFormData = {
  isPrivateLabel: boolean
  isPrintedBySupplier: boolean | null
  isAppliedBySupplier: boolean | null
  pdfs: File[]
  image: File | null
}

// Define literal types for radio values
const RadioValue = z.enum(["true", "false"])
type RadioValue = z.infer<typeof RadioValue>

// Define transformers for consistent boolean conversion
const booleanTransformer = (val: boolean | RadioValue) => (typeof val === "boolean" ? val : val === "true")

const nullableBooleanTransformer = (val: boolean | RadioValue | null) =>
  val === null ? null : typeof val === "boolean" ? val : val === "true"

export const schema = z.object({
  isPrivateLabel: z.union([z.boolean(), RadioValue]).transform(booleanTransformer),
  isPrintedBySupplier: z.union([z.boolean(), RadioValue, z.null()]).transform(nullableBooleanTransformer),
  isAppliedBySupplier: z.union([z.boolean(), RadioValue, z.null()]).transform(nullableBooleanTransformer),
  files: z.array(z.instanceof(File)),
})

// Infer the type with transformed values
export type FormData = {
  isPrivateLabel: boolean
  isPrintedBySupplier: boolean | null
  isAppliedBySupplier: boolean | null
  files: File[]
}

// !!! When updating this function, make sure to update the possible patterns in mapPatternUrlsToSizes
export const createLabelByPattern = (
  isPrivateLabel: boolean,
  isPrintedBySupplier: boolean | null,
  isAppliedBySupplier: boolean | null,
  size: PDFSize
): string => {
  if (!isPrivateLabel) return ""

  if (isPrintedBySupplier === null) {
    console.warn("Missing supplier values:", { isPrintedBySupplier })
    return ""
  }

  const printedBy = isPrintedBySupplier ? "supplier" : "akrochem"
  const appliedBy = isAppliedBySupplier === null ? "null" : isAppliedBySupplier ? "supplier" : "akrochem"

  const pattern = `private_${printedBy}_${appliedBy}_size_${size}`
  return pattern
}

export type LabelInformation = {
  isPrivateLabel: boolean
  isPrintedBySupplier: boolean | null
  isAppliedBySupplier: boolean | null
}

export type LabelFile = {
  type: "pdf" | "image"
  size: PDFSize
  file?: File
  savedFileData: AerosFile | null
}

export const mapStoredFilesToLabelFiles = (files: AerosFile[]): LabelFile[] => {
  return files.map((file) => ({
    type: file.label?.includes(".pdf") ? "pdf" : "image",
    size: file.label?.split("_")[3] as PDFSize,
    savedFileData: file,
  }))
}

/*
  This function is used to map BE returned SignedUrls[] to LabelFiles[]
  @example:
    BE returns [
      { url: "https://example.com/private_supplier_supplier_size_small.pdf", file: { label: "private_supplier_supplier_size_small.pdf" } },
      { url: "https://example.com/private_supplier_supplier_size_medium.pdf", file: { label: "private_supplier_supplier_size_medium.pdf" } },
    ]
    This will return {
      small: { url: "https://example.com/private_supplier_supplier_size_small.pdf", file: { label: "private_supplier_supplier_size_small.pdf" } },
      medium: { url: "https://example.com/private_supplier_supplier_size_medium.pdf", file: { label: "private_supplier_supplier_size_medium.pdf" } },
    }
*/
export const mapSignedUrlsToLabelFiles = (signedUrls: SignedUrl[]): LabelFileBySize => {
  // First group files by size
  const urlsBySize = signedUrls.reduce(
    (acc, item) => {
      if (!item.file?.label) return acc
      const pattern = item.file.label.split("/").pop() || ""
      const size = pattern.split("_")[4] as PDFSize // Extract size from pattern

      if (!acc[size]) {
        acc[size] = []
      }
      acc[size].push(item)
      return acc
    },
    {} as Record<PDFSize, SignedUrl[]>
  )

  // Then get latest file for each size
  const getLatestFile = (files: SignedUrl[]): SignedUrl | null => {
    if (!files?.length) return null
    return files.reduce((latest, current) => {
      if (!latest.file?.createdOn || !current.file?.createdOn) return latest
      return new Date(current.file.createdOn) > new Date(latest.file.createdOn) ? current : latest
    })
  }

  return {
    [PDFSize.Small]: getLatestFile(urlsBySize[PDFSize.Small]) || null,
    [PDFSize.Medium]: getLatestFile(urlsBySize[PDFSize.Medium]) || null,
    [PDFSize.Large]: getLatestFile(urlsBySize[PDFSize.Large]) || null,
    [PDFSize.ExtraLarge]: getLatestFile(urlsBySize[PDFSize.ExtraLarge]) || null,
  }
}
/**
 * Groups the given array of `SignedUrl` objects by four possible sizes: `small`, `medium`, `large`, and `extraLarge`.
 * If multiple files for the same size are found, only the most recently created one is retained.
 *
 * @param {SignedUrl[]} signedUrls - An array of `SignedUrl` objects containing file information.
 * @returns {Record<"small" | "medium" | "large" | "extraLarge", SignedUrl>} An object where the key is one of the
 * sizes (`small`, `medium`, `large`, or `extraLarge`) and the value is the corresponding `SignedUrl`
 * that has the most recent file.
 */
export const mapSignedUrlsToPDFSizePattern = (signedUrls: SignedUrl[]): Record<string, SignedUrl> => {
  return signedUrls.reduce(
    (acc, signedUrl) => {
      if (!signedUrl.file?.label) return acc

      const sizeMatch = signedUrl.file.label.match(/_size_(small|medium|large|extraLarge)$/)
      if (!sizeMatch) return acc

      const size = sizeMatch[1] as "small" | "medium" | "large" | "extraLarge"

      if (acc[size]) {
        const existingDate = new Date(acc[size].file?.createdOn || 0)
        const currentDate = new Date(signedUrl.file.createdOn || 0)
        if (currentDate > existingDate) {
          acc[size] = signedUrl
        }
      } else {
        acc[size] = signedUrl
      }

      return acc
    },
    {} as Record<"small" | "medium" | "large" | "extraLarge", SignedUrl>
  )
}

/**
 * Maps pattern URLs to PDF sizes based on label information
 * @param patternUrls Record of pattern URLs mapped by pattern name
 * @param labelInfo Label information containing printing and application preferences
 * @returns Record mapping PDF sizes to signed URLs
 */
export const mapPatternUrlsToSizes = (
  patternUrls: Record<string, SignedUrl>,
  labelInfo: LabelInformation
): Record<PDFSize, SignedUrl | null> => {
  const result: Record<PDFSize, SignedUrl | null> = {
    [PDFSize.Small]: null,
    [PDFSize.Medium]: null,
    [PDFSize.Large]: null,
    [PDFSize.ExtraLarge]: null,
  }

  // Return empty result if not private label
  if (!labelInfo.isPrivateLabel) return result

  // Return empty result if printed by supplier is not set
  if (labelInfo.isPrintedBySupplier === null) return result

  Object.entries(patternUrls).forEach(([sizeStr, signedUrl]) => {
    // Map size string to PDFSize enum
    const size = sizeStr === "extraLarge" ? PDFSize.ExtraLarge : (sizeStr as PDFSize)
    result[size] = signedUrl
  })

  return result
}
