import { Box, Chip, IconButton, Typography } from "@mui/material"
import { EditOutlined } from "@mui-symbols-material/w300"
import React from "react"

type ProductDetails = {
  id: string
  isActive?: boolean
  productDescription?: string
  vendorDescription?: string
  vendorSku?: string
  companyName?: string
  productManager?: string
  classification?: string
  category?: string
  onEditClick: () => void
}

const ProductHeader: React.FC<ProductDetails> = (props: ProductDetails) => {
  const {
    id,
    productDescription,
    isActive,
    vendorDescription,
    vendorSku,
    companyName,
    productManager,
    classification,
    category,
    onEditClick,
  } = props

  return (
    <Box className='rounded-md border border-solid border-gray-300 p-6'>
      <Box className='flex items-center justify-between gap-3'>
        <Box className='flex items-center gap-3'>
          <Typography variant='overline' className='tracking-[1px] text-gray-700'>
            AKROCHEM DESCRIPTION
          </Typography>
        </Box>
        <IconButton color='primary' aria-label='edit contact details' onClick={onEditClick}>
          <EditOutlined />
        </IconButton>
      </Box>
      <Box className='flex items-center gap-3'>
        <Typography variant='h5' className='text-gray-800'>
          {productDescription}
        </Typography>
        <Chip label={isActive ? "Active" : "Pending"} variant='outlined' color={isActive ? "success" : "warning"} />
        <Typography variant='body2' className='text-base font-medium text-gray-700'>
          Product ID:
          {id || "-----"}
        </Typography>
      </Box>
      <Box className='mt-6 flex items-center gap-6'>
        <Typography variant='body2' className='font-medium tracking-[0.46px] text-gray-700'>
          Vendor Description: {vendorDescription}
        </Typography>
        <Typography variant='body2' className='font-medium tracking-[0.46px] text-gray-700'>
          Vendor SKU: {vendorSku}
        </Typography>
        <Typography variant='body2' className='font-medium tracking-[0.46px] text-gray-700'>
          Vendor: {companyName}
        </Typography>
      </Box>
      <Box className='mt-6 flex items-center gap-6'>
        <Box className='flex items-center gap-2'>
          <Typography variant='body2' className='text-gray-700'>
            Category:
          </Typography>
          <Chip label={category} className='rounded-sm text-xs' />
        </Box>
        <Box className='flex items-center gap-2'>
          <Typography variant='body2' className='text-gray-700'>
            Classification:
          </Typography>
          <Chip label={classification} className='rounded-sm text-xs' />
        </Box>
        <Box className='flex items-center gap-2'>
          <Typography variant='body2' className='text-gray-700'>
            Product Manager:
          </Typography>
          {productManager ? <Chip label={productManager} className='rounded-sm text-xs' /> : "-"}
        </Box>
      </Box>
    </Box>
  )
}

export default ProductHeader
