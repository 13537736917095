import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect, useState } from "react"
import type { UseFormReturn } from "react-hook-form"
import { useForm } from "react-hook-form"

import type { ProductUpdateFailure } from "@/graphql/codegen/graphql"
import { ProductFormInfoItem } from "@/graphql/codegen/graphql"
import type { AddProductModalType } from "@/screens/Products/components/AddProductSchema"
import { AddProductModalSchema } from "@/screens/Products/components/AddProductSchema"
import type { LocationAssociation } from "@/screens/Products/components/ProductShipLocationSelect.tsx"
import { useGetProduct } from "@/screens/Products/hooks/useGetProduct.tsx"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

interface UseEditProductProps {
  productId: number
}

interface UseEditProductReturn {
  methods: UseFormReturn<AddProductModalType>
  handleSubmit: (data: AddProductModalType) => Promise<void>
  resetDone: boolean
}

export const useEditProduct = ({ productId }: UseEditProductProps): UseEditProductReturn => {
  const [resetDone, setResetDone] = useState(false)
  const { enqueueNotification } = useNotificationsStore()
  const { productDetails, productDetailsFetching, updateProduct } = useGetProduct(productId)

  const methods = useForm<AddProductModalType>({
    defaultValues: {
      companyId: "",
      vendorSku: "",
      vendorDescription: "",
      companyProductDescription: "",
      productManager: { id: "", name: "" },
      classification: { id: "", name: "" },
      productFormInfo: "",
      locationsAssociations: [],
      uomId: "",
    },
    resolver: zodResolver(AddProductModalSchema),
    mode: "all",
  })

  const { setError } = methods

  useEffect(() => {
    if (productDetails) {
      const productManagerName = productDetails.productManager
        ? `${productDetails.productManager.firstName || ""} ${productDetails.productManager.lastName || ""}`.trim()
        : ""

      const formKey = Object.keys(ProductFormInfoItem).find(
        (key) => ProductFormInfoItem[key as keyof typeof ProductFormInfoItem] === productDetails.productFormInfo
      )

      const formData: AddProductModalType = {
        companyId: productDetails.company?.companyId || "",
        vendorSku: productDetails.vendorSku || "",
        vendorDescription: productDetails.vendorDescription || "",
        companyProductDescription: productDetails.companyProductDescription || "",
        productManager: {
          id: productDetails.productManagerId || "",
          name: productManagerName,
        },
        classification: {
          id: productDetails.classification?.id || "",
          name: productDetails.classification?.description || "",
        },
        productFormInfo: formKey || "",
        locationsAssociations:
          productDetails.locationsAssociations?.map((location) => ({
            address1: location.shipping?.address1 || "",
            city: location.shipping?.city || "",
            regionIsoCode: location.shipping?.regionIsoCode || "",
            locationAssociationId: location.locationAssociationId || "",
          })) || [],
        uomId: productDetails.uomId || "",
      }

      methods.reset(formData)
      setResetDone(true)
    }
  }, [productDetailsFetching, productDetails, methods])

  const handleSubmit = async (data: AddProductModalType) => {
    const { productManager, locationsAssociations, classification, uomId, ...rest } = data
    const { error, data: result } = await updateProduct({
      input: {
        ...rest,
        uomId: uomId || null,
        classificationId: classification.id,
        productId: Number(productId),
        productFormInfo: ProductFormInfoItem[data.productFormInfo as keyof typeof ProductFormInfoItem],
        locationsAssociations: (locationsAssociations as LocationAssociation[]).map(({ locationAssociationId }) => ({
          locationAssociationId,
        })),
        productManagerId: productManager.id,
      },
    })

    if (error || (result?.product?.update as ProductUpdateFailure)?.error) {
      setError("root", {
        type: "server",
        message: error?.message || (result?.product?.update as ProductUpdateFailure)?.error?.message,
      })

      enqueueNotification({
        type: "error",
        content: <p>Product updating failed</p>,
      })
    } else {
      enqueueNotification({
        type: "success",
        content: <p>Product successfully updated</p>,
      })
    }
  }

  return { methods, handleSubmit, resetDone }
}
