import type { z } from "zod"

import type { packagingInfoSchema } from "./schema"

export enum PackagingInfoFormFieldIds {
  FreightItem = "freightItem",
  FreightClass = "freightClass",
  PackagingType = "packagingType",
  PackageWeight = "packageWeight",
  PackageWeightUom = "packageWeightUom",
  PackageLength = "packageLength",
  PackageWidth = "packageWidth",
  PackageHeight = "packageHeight",
  PackageDimensionsUom = "packageDimensionsUom",
  PalletWeight = "palletWeight",
  PalletWeightUom = "palletWeightUom",
  PalletSizeLength = "palletSizeLength",
  PalletSizeWidth = "palletSizeWidth",
  PalletSizeHeight = "palletSizeHeight",
  PalletSizeUom = "palletSizeUom",
  PackagesPerPallet = "packagesPerPallet",
}

export type PackagingInfoFormData = z.infer<typeof packagingInfoSchema>

export interface PackagingInfoFormApi {
  submit: () => void
  isValid: boolean
}

export interface PackagingInfoFormProps {
  disabled?: boolean
  onSubmit: (data: Omit<PackagingInfoFormData, "form">) => Promise<void>
  defaultValues?: Partial<Omit<PackagingInfoFormData, "form">>
  onFormMount?: (api: PackagingInfoFormApi) => void
}

export interface PackagingInfoModalProps {
  open: boolean
  onClose: () => void
  onSubmit: (data: Omit<PackagingInfoFormData, "form">) => Promise<void>
  defaultValues?: Partial<Omit<PackagingInfoFormData, "form">>
  loading?: boolean
}
