import { type PropsWithChildren, createContext, createElement, useContext, useRef } from "react"
import type { StoreApi } from "zustand"
import { useStore } from "zustand"
import { devtools } from "zustand/middleware"
import { shallow } from "zustand/shallow"
import { createWithEqualityFn } from "zustand/traditional"

import type { Product } from "@/graphql/codegen/graphql"
import { ProductSharedInformationDocument } from "@/graphql/codegen/graphql"
import { queryClient } from "@/providers/GraphqlRouterProvider"

// State slices
interface ProductSlice {
  product: Product | null
  setProduct: (product: Product | null) => void
}

interface StatusSlice {
  fetching: boolean
  error: string | null
  setFetching: (fetching: boolean) => void
  setError: (error: string | null) => void
}

interface ActionsSlice {
  getProduct: (productId: number) => Promise<void>
  resetStore: () => void
}

// Combined state type
interface ProductState extends ProductSlice, StatusSlice, ActionsSlice {}

// Initial state
const INITIAL_STATE = {
  product: null,
  fetching: false,
  error: null,
}

// Create slices
const createProductSlice = (
  set: StoreApi<ProductState>["setState"],
  _get: StoreApi<ProductState>["getState"]
): ProductSlice => ({
  product: null,
  setProduct: (product) => set({ product }),
})

const createStatusSlice = (set: StoreApi<ProductState>["setState"]): StatusSlice => ({
  fetching: false,
  error: null,
  setFetching: (fetching) => set({ fetching }),
  setError: (error) => set({ error }),
})

const createActionsSlice = (set: StoreApi<ProductState>["setState"]): ActionsSlice => ({
  getProduct: async (productId: number) => {
    set({ fetching: true })
    try {
      const { data, error } = await queryClient.query(ProductSharedInformationDocument, { productId }).toPromise()

      if (error) {
        set({ error: error.message, fetching: false })
      } else if (data?.product.get.__typename === "ProductGetSuccess") {
        set({
          product: data.product.get.product as Product,
          fetching: false,
          error: null,
        })
      } else if (data?.product.get.__typename === "ProductGetFailure") {
        set({ error: data.product.get.error.message, fetching: false })
      } else {
        set({ error: "Failed to fetch product", fetching: false })
      }
    } catch (error) {
      set({ error: (error as Error).message, fetching: false })
    }
  },
  resetStore: () => set(INITIAL_STATE),
})

// Create store factory
const createProductStore = (): StoreApi<ProductState> =>
  createWithEqualityFn<ProductState>()(
    devtools(
      (set, get) => ({
        ...createProductSlice(set, get),
        ...createStatusSlice(set),
        ...createActionsSlice(set, get),
      }),
      {
        name: "Product Store",
      }
    ),
    shallow
  )

// Create context with proper typing
const ProductStoreContext = createContext<StoreApi<ProductState> | null>(null)

// Create provider
export const ProductStoreProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const storeRef = useRef<ReturnType<typeof createProductStore>>()
  if (!storeRef.current) {
    storeRef.current = createProductStore()
  }
  return createElement(ProductStoreContext.Provider, { value: storeRef.current }, children)
}

// Hook to use the store
export const useProductStore = <T = ProductState>(selector: (state: ProductState) => T): T => {
  const store = useContext(ProductStoreContext)
  if (!store) {
    throw new Error("Missing ProductStoreProvider")
  }

  return useStore(store, selector)
}

// Hook for actions
export const useProductActions = (): ActionsSlice => {
  const store = useContext(ProductStoreContext)
  if (!store) {
    throw new Error("Missing ProductStoreProvider")
  }
  return {
    getProduct: store.getState().getProduct,
    resetStore: store.getState().resetStore,
  }
}

// Selectors
export const selectProduct = (state: ProductState): Product | null => state.product
export const selectProductManager = (state: ProductState): string | undefined =>
  state.product?.productManager
    ? `${state.product.productManager.firstName} ${state.product.productManager.lastName}`
    : undefined
export const selectClassification = (state: ProductState): string => state.product?.classification?.description ?? ""
export const selectStatus = (state: ProductState): { fetching: boolean; error: string | null } => ({
  fetching: state.fetching,
  error: state.error,
})
export const selectSourceLocationId = (state: ProductState): string | null =>
  state.product?.locationsAssociations?.find((la) => Boolean(la.locationAssociationId))?.locationAssociationId ||
  state.product?.company?.locationsAssociations?.find((la) => Boolean(la.companyLocationAssociationId))
    ?.companyLocationAssociationId ||
  null
export const selectCategory = (state: ProductState): string =>
  state.product?.classification?.categoryDetail?.description ?? ""
export const setProduct = (state: ProductState): ((product: Product | null) => void) => state.setProduct

// Export hooks with return types
export const useProductManager = (): string | undefined => useProductStore(selectProductManager)
export const useClassification = (): string => useProductStore(selectClassification)
export const useProductStatus = (): { fetching: boolean; error: string | null } => useProductStore(selectStatus)
export const useCategory = (): string => useProductStore(selectCategory)
export const useSetProduct = (): ((product: Product | null) => void) => useProductStore(setProduct)
