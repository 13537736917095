import { Box, IconButton } from "@mui/material"
import { KeyboardArrowDownSharp, KeyboardArrowUpSharp } from "@mui-symbols-material/w300"
import type { FC } from "react"

import type { ExpanderCellProps } from "../types"

import { InstructionGroupDropdown } from "./InstructionGroupDropdown.tsx"

export const ExpanderCell: FC<ExpanderCellProps> = ({ row, onEdit, onDelete }) => (
  <Box className='flex justify-end'>
    <InstructionGroupDropdown row={row} onEdit={onEdit} onDelete={onDelete} />
    <IconButton size='small' onClick={row.getToggleExpandedHandler()} disabled={!row.getCanExpand()}>
      {row.getIsExpanded() ? <KeyboardArrowUpSharp /> : <KeyboardArrowDownSharp />}
    </IconButton>
  </Box>
)
