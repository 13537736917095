import { cva } from "class-variance-authority"

/**
 * Variants for the MultiSelect component and its subcomponents.
 *
 * Note on portal styling:
 * Menu and MenuList styles are applied via MenuProps since they are rendered
 * in a portal outside our component tree.
 */

export const multiSelectVariants = cva(
  [
    "relative box-border h-10 rounded px-4 text-sm",
    "border transition-colors",
    "border-gray-400",
    "hover:border-gray-400 hover:outline hover:outline-blue-200",
    "[&_.MuiOutlinedInput-notchedOutline]:border-0",
    "[&.Mui-focused]:outline [&.Mui-focused]:outline-blue-200",
  ],
  {
    variants: {
      variant: {
        default: "",
        inline: "border-none hover:outline-none [&.Mui-focused]:outline-none",
      },
      error: {
        true: ["border-error-300", "hover:border-error-400", "[&.Mui-focused]:outline-error-300"].join(" "),
        false: "",
      },
      success: {
        true: ["border-success-400", "hover:border-success-400", "[&.Mui-focused]:outline-success-400"].join(" "),
        false: "",
      },
      disabled: {
        true: "cursor-not-allowed border-gray-200 bg-gray-100 text-gray-500 hover:border-gray-200 hover:outline-none [&.Mui-focused]:outline-none",
        false: "",
      },
      width: {
        xs: "w-20",
        sm: "w-32",
        md: "w-[320px]",
        lg: "w-96",
        xl: "w-[480px]",
        "2xl": "w-[640px]",
        full: "w-full",
      },
    },
    defaultVariants: {
      variant: "default",
      error: false,
      success: false,
      disabled: false,
    },
  }
)

export const multiSelectButtonVariants = cva(
  [
    "relative w-full text-left",
    "flex items-center gap-2",
    "text-gray-900 disabled:text-gray-500",
    "bg-white",
    "[&>svg]:ml-auto",
    "py-0.5",
  ],
  {
    variants: {},
  }
)

export const multiSelectListboxVariants = cva(["list-none", "overflow-auto", "!p-0", "outline-none"], {
  variants: {},
})

export const multiSelectPopupVariants = cva(
  ["absolute z-[9999]", "left-0 top-full mt-1", "rounded-md bg-white", "shadow-md", "border border-gray-200"],
  {
    variants: {
      width: {
        xs: "w-20",
        sm: "w-32",
        md: "w-[320px]",
        lg: "w-96",
        xl: "w-[480px]",
        "2xl": "w-[640px]",
        full: "w-full",
      },
    },
    defaultVariants: {
      width: undefined,
    },
  }
)
