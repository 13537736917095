import { Box, Typography } from "@mui/material"
import { CheckCircleFilledRounded } from "@mui-symbols-material/w300"
import type { FC } from "react"
import React, { useMemo } from "react"
import { useFieldArray, useFormContext } from "react-hook-form"

import { useLocations } from "../../hooks/useLocations"

import type { ContactCreateInput, ContactLocationCreateInput } from "@/graphql/codegen/graphql"
import type { UIFormData } from "@/screens/Contacts/edit/components/LocationRolesDialog.tsx"
import { LocationRolesFields } from "@/screens/Contacts/edit/components/LocationRolesDialog.tsx"

export const AddLocationRoles: FC = () => {
  const { control, getValues, watch } = useFormContext<ContactCreateInput | ContactLocationCreateInput | UIFormData>()
  const {
    companyLocations: { locations },
  } = useLocations()
  const { fields, append } = useFieldArray({
    control,
    name: "locationRoles",
  })

  const formData = useMemo(() => getValues(), [getValues])

  const currentLocationRoles = watch("locationRoles")

  const addRoleAtLocation = () => append({ locationId: "", roles: [] })

  return (
    <form className='space-y-6'>
      <Box className='space-y-4'>
        <Box className='mb-2' display='flex' alignItems='center'>
          <Typography
            variant='body1'
            fontWeight={700}
            className='leading-6 tracking-[0.15px] text-gray-700'
            display='flex'
            alignItems='center'
          >
            1. General Information
            <CheckCircleFilledRounded className='ml-1 text-success-500' />
          </Typography>
        </Box>
        <Box className='px-10'>
          <p className='py-0 text-sm text-gray-900'>First Name: {formData.firstName}</p>
          <p className='py-0 text-sm text-gray-900'>Last Name: {formData.lastName}</p>
          {formData.companyDepartment && (
            <p className='py-0 text-sm text-gray-900'>Company Department: {formData.companyDepartment}</p>
          )}
          {formData.title && <p className='py-0 text-sm text-gray-900'>Title: {formData.title}</p>}
          {formData?.officeLocation && (
            <p className='py-0 text-sm text-gray-900'>Office Location: {formData.officeLocation}</p>
          )}
        </Box>
      </Box>
      <Box className='space-y-4'>
        <Box className='mb-2' display='flex' alignItems='center'>
          <Typography
            variant='body1'
            fontWeight={700}
            className='leading-6 tracking-[0.15px] text-gray-700'
            display='flex'
            alignItems='center'
          >
            2. Contact Methods
            <CheckCircleFilledRounded className='ml-1 text-success-500' />
          </Typography>
        </Box>
        <Box className='px-10'>
          {(formData.phoneNumber ?? []).map((item, index) => (
            <Box key={index} className='space-y-2'>
              <p className='py-0 text-sm text-gray-900'>
                Phone{`(${item.type ?? "Text"})`}:{" "}
                {item.number ? `${item.number} ${item.isPreferred ? "Preferred" : ""}` : "Not added"}
              </p>
            </Box>
          ))}
          <p className='py-0 text-sm text-gray-900'>Email: {formData.email || "Not added"}</p>
        </Box>
      </Box>
      <Typography variant='body1' fontWeight={700} className='leading-6 tracking-[0.15px] text-gray-700'>
        3. Locations Roles
      </Typography>
      <LocationRolesFields
        fields={fields}
        locations={locations}
        control={control}
        currentLocationRoles={currentLocationRoles}
        addRoleAtLocation={addRoleAtLocation}
      />
    </form>
  )
}
