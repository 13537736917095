import { Box } from "@mui/material"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import type { ReactElement } from "react"
import { useMemo } from "react"
import { useShallow } from "zustand/shallow"

import { InfoTable } from "@/components/InfoTable/InfoTable"
import { formatEnumValue, incoTermsLabels } from "@/constants/pricingTiers"
import type { PricingPackage } from "@/graphql/codegen/graphql"
import { useMergedLocations } from "@/screens/Companies/products/hooks/__tests__/useMergedLocations.ts"
import { useGetPurchaseOrderIncotermsLocations } from "@/screens/Procurement/PurchaseOrder/hooks/useGetPurchaseOrderIncotermsLocations"
import { useUnitOfMeasurementsStore } from "@/stores/unitOfMeasurmentsStore"
import { useProductStore } from "@/stores/useProductStore.ts"
import { formatLocationAddress } from "@/utils/utils"

dayjs.extend(utc)

export const PricingPackageTable = ({
  title = "Current Pricing",
  pricingDetails,
  onEditClick,
}: {
  pricingDetails: PricingPackage
  title?: string
  onEditClick?: (uomName: string) => void
}): ReactElement => {
  const { headerClassName, chipClassName } =
    title === "Future Pricing"
      ? { headerClassName: "bg-primary-200", chipClassName: "bg-primary-100" }
      : { headerClassName: "bg-primary-100", chipClassName: "bg-primary-200" }
  const units = useUnitOfMeasurementsStore(useShallow((s) => s.units))
  const {
    effectiveDate,
    expirationDate = "",
    pricingTiers,
    freightTerms,
    incoterms,
    incotermsLocationId,
    unitOfMeasurementId,
  } = pricingDetails
  const uomName = units.find((unit) => unit.id === unitOfMeasurementId)?.name ?? ""

  const { locations: incotermLocations } = useGetPurchaseOrderIncotermsLocations()
  const product = useProductStore((state) => state.product)

  const mergedLocations = useMergedLocations(product, incotermLocations)

  const selectedLocations = useMemo(
    () => mergedLocations.find(({ locationId }) => locationId === incotermsLocationId),
    [mergedLocations, incotermsLocationId]
  )
  const shippingName = useMemo(
    () => (selectedLocations ? formatLocationAddress(selectedLocations) : "-"),
    [selectedLocations]
  )

  const formattedPricingTiers = (pricingTiers ?? []).map((pricingTier) => {
    return {
      label: pricingTier.numberOfUnits.toString() + " QTY",
      value: `$${pricingTier.pricePerUnit} / ${uomName}`,
    }
  })

  const handleEdit = onEditClick ? () => onEditClick(uomName) : undefined

  return (
    <Box>
      <InfoTable
        title={title}
        className={{
          headerClassName,
          chipClassName,
        }}
        chipData={[
          { label: "Effective", value: dayjs.utc(effectiveDate).format("MM/DD/YYYY") },
          {
            label: "Valid Until",
            value: expirationDate ? dayjs.utc(expirationDate).format("MM/DD/YYYY") : "-",
          },
        ]}
        rows={formattedPricingTiers}
        extraInfo={[
          { label: "Freight", value: formatEnumValue(freightTerms) },
          { label: "Incoterms", value: incoTermsLabels[incoterms] },
          { label: "Incoterms Location", value: shippingName || "-" },
        ]}
        onEdit={handleEdit}
      />
    </Box>
  )
}
