import { Checkbox, MenuItem } from "@mui/material"
import { type FC, memo } from "react"
import { type Control, useFormContext } from "react-hook-form"

import { includedWithLabelLookup } from "./constants"
import InstructionList from "./InstructionList"

import { Form } from "@/components/Forms"
import { FormField } from "@/components/Forms/FormField/FormField"
import MultiSelect from "@/components/MultiSelect/MultiSelect"
import { ApplicableToEnum, IncludeWithEnum, TransportationModeEnum } from "@/graphql/codegen/graphql"

interface FormValues {
  applicableTo: ApplicableToEnum[]
  transportationMode: TransportationModeEnum[]
  includedWith: IncludeWithEnum[]
  productInstructionId: string[]
  groupId?: string
}

const applicableToOptions = Object.values(ApplicableToEnum).map((value) => ({
  value: value.toString(),
  label: value,
}))

const transportationModeOptions = Object.values(TransportationModeEnum).map((value) => ({
  value: value.toString(),
  label: value,
}))

const includedWithOptions = Object.values(IncludeWithEnum).map((value) => ({
  value: value.toString(),
  label: includedWithLabelLookup[value],
}))

const AddInstructionsContent: FC = () => {
  const { control, watch } = useFormContext<FormValues>()
  const showInstructionsField = Boolean(
    watch("applicableTo")?.length && watch("transportationMode")?.length && watch("includedWith")?.length
  )

  return (
    <Form>
      <FormField
        name='applicableTo'
        label='Applicable To'
        required
        render={({ field, fieldState: { error } }) => (
          <MultiSelect
            id='applicable-to-select'
            value={field.value?.map(String) || []}
            onChange={field.onChange}
            options={applicableToOptions}
            error={Boolean(error)}
          >
            {applicableToOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                <Checkbox checked={field.value?.map(String).includes(value)} />
                {label}
              </MenuItem>
            ))}
          </MultiSelect>
        )}
      />

      <FormField
        name='transportationMode'
        label='Transportation Mode'
        required
        render={({ field, fieldState: { error } }) => (
          <MultiSelect
            id='transportation-mode-select'
            value={field.value?.map(String) || []}
            onChange={field.onChange}
            options={transportationModeOptions}
            error={Boolean(error)}
          >
            {transportationModeOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                <Checkbox checked={field.value?.map(String).includes(value)} />
                {label}
              </MenuItem>
            ))}
          </MultiSelect>
        )}
      />

      <FormField
        name='includedWith'
        label='Include With'
        required
        render={({ field, fieldState: { error } }) => (
          <MultiSelect
            id='included-with-select'
            value={field.value?.map(String) || []}
            onChange={field.onChange}
            options={includedWithOptions}
            error={Boolean(error)}
          >
            {includedWithOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                <Checkbox checked={field.value?.map(String).includes(value)} />
                {label}
              </MenuItem>
            ))}
          </MultiSelect>
        )}
      />

      {showInstructionsField && (
        <InstructionList name='productInstructionId' control={control as unknown as Control} label='Instruction' />
      )}
    </Form>
  )
}

export default memo(AddInstructionsContent)
