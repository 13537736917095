/**
 * Returns a "consolidated" value for the specified field from an array of items.
 *
 * Rules:
 * 1) If all items have the same value for that field, return it.
 * 2) Otherwise, if there is exactly one unique value (appears in only one item)
 *    while the other items have a different value (or none), return that unique value.
 * 3) In all other cases, return null.
 *
 * @template T - The type of elements in the items array.
 * @param {T[]} items - The array of items.
 * @param {keyof T} field - The field name to consolidate.
 * @returns {T[keyof T] | null} The consolidated value, or null if it doesn't meet the rules.
 */
export function getConsolidatedValue<T extends Record<string, any>>(items: T[], field: keyof T): T[keyof T] | null {
  if (!items || items.length === 0) return null

  const distinctValues = new Set(items.map((item) => item[field]))
  if (distinctValues.size === 1) {
    return [...distinctValues][0]
  }

  const occurrences: Record<string, number> = {}
  for (const item of items) {
    const valKey = String(item[field]) // for consistent hashing
    occurrences[valKey] = (occurrences[valKey] || 0) + 1
  }

  const singleOccurrenceEntries = Object.entries(occurrences).filter(([, count]) => count === 1)
  if (singleOccurrenceEntries.length === 1) {
    return singleOccurrenceEntries[0][0] as T[keyof T]
  }

  return null
}
