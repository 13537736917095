import { Box, Typography } from "@mui/material"
import { CheckCircleFilledRounded } from "@mui-symbols-material/w300"
import { useParams } from "@tanstack/react-router"
import type { FC } from "react"
import React, { useMemo } from "react"
import { useFormContext } from "react-hook-form"

import { useGetContactGeneralInformation } from "../../hooks/useGetContactCompany"

import EmailField from "@/components/Contact/EmailField.tsx"
import PhoneNumberField from "@/components/Contact/PhoneNumberField.tsx"
import { type Company, type ContactCreateInput, type Location } from "@/graphql/codegen/graphql"
import { formatLocationAddress } from "@/utils/utils"

export const AddContactMethods: FC = () => {
  const { getValues } = useFormContext<ContactCreateInput>()

  const formData = useMemo(() => getValues(), [getValues])

  const { companyId: queryCompanyId } = useParams({ from: "/companies/$companyId/edit" })
  const {
    company,
    location,
    fetching: contactGeneralInformationFetching,
  } = useGetContactGeneralInformation({
    companyId: queryCompanyId,
    locationId: formData.physicalLocationId ?? undefined,
  })

  return (
    <form className='space-y-6'>
      <GeneralInformation
        formData={formData}
        company={company}
        location={location}
        loading={contactGeneralInformationFetching}
      />
      <Typography variant='body1' fontWeight={700} className='leading-6 tracking-[0.15px] text-gray-700'>
        2. Contact methods
      </Typography>
      <Box className='space-y-10'>
        <PhoneNumberField />
        <EmailField />
      </Box>
    </form>
  )
}

const GeneralInfoText: FC<{ children?: React.ReactNode }> = ({ children }) => (
  <p className='py-0 text-sm text-gray-900'>{children ?? <>&nbsp;</>}</p>
)

interface GeneralInformationProps {
  formData: ContactCreateInput
  company?: Company
  location?: Location
  loading?: boolean
}

const GeneralInformation: FC<GeneralInformationProps> = ({ formData, company, location, loading }) => {
  return (
    <Box className='space-y-4'>
      <Box className='mb-2' display='flex' alignItems='center'>
        <Typography
          variant='body1'
          fontWeight={700}
          className='leading-6 tracking-[0.15px] text-gray-700'
          display='flex'
          alignItems='center'
        >
          1. General Information
          <CheckCircleFilledRounded className='ml-1 text-success-500' />
        </Typography>
      </Box>
      <Box className='px-10'>
        {loading ? (
          <>
            <GeneralInfoText>
              <em>Loading...</em>
            </GeneralInfoText>
            {/* DOM placeholders to prevent "jumping" when transitioning between loading states */}
            <GeneralInfoText />
            <GeneralInfoText />
            <GeneralInfoText />
            <GeneralInfoText />
            <GeneralInfoText />
          </>
        ) : (
          <>
            <GeneralInfoText>First Name: {formData.firstName}</GeneralInfoText>
            <GeneralInfoText>Last Name: {formData.lastName}</GeneralInfoText>
            <GeneralInfoText>Company: {company?.name ?? "-"}</GeneralInfoText>
            {formData.companyDepartment && (
              <GeneralInfoText>Company Department: {formData.companyDepartment}</GeneralInfoText>
            )}
            {formData.title && <GeneralInfoText>Title: {formData.title}</GeneralInfoText>}
            {location && (
              <GeneralInfoText>Office Location: {location ? formatLocationAddress(location) : "-"}</GeneralInfoText>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

export default AddContactMethods
