import type { FunctionComponent } from "react"
import React from "react"
import { FormProvider } from "react-hook-form"
import type { Client } from "urql"

import { queryClient } from "@/providers/GraphqlRouterProvider"
import { useEditProduct } from "@/screens/Companies/products/hooks/useEditProduct"
import { useProductActionsStore } from "@/screens/Companies/products/stores/useProductsActions"
import { ProductActionsDialogs } from "@/screens/Companies/products/types/schema"
import type { AddProductModalType } from "@/screens/Products/components/AddProductSchema"
import EditProductModal from "@/screens/Products/components/EditProductModal"

type EditProductModalContainerProps = {
  productId: number
  executeSearch: (client: Client, filters: any, skipFilterCheck?: boolean) => Promise<void>
  currentSearch: any
}

const EditProductModalContainer: FunctionComponent<EditProductModalContainerProps> = ({
  productId,
  executeSearch,
  currentSearch,
}) => {
  const openedDialog = useProductActionsStore((state) => state.openedDialog)
  const setOpenedDialog = useProductActionsStore((state) => state.setOpenedDialog)

  const { methods, handleSubmit: handleEditProductModalSubmit, resetDone } = useEditProduct({ productId })

  const handleSubmit = async (data: AddProductModalType) => {
    await handleEditProductModalSubmit(data)
    await executeSearch(queryClient, currentSearch, true)
    setOpenedDialog(null)
  }

  return (
    <FormProvider {...methods}>
      <EditProductModal
        open={openedDialog === ProductActionsDialogs.EDIT_PRODUCT}
        onClose={() => setOpenedDialog(null)}
        onSubmit={handleSubmit}
        resetDone={resetDone}
      />
    </FormProvider>
  )
}

export default EditProductModalContainer
