import { zodResolver } from "@hookform/resolvers/zod"
import { useCallback, useState } from "react"
import type { UseFormReturn } from "react-hook-form"
import { useForm } from "react-hook-form"

import { AddInstructionsSchema } from "../AddInstructionsSchema"
import type { FormValues } from "../types"

import type {
  ApplicableToEnum,
  IncludeWithEnum,
  RelatedProductInstruction,
  TransportationModeEnum,
} from "@/graphql/codegen/graphql"

export type ModalType = "add" | "edit"

interface ModalState {
  isOpen: boolean
  type: ModalType
}

interface UseInstructionModalProps {
  instructions: RelatedProductInstruction[]
}

const defaultValues: FormValues = {
  applicableTo: [],
  transportationMode: [],
  includedWith: [],
  productInstructionId: [],
  groupId: "",
}

interface InstructionModalHook {
  modal: ModalState
  methods: UseFormReturn<FormValues, any, undefined>
  openAddModal: () => void
  openEditModal: (instruction: RelatedProductInstruction) => void
  closeModal: () => void
}

/**
 * Hook for managing instruction modal state and form operations
 */
export const useInstructionModal = ({ instructions }: UseInstructionModalProps): InstructionModalHook => {
  const [modal, setModal] = useState<ModalState>({
    isOpen: false,
    type: "add",
  })

  const methods = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(AddInstructionsSchema),
    mode: "all",
  })

  const openAddModal = useCallback(() => {
    methods.reset(defaultValues)
    setModal({
      isOpen: true,
      type: "add",
    })
  }, [methods])

  const openEditModal = useCallback(
    (instruction: RelatedProductInstruction) => {
      // Filter instructions belonging to the same group
      const groupInstructions = instructions.filter((inst) => inst.groupId === instruction.groupId)

      // Initialize unique value containers
      const uniqueValues = {
        applicableTo: new Set<ApplicableToEnum>(),
        transportationMode: new Set<TransportationModeEnum>(),
        includedWith: new Set<IncludeWithEnum>(),
        productInstructionId: new Set<string>(),
      }

      // Collect unique values efficiently
      for (const inst of groupInstructions) {
        if (inst.applicableTo) uniqueValues.applicableTo.add(inst.applicableTo)
        if (inst.transportationMode) uniqueValues.transportationMode.add(inst.transportationMode)
        if (inst.includedWith) uniqueValues.includedWith.add(inst.includedWith)
        if (inst.productInstructionId) uniqueValues.productInstructionId.add(inst.productInstructionId)
      }

      // Convert sets to arrays only once
      const formValues = {
        applicableTo: Array.from(uniqueValues.applicableTo),
        transportationMode: Array.from(uniqueValues.transportationMode),
        includedWith: Array.from(uniqueValues.includedWith),
        productInstructionId: Array.from(uniqueValues.productInstructionId),
        groupId: instruction.groupId || "",
      }

      // Reset the form
      methods.reset(formValues)

      // Open the edit modal
      setModal({
        isOpen: true,
        type: "edit",
      })
    },
    [methods, instructions]
  )

  const closeModal = useCallback(() => {
    methods.reset(defaultValues)
    setModal({
      isOpen: false,
      type: "add",
    })
  }, [methods])

  return {
    modal,
    methods,
    openAddModal,
    openEditModal,
    closeModal,
  }
}
