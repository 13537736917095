import { z } from "zod"

const emptyToNullableNumber = (value: any) => {
  const numberValue = Number(value)
  return isNaN(numberValue) || value === "" ? null : numberValue
}

const positiveNumberSchema = z.preprocess(
  emptyToNullableNumber,
  z.number().nonnegative({ message: "Value must be positive" }).nullable().optional()
)

export const ShippingDetailsSchema = z.object({
  weightPerTruckload: positiveNumberSchema,
  leadTimeInDays: positiveNumberSchema,
  minOrderQuantity: positiveNumberSchema,
  leadTimeUom: z
    .enum(["Days", "Weeks"], {
      errorMap: () => ({ message: "Must be either Days or Weeks" }),
    })
    .nullable(),
})
