import { useNavigate } from "@tanstack/react-router"
import dayjs from "dayjs"
import { useCallback, useEffect, useMemo } from "react"
import { useMutation } from "urql"
import { useShallow } from "zustand/react/shallow"

import type { ProductSelectionItem, PurchaseOrderFormData } from "../Supplier/types/types"

import { POUND_UOM_ID } from "@/constants/unitOfMeasurements"
import type { Order, OrderMutationUpdateArgs } from "@/graphql/codegen/graphql"
import {
  CreatePurchaseOrderDocument,
  OrderCreateInputSchema,
  OrderStatus,
  OrderType,
  UpdatePurchaseOrderMutationDocument,
} from "@/graphql/codegen/graphql"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

type UsePurchaseOrderReturn = {
  createdPO: Order | undefined
  handleCreatePO: (data: PurchaseOrderFormData, selectedProducts: ProductSelectionItem[]) => void
  updatedPO: Partial<Order> | undefined
  updatePO: (data: OrderMutationUpdateArgs) => void
  fetching: boolean
  fetchingUpdate: boolean
}

export const usePurchaseOrder = (): UsePurchaseOrderReturn => {
  const navigate = useNavigate()
  const notify = useNotificationsStore(useShallow((s) => s.enqueueNotification))
  const [{ data: createdPOResult, fetching: creatingPO }, createPO] = useMutation(CreatePurchaseOrderDocument)
  const [{ data, fetching: updatingPO }, updatePO] = useMutation(UpdatePurchaseOrderMutationDocument)

  const updatedPO = useMemo(() => {
    if (data?.order.update.__typename === "OrderUpdateSuccess") {
      notify({ message: "Purchase order updated", type: "success" })
      return data?.order.update.order
    }
    if (data?.order.update.__typename === "OrderUpdateFailure") {
      notify({ message: "Failed to update purchase order", type: "error" })
    }

    return undefined
  }, [data])

  const createdPO = useMemo(() => {
    if (createdPOResult?.order.create.__typename !== "OrderCreateSuccess") return undefined
    return createdPOResult.order.create.order as Order
  }, [createdPOResult])

  useEffect(() => {
    if (createdPOResult?.order.create.__typename === "OrderCreateSuccess") {
      notify({ message: "Purchase order created", type: "success" })
      navigate({
        to: "/procurement/purchase-orders/$purchaseOrderId",
        params: { purchaseOrderId: createdPO?.orderId },
      })
    }
    if (createdPOResult?.order.create.__typename === "OrderCreateFailure") {
      console.error(createdPOResult.order.create.error)
      notify({ message: "Couldn't create PO, please make sure products are valid", type: "error" })
    }
  }, [createdPOResult])

  const validatePOFormData = (data: PurchaseOrderFormData, selectedProducts: ProductSelectionItem[]) => {
    const sourceLocationId =
      selectedProducts.find((p) => p.locationsAssociations?.some((la) => la.locationAssociationId))
        ?.locationsAssociations?.[0]?.locationAssociationId ||
      selectedProducts.find((p) => p.company?.locationsAssociations?.some((la) => la.companyLocationAssociationId))
        ?.company?.locationsAssociations?.[0]?.companyLocationAssociationId ||
      null

    const longestLeadTime = selectedProducts?.reduce((max, line) => {
      const leadTime = line?.leadTimeInDays ?? 0
      return Math.max(max, leadTime)
    }, 0)

    // If requestedDeliveryDate is not available on PO, default to today plus longest lead time plus one day
    const requestedDeliveryDate = dayjs()
      .add(longestLeadTime ?? 0, "days")
      .add(1, "day")
      .toISOString()

    const draftPO = OrderCreateInputSchema().safeParse({
      destinationLocationAssociationId: data.shipTo,
      orderLines: selectedProducts.map((product) => ({
        numberOfPallets: 0, // FIXME: should be returned by backend
        pricePerUom: 0, // FIXME: should be returned by backend
        productId: product.productId,
        quantityInUom: 0,
        uomId: product.uomId ?? POUND_UOM_ID,
        priceUomId: POUND_UOM_ID,
      })),
      freightTerms: data.freightTerms ?? null,
      deliveryTerms: data.deliveryTerms ?? null,
      shipViaCompanyId: data.shipViaCompanyId,
      deliveryLocationId: data.deliveryLocationId ?? null,
      status: OrderStatus.Draft,
      type: OrderType.PurchaseOrder,
      requestedDeliveryDate: requestedDeliveryDate,
      sourceLocationAssociationId: sourceLocationId,
    })
    return draftPO
  }

  const handleCreatePO = useCallback(
    (data: PurchaseOrderFormData, selectedProducts: ProductSelectionItem[]) => {
      const draftPO = validatePOFormData(data, selectedProducts)
      if (!draftPO?.success) {
        console.error(draftPO?.error.issues)
        let message = "Couldn't create PO, please check the form data"
        if (!draftPO.data?.sourceLocationAssociationId) {
          message = "Couldn't create PO. Please make sure product or company have associated locations"
        }
        notify({ message, type: "error" })
        return
      }
      createPO({ input: draftPO.data })
    },
    [validatePOFormData, createPO]
  )

  return {
    createdPO,
    fetching: creatingPO,
    fetchingUpdate: updatingPO,
    handleCreatePO,
    updatedPO,
    updatePO,
  }
}
