import { z } from "zod"

import freightClassData from "@/assets/static/freight_class.json"

// Create sets of valid freight items and classes
const validFreightItems = new Set(freightClassData.map((item) => String(item.freight_item)))
const validFreightClasses = new Set(freightClassData.map((item) => String(item.freight_class)))

// Create a map of valid freight item/class pairs
const freightPairs = new Map(
  freightClassData.map((item) => [
    `${item.freight_item}-${item.freight_class}`,
    { item: String(item.freight_item), class: String(item.freight_class), description: item.freight_description },
  ])
)

export const packagingInfoSchema = z
  .object({
    freightItem: z
      .string()
      .regex(/^\d+$/, "Must be a number")
      .refine((val) => !val || validFreightItems.has(val), "Invalid Freight Item")
      .nullable(),
    freightClass: z
      .string()
      .regex(/^\d+$/, "Must be a number")
      .refine((val) => !val || validFreightClasses.has(val), "Invalid Freight Class")
      .nullable(),
    packagingType: z.string().optional(),
    packageWeight: z
      .string()
      .min(1, "Package Weight is required")
      .regex(/^\d+(\.\d+)?$/, "Must be a number"),
    packageWeightUom: z.string().default("lb"),
    packageLength: z
      .string()
      .regex(/^\d+(\.\d+)?$/, "Must be a number")
      .optional(),
    packageWidth: z
      .string()
      .regex(/^\d+(\.\d+)?$/, "Must be a number")
      .optional(),
    packageHeight: z
      .string()
      .regex(/^\d+(\.\d+)?$/, "Must be a number")
      .optional(),
    packageDimensionsUom: z.string().default("in"),
    palletWeight: z
      .string()
      .min(1, "Pallet Weight is required")
      .regex(/^\d+(\.\d+)?$/, "Must be a number"),
    palletWeightUom: z.string().default("lb"),
    palletSizeLength: z
      .string()
      .regex(/^\d+(\.\d+)?$/, "Must be a number")
      .optional(),
    palletSizeWidth: z
      .string()
      .regex(/^\d+(\.\d+)?$/, "Must be a number")
      .optional(),
    palletSizeHeight: z
      .string()
      .regex(/^\d+(\.\d+)?$/, "Must be a number")
      .optional(),
    palletSizeUom: z.string().default("in"),
    packagesPerPallet: z.string().regex(/^\d+$/, "Must be a number").optional(),
    form: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    const hasFreightItem = data.freightItem !== null && data.freightItem !== ""
    const hasFreightClass = data.freightClass !== null && data.freightClass !== ""

    // Both must be empty/null or both must be filled with valid values
    if (!((!hasFreightItem && !hasFreightClass) || (hasFreightItem && hasFreightClass))) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Both Freight Item and Freight Class must be provided together",
        path: ["form"],
      })
      return
    }

    // Skip validation if either field is empty
    if (!data.freightItem || !data.freightClass) return

    // Check if the pair exists in our valid pairs
    const key = `${data.freightItem}-${data.freightClass}`
    if (!freightPairs.has(key)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Invalid Freight Item and Class combination",
        path: ["form"],
      })
    }
  })
