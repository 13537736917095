import { Box } from "@mui/material"
import type { ColumnDef, ExpandedState, Row } from "@tanstack/react-table"
import type { FC } from "react"
import { useState } from "react"

import { InstructionGroupExpanded } from "./components/InstructionGroupExpanded"
import { ApplicableToCell, HazardousCell, IncludedWithCell, TransportationModeCell } from "./components/TableCells"
import { createExpanderCell } from "./components/TableCells/ExpanderCell/ExpanderCellWrapper"
import type { GroupedInstruction } from "./hooks/useInstructionGroups"
import { useInstructionGroups } from "./hooks/useInstructionGroups"

import { AerosBaseTable } from "@/components/Tables/Aeros/components/AerosBaseTable"
import type { RelatedProductInstruction } from "@/graphql/codegen/graphql"

type InstructionGroupsProps = {
  data: RelatedProductInstruction[]
  onEditInstruction: (instruction: RelatedProductInstruction) => void
  onDeleteInstruction: (instruction: RelatedProductInstruction) => void
}

/**
 * Main component for displaying and managing groups of product instructions.
 * Groups instructions based on applicableTo, transportationMode, and includedWith.
 * Instructions with the same property combinations are merged into a single group.
 */
export const InstructionGroups: FC<InstructionGroupsProps> = ({
  data = [],
  onEditInstruction,
  onDeleteInstruction,
}) => {
  const [expandedRow, setExpandedRow] = useState<ExpandedState>({})
  const groupedData = useInstructionGroups(data)

  const columns: ColumnDef<GroupedInstruction>[] = [
    {
      header: "Applicable To",
      cell: ApplicableToCell,
    },
    {
      header: "Transportation Mode",
      cell: TransportationModeCell,
    },
    {
      header: "Included With",
      cell: IncludedWithCell,
    },
    {
      header: "Hazardous",
      cell: HazardousCell,
    },
    {
      id: "expander",
      cell: createExpanderCell(onEditInstruction, onDeleteInstruction),
      size: 80,
    },
  ]

  return (
    <Box className='flex size-full flex-col'>
      <AerosBaseTable
        slotProps={{ bodyCellExpanded: { className: "bg-primary-100" } }}
        features={{
          tanstackOptions: {
            data: groupedData,
            columns,
            state: {
              expanded: expandedRow,
            },
            onExpandedChange: setExpandedRow,
            getRowCanExpand: (row: Row<GroupedInstruction>) => Boolean(row.original.relatedInstructions?.length),
            renderExpandedContent: InstructionGroupExpanded,
          },
        }}
      />
    </Box>
  )
}

export default InstructionGroups
