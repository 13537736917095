import { useNavigate, useSearch } from "@tanstack/react-router"
import type { OnChangeFn, SortingState } from "@tanstack/react-table"
import { getCoreRowModel, getExpandedRowModel, useReactTable } from "@tanstack/react-table"
import { useCallback, useEffect, useMemo, useState } from "react"

import { useSupplierReportSearchStore } from "../stores/useSupplierReportSearchStore"
import type { ProductSelection, ProductSelectionItem, SupplierReportExpandedState } from "../types/types"
import type { SortableAccessorKey } from "../utils/supplierReportConstants"
import { accessorKeyToSupplierSortField, supplierSortFieldToAccessoryKey } from "../utils/supplierReportConstants"
import { SupplierReportTableColumns } from "../utils/supplierReportTableColumns"

import { MINIMUM_SEARCH_LENGTH } from "@/constants/search"
import { SortingOrder } from "@/graphql/codegen/graphql"
import type { Company, CompanySortField } from "@/graphql/codegen/graphql"
import { queryClient } from "@/providers/GraphqlRouterProvider"
import { getRefinedSearchParams } from "@/screens/Companies/utils"
import useDebounce from "@/utils/useDebounce"

type UseSupplierReportTable = {
  expanded: SupplierReportExpandedState
  table: ReturnType<typeof useReactTable<Company>>
  productRowSelection: ProductSelection
  getFlexibleColumnWidth: (header: { column: { getSize: () => number } }) => string
  handleProductSelectionChange: (companyId: string, products: ProductSelectionItem[]) => void
}

export const useSupplierReportTable = (): UseSupplierReportTable => {
  const navigate = useNavigate()
  const currentSearch = useSearch({ from: "/procurement/_layout/supplier-report" })
  const { category, query, field, order, ...restSearchParams } = currentSearch

  const data = useSupplierReportSearchStore(({ data }) => data)
  const executeSearch = useSupplierReportSearchStore(({ executeSearch }) => executeSearch)

  const [expanded, setExpanded] = useState<SupplierReportExpandedState>({})
  const [productRowSelection, setProductRowSelection] = useState<ProductSelection>({})

  const sorting = useMemo(() => {
    const tableSortField = supplierSortFieldToAccessoryKey.get(field as CompanySortField) as string
    const tableSortOrder = order || SortingOrder.Asc
    return [
      {
        id: tableSortField,
        asc: tableSortOrder === SortingOrder.Asc,
        desc: tableSortOrder === SortingOrder.Desc,
      },
    ]
  }, [field, order])

  const filters = useDebounce(currentSearch, 300)

  useEffect(() => {
    if (filters?.query?.length >= MINIMUM_SEARCH_LENGTH || filters?.query === "" || !filters?.query) {
      executeSearch(queryClient, filters)
    }
  }, [executeSearch, filters])

  const handleSortingChange: OnChangeFn<SortingState> = (updater) => {
    const newSorting = updater instanceof Function ? updater(sorting) : updater

    if (newSorting.length > 0) {
      const sort = newSorting[0]
      const key = sort.id as SortableAccessorKey
      const field = accessorKeyToSupplierSortField.get(key)

      if (!field) return

      const params = {
        ...restSearchParams,
        query,
        category,
        field,
        order: sort.desc ? SortingOrder.Desc : SortingOrder.Asc,
      }

      const refinedParams = getRefinedSearchParams(params)
      void navigate({
        to: "/procurement/supplier-report",
        search: refinedParams.search,
        replace: true,
      })
    }
  }

  const table = useReactTable<Company>({
    data,
    columns: SupplierReportTableColumns,
    state: { expanded, sorting },
    manualSorting: true,
    enableSortingRemoval: false,
    onSortingChange: handleSortingChange,
    onExpandedChange: (updater) => {
      setExpanded((prev) => {
        const next = updater instanceof Function ? updater(prev) : updater
        return next as SupplierReportExpandedState
      })
    },
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: () => true,
    getRowId: (row) => row.companyId.toString(),
  })

  const getFlexibleColumnWidth = useCallback((header: { column: { getSize: () => number } }) => {
    const configuredWidth = header.column.getSize()
    return configuredWidth === 150 || !configuredWidth ? "1fr" : `${configuredWidth}px`
  }, [])

  const handleProductSelectionChange = useCallback((companyId: string, products: ProductSelectionItem[]) => {
    setProductRowSelection((prev) => ({
      ...prev,
      [companyId]: products.reduce(
        (acc, product) => {
          acc[product.productId] = product
          return acc
        },
        {} as Record<string, ProductSelectionItem>
      ),
    }))
  }, [])

  return {
    expanded,
    table,
    productRowSelection,
    getFlexibleColumnWidth,
    handleProductSelectionChange,
  }
}
