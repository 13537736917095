import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from "@mui/material"
import { CloseOutlined } from "@mui-symbols-material/w300"
import type { FC } from "react"
import { memo, useCallback, useMemo } from "react"
import { useFormContext } from "react-hook-form"

import AddInstructionsContent from "./AddInstructionsContent"
import type { FormValues } from "./types"
import { getInstructionCombinations, hasDuplicateInstructions } from "./utils/instructionManagement"

import { Button } from "@/components/common/Button"
import type { RelatedProductInstruction } from "@/graphql/codegen/graphql"

type DialogAction = "cancel" | "backdropClick" | "escapeKeyDown"

interface AddInstructionsProps {
  isOpen: boolean
  onClose: (action: DialogAction) => void
  onSubmit: (data: FormValues) => Promise<void>
  type: "add" | "edit"
  existingInstructions?: RelatedProductInstruction[]
}

const AddInstructionsModal: FC<AddInstructionsProps> = ({
  isOpen,
  onClose,
  onSubmit,
  type,
  existingInstructions = [],
}) => {
  const {
    handleSubmit,
    reset,
    watch,
    formState: { isValid, isSubmitting, isDirty },
  } = useFormContext<FormValues>()

  const formValues = watch()

  const hasDuplicates = useMemo(() => {
    // Check if any of the three fields are selected
    if (
      !formValues.applicableTo?.length ||
      !formValues.transportationMode?.length ||
      !formValues.includedWith?.length
    ) {
      return false
    }

    // Create a combination object with the current form values
    const formCombination = {
      applicableTo: formValues.applicableTo,
      transportationMode: formValues.transportationMode,
      includedWith: formValues.includedWith,
    }

    // Filter out existing combinations from the current group when editing
    const filteredExistingInstructions =
      type === "edit"
        ? existingInstructions.filter((inst) => inst.groupId !== formValues.groupId)
        : existingInstructions

    const existingCombinations = getInstructionCombinations(filteredExistingInstructions)

    // Check against each existing combination
    return existingCombinations.some((existingCombination) =>
      hasDuplicateInstructions(formCombination, existingCombination)
    )
  }, [
    formValues.applicableTo,
    formValues.transportationMode,
    formValues.includedWith,
    existingInstructions,
    type,
    formValues.groupId,
  ])

  const handleFormSubmit = useCallback(
    async (value: FormValues) => {
      try {
        await onSubmit(value)
        // Modal will be closed by the parent component after successful submission
      } catch (error) {
        // Error handling is done in the parent component
        console.error("Form submission failed:", error)
      }
    },
    [onSubmit]
  )

  const handleClose = useCallback(
    (reason: DialogAction) => {
      if (isSubmitting) return // Prevent closing while submitting
      reset()
      onClose(reason)
    },
    [onClose, reset, isSubmitting]
  )

  const handleResetClick = useCallback(() => {
    handleClose("cancel")
  }, [handleClose])

  const buttonLabelByDialogType = type === "add" ? "Add" : "Save"
  const buttonLabel = isSubmitting ? "Saving..." : buttonLabelByDialogType

  return (
    <Dialog open={isOpen} onClose={(_, reason) => handleClose(reason)} maxWidth='md' fullWidth>
      <DialogTitle className='flex items-center justify-between'>
        {type === "add" ? "Add Instructions" : "Edit Instructions"}
        <IconButton onClick={() => handleClose("cancel")} size='small' disabled={isSubmitting}>
          <CloseOutlined />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {/* when isDirty is true, it will hide alert while editing existing instructions */}
        {hasDuplicates && isDirty && (
          <Alert severity='warning' className='mb-4'>
            You have duplicate instruction group. <br /> Each combination of applicable to, transportation mode, and
            included with should be unique.
          </Alert>
        )}
        <AddInstructionsContent />
      </DialogContent>
      <DialogActions>
        <Button appearance='outlined' onClick={handleResetClick} disabled={isSubmitting}>
          Cancel
        </Button>
        <Button disabled={!isValid || isSubmitting || hasDuplicates} onClick={handleSubmit(handleFormSubmit)}>
          {buttonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default memo(AddInstructionsModal)
