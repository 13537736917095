import { Alert, MenuItem, Select, TextField } from "@mui/material"
import { useFormContext } from "react-hook-form"

import type { PackagingInfoFormData } from "./types"
import { PackagingInfoFormFieldIds } from "./types"

import { FieldGroup, FieldRow, Form, FormField } from "@/components/Forms"
import { formattedPackagingTypes } from "@/constants/productPackagingType"

const InputFieldClasses = "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2"
const textFieldSlotProps = { htmlInput: { className: InputFieldClasses } }
const selectSlotProps = { input: { className: InputFieldClasses } }

const renderSelectPlaceholder = (value: unknown) => {
  if (!value) {
    return <span className='text-gray-400'>Select One</span>
  }
  return value as string
}

interface PackagingInfoFormProps {
  disabled?: boolean
}

export const PackagingInfoForm = ({ disabled: disabledProp = false }: PackagingInfoFormProps): React.ReactElement => {
  const {
    formState: { isSubmitting, errors },
    trigger,
    register,
  } = useFormContext<PackagingInfoFormData>()

  const disabled = disabledProp || isSubmitting

  // Get form-level validation errors
  const formLevelError = errors.form?.message

  return (
    <Form>
      <FieldGroup legend='Freight' data-testid='freight-section'>
        {formLevelError && (
          <Alert severity='error' className='mb-4' data-testid='form-error-alert'>
            {formLevelError}
          </Alert>
        )}
        <FieldRow>
          <FormField
            name={PackagingInfoFormFieldIds.FreightItem}
            label='Freight Item'
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                data-testid={PackagingInfoFormFieldIds.FreightItem}
                value={field.value || ""}
                slotProps={textFieldSlotProps}
                placeholder='000000'
                type='number'
                error={!!fieldState.error || !!formLevelError}
                fullWidth
                variant='outlined'
                disabled={disabled}
                onChange={(e) => {
                  const value = e.target.value
                  field.onChange(value || null)
                  trigger([PackagingInfoFormFieldIds.FreightItem, PackagingInfoFormFieldIds.FreightClass, "form"])
                }}
              />
            )}
          />
          <FormField
            name={PackagingInfoFormFieldIds.FreightClass}
            label='Freight Class'
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                data-testid={PackagingInfoFormFieldIds.FreightClass}
                value={field.value || ""}
                slotProps={textFieldSlotProps}
                placeholder='00'
                type='number'
                error={!!fieldState.error || !!formLevelError}
                fullWidth
                variant='outlined'
                disabled={disabled}
                onChange={(e) => {
                  const value = e.target.value
                  field.onChange(value || null)
                  trigger([PackagingInfoFormFieldIds.FreightItem, PackagingInfoFormFieldIds.FreightClass, "form"])
                }}
              />
            )}
          />
        </FieldRow>
      </FieldGroup>
      <FieldGroup legend='Individual Standard Packaging' data-testid='individual-packaging-section'>
        <FieldRow>
          <FormField
            name={PackagingInfoFormFieldIds.PackagingType}
            label='Standard Packaging'
            render={({ field, fieldState }) => (
              <Select
                {...field}
                data-testid={PackagingInfoFormFieldIds.PackagingType}
                slotProps={selectSlotProps}
                error={!!fieldState.error}
                fullWidth
                variant='outlined'
                displayEmpty
                renderValue={renderSelectPlaceholder}
                disabled={disabled}
              >
                <MenuItem value='' divider>
                  Select One
                </MenuItem>
                {formattedPackagingTypes.map((packagingType) => (
                  <MenuItem key={packagingType.original} value={packagingType.original} divider>
                    {packagingType.formatted}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <FormField
            name={PackagingInfoFormFieldIds.PackageWeight}
            label='Weight per Individual Std Package (lb)'
            required
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                data-testid={PackagingInfoFormFieldIds.PackageWeight}
                slotProps={textFieldSlotProps}
                placeholder='000'
                type='number'
                error={!!fieldState.error}
                fullWidth
                variant='outlined'
                disabled={disabled}
                onChange={(e) => {
                  field.onChange(e)
                  trigger(PackagingInfoFormFieldIds.PackageWeight)
                }}
              />
            )}
          />
          <FormField name={PackagingInfoFormFieldIds.PackageWeightUom} />
        </FieldRow>
        <FieldRow>
          <FormField
            name={PackagingInfoFormFieldIds.PackageLength}
            label='Length (in)'
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                data-testid={PackagingInfoFormFieldIds.PackageLength}
                slotProps={textFieldSlotProps}
                placeholder='00'
                type='number'
                error={!!fieldState.error}
                fullWidth
                variant='outlined'
                disabled={disabled}
              />
            )}
          />
          <FormField
            name={PackagingInfoFormFieldIds.PackageWidth}
            label='Width (in)'
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                data-testid={PackagingInfoFormFieldIds.PackageWidth}
                slotProps={textFieldSlotProps}
                placeholder='00'
                type='number'
                error={!!fieldState.error}
                fullWidth
                variant='outlined'
                disabled={disabled}
              />
            )}
          />
          <FormField
            name={PackagingInfoFormFieldIds.PackageHeight}
            label='Height (in)'
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                data-testid={PackagingInfoFormFieldIds.PackageHeight}
                slotProps={textFieldSlotProps}
                placeholder='00'
                type='number'
                error={!!fieldState.error}
                fullWidth
                variant='outlined'
                disabled={disabled}
              />
            )}
          />
          <FormField name={PackagingInfoFormFieldIds.PackageDimensionsUom} />
        </FieldRow>
      </FieldGroup>
      <FieldGroup legend='Full Pallet Packaging' data-testid='pallet-packaging-section'>
        <FieldRow>
          <FormField
            name={PackagingInfoFormFieldIds.PalletWeight}
            label='Weight per Pallet (lb)'
            required
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                data-testid={PackagingInfoFormFieldIds.PalletWeight}
                slotProps={textFieldSlotProps}
                placeholder='000'
                type='number'
                error={!!fieldState.error}
                fullWidth
                variant='outlined'
                disabled={disabled}
              />
            )}
          />
          <FormField name={PackagingInfoFormFieldIds.PalletWeightUom} />
        </FieldRow>
        <FieldRow>
          <FormField
            name={PackagingInfoFormFieldIds.PalletSizeLength}
            label='Length (in)'
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                data-testid={PackagingInfoFormFieldIds.PalletSizeLength}
                slotProps={textFieldSlotProps}
                placeholder='00'
                type='number'
                error={!!fieldState.error}
                fullWidth
                variant='outlined'
                disabled={disabled}
              />
            )}
          />
          <FormField
            name={PackagingInfoFormFieldIds.PalletSizeWidth}
            label='Width (in)'
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                data-testid={PackagingInfoFormFieldIds.PalletSizeWidth}
                slotProps={textFieldSlotProps}
                placeholder='00'
                type='number'
                error={!!fieldState.error}
                fullWidth
                variant='outlined'
                disabled={disabled}
              />
            )}
          />
          <FormField
            name={PackagingInfoFormFieldIds.PalletSizeHeight}
            label='Height (in)'
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                data-testid={PackagingInfoFormFieldIds.PalletSizeHeight}
                slotProps={textFieldSlotProps}
                placeholder='00'
                type='number'
                error={!!fieldState.error}
                fullWidth
                variant='outlined'
                disabled={disabled}
              />
            )}
          />
          <FormField name={PackagingInfoFormFieldIds.PalletSizeUom} />
        </FieldRow>
        <FieldRow>
          <FormField
            name={PackagingInfoFormFieldIds.PackagesPerPallet}
            label='Quantity per Pallet (Std. Packaging)'
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                data-testid={PackagingInfoFormFieldIds.PackagesPerPallet}
                slotProps={textFieldSlotProps}
                placeholder='000'
                type='number'
                error={!!fieldState.error}
                fullWidth
                variant='outlined'
                disabled={disabled}
              />
            )}
          />
        </FieldRow>
      </FieldGroup>
      {/* invisible field to handle form-level errors */}
      <input {...register("form")} type='hidden' />
    </Form>
  )
}

export { PackagingInfoFormFieldIds }
export type { PackagingInfoFormProps }
