import type { SortingOrder } from "@/graphql/codegen/graphql"
import { OrderSortField, ProductSortField } from "@/graphql/codegen/graphql"

export interface SearchFilters {
  query?: string
  filter?: Record<string, unknown>
  field?: OrderSortField
  order?: SortingOrder
  [key: string]: unknown
}

export type ProductPurchaseOrderSearchFilters = SearchFilters & {
  category?: string
  field?: OrderSortField | ProductSortField
  order?: SortingOrder
  start?: string
  end?: string
}

export interface TableSorting {
  id: string
  desc: boolean
}

export const SortablePurchaseOrderAccessorKeys = {
  companyName: "companyName",
  orderId: "orderId",
  status: "status",
  shipStatus: "shipStatus",
  requestedDeliveryDate: "requestedDeliveryDate",
  expectedDeliveryDate: "expectedDeliveryDate",
  productId: "productId",
  companyProductDescription: "companyProductDescription",
}

export const SortableProductPurchaseOrderAccessorKeys = {
  productId: "productId",
  companyProductDescription: "companyProductDescription",
}

export type SortablePurchaseOrderAccessorKey =
  (typeof SortablePurchaseOrderAccessorKeys)[keyof typeof SortablePurchaseOrderAccessorKeys]

export type SortableProductPurchaseOrderAccessorKey =
  (typeof SortableProductPurchaseOrderAccessorKeys)[keyof typeof SortableProductPurchaseOrderAccessorKeys]

export const accessoryKeyToOrderSortField: ReadonlyMap<SortablePurchaseOrderAccessorKey, OrderSortField> = new Map([
  [SortablePurchaseOrderAccessorKeys.companyName, OrderSortField.CompanyName],
  [SortablePurchaseOrderAccessorKeys.orderId, OrderSortField.OrderId],
  [SortablePurchaseOrderAccessorKeys.requestedDeliveryDate, OrderSortField.RequestedDate],
  [SortablePurchaseOrderAccessorKeys.expectedDeliveryDate, OrderSortField.ExpectedDate],
  [SortablePurchaseOrderAccessorKeys.status, OrderSortField.Status],
  [SortablePurchaseOrderAccessorKeys.shipStatus, OrderSortField.ShipStatus],
])

export const accessoryProductKeyToOrderSortField: ReadonlyMap<
  SortableProductPurchaseOrderAccessorKey,
  ProductSortField
> = new Map([
  [SortableProductPurchaseOrderAccessorKeys.productId, ProductSortField.ProductId],
  [SortableProductPurchaseOrderAccessorKeys.companyProductDescription, ProductSortField.CompanyProductDescription],
])

export const orderSortFieldToAccessoryKey: ReadonlyMap<OrderSortField, SortablePurchaseOrderAccessorKey> = new Map([
  [OrderSortField.CompanyName, SortablePurchaseOrderAccessorKeys.companyName],
  [OrderSortField.OrderId, SortablePurchaseOrderAccessorKeys.orderId],
  [OrderSortField.RequestedDate, SortablePurchaseOrderAccessorKeys.requestedDeliveryDate],
  [OrderSortField.ExpectedDate, SortablePurchaseOrderAccessorKeys.expectedDeliveryDate],
  [OrderSortField.Status, SortablePurchaseOrderAccessorKeys.status],
  [OrderSortField.ShipStatus, SortablePurchaseOrderAccessorKeys.shipStatus],
])

export const productOrderSortFieldToAccessoryKey: ReadonlyMap<
  ProductSortField,
  SortableProductPurchaseOrderAccessorKey
> = new Map([
  [ProductSortField.ProductId, SortableProductPurchaseOrderAccessorKeys.productId],
  [ProductSortField.ProductSupplierDescription, SortableProductPurchaseOrderAccessorKeys.companyProductDescription],
])
