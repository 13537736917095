import { zodResolver } from "@hookform/resolvers/zod"
import { Box, Modal, Typography } from "@mui/material"
import { CloseOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"

import { Button } from "@/components/common/Button"
import { CancelTemplate } from "@/components/common/Modals/CancelTemplate.tsx"
import type {
  Company,
  CompanyCreateFailure,
  CompanyCreateInput,
  CompanyCreateSuccess,
} from "@/graphql/codegen/graphql.ts"
import { CompanyStatus } from "@/graphql/codegen/graphql.ts"
import CreateNewCompanyForm from "@/screens/Companies/create/components/CreateNewCompanyForm.tsx"
import { CompanyModalSchema } from "@/screens/Companies/create/components/CreateNewCompanySchema.tsx"
import { useCreateNewCompanyModal } from "@/screens/Companies/hooks/useCreateNewCompanyModal.tsx"
import { useNotificationsStore } from "@/stores/useNotificationsStore"
import type { TreeNode } from "@/types/tree.ts"

const NewCompanySuccessMessage = memo(() => (
  <>
    <p>A new company has been successfully created.</p>
    <p>Add required information to complete company's setup or save and come back later.</p>
  </>
))

NewCompanySuccessMessage.displayName = "NewCompanySuccessMessage"

type CreateNewCompanyModalContainerProps = {
  isOpen: boolean
  onClose: (data?: CompanyCreateInput, reason?: "backdropClick" | "escapeKeyDown") => void
  onSuccess?: (value: ((prevState: boolean) => boolean) | boolean | Company) => void
}

type CompanyCreateInputWithAccountManagers = CompanyCreateInput & {
  accountManagers: { accountManagerId: string }[]
}

const defaultFormValues: CompanyCreateInputWithAccountManagers = {
  name: "",
  types: [],
  accountManagers: [],
  status: CompanyStatus.Active,
}

const CreateNewCompanyModal: FunctionComponent<CreateNewCompanyModalContainerProps> = ({
  isOpen,
  onClose,
  onSuccess,
}) => {
  const [modalInput, setModalInput] = useState("")
  const [isCancelConfirmationOpen, setIsCancelConfirmationOpen] = useState(false)
  const [step, setStep] = useState(1)

  const { companyNameList, companyTypeList, accountsManagerList, createCompany } = useCreateNewCompanyModal(modalInput)
  const notify = useNotificationsStore((state) => state.enqueueNotification)

  const methods = useForm<CompanyCreateInputWithAccountManagers>({
    defaultValues: defaultFormValues,
    resolver: zodResolver(CompanyModalSchema),
    mode: "onChange",
  })

  const {
    setError,
    clearErrors,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods

  const handleNextStep = useCallback(() => {
    setStep((prevStep) => prevStep + 1)
  }, [])

  const handleCancel = useCallback(() => {
    setIsCancelConfirmationOpen(true)
  }, [])

  const handleModalClose = useCallback(() => {
    reset(defaultFormValues)
    clearErrors()
    setStep(1)
    setModalInput("")
    onClose()
  }, [onClose, reset, clearErrors])

  const confirmCancel = useCallback(() => {
    setIsCancelConfirmationOpen(false)
    handleModalClose()
  }, [handleModalClose])

  const handleCompanyNameChange = useCallback((value: string) => {
    setModalInput(value)
  }, [])

  useEffect(() => {
    if (!isOpen) {
      setStep(1)
      setIsCancelConfirmationOpen(false)
      reset(defaultFormValues)
      clearErrors()
      setModalInput("")
    }
  }, [isOpen, reset, clearErrors])

  useEffect(() => {
    if (modalInput) {
      const isDuplicate = companyNameList.some((name) => name.toLowerCase().trim() === modalInput.toLowerCase().trim())

      if (isDuplicate) {
        setError("name", {
          type: "duplicate",
          message: "Company with this name already exists.",
        })
      } else {
        const currentError = errors.name
        if (currentError?.type === "duplicate") {
          clearErrors("name")
        }
      }
    }
  }, [modalInput, companyNameList, setError, clearErrors, errors.name?.type])

  const handleFormSubmit = useCallback(
    async (data: CompanyCreateInputWithAccountManagers) => {
      const filteredAccountsManagerList = accountsManagerList
        .filter((accountManager) =>
          data.accountManagers?.some((selected) => selected.accountManagerId === accountManager.accountManagerId)
        )
        .map(({ accountManagerId }) => ({ accountManagerId }))

      const { error, data: company } = await createCompany({
        input: {
          accountManagers: filteredAccountsManagerList,
          name: data.name,
          status: CompanyStatus.Active,
          types: data.types,
        },
      })

      const hasError =
        error?.message ||
        (company?.company.create.__typename === "CompanyCreateFailure" && Boolean(company?.company.create.error))

      if (hasError) {
        setError("name", {
          type: "server",
          message: error?.message ?? (company?.company.create as CompanyCreateFailure).error?.message,
        })
        notify({
          type: "error",
          silent: false,
          message: error?.message ?? "Failed to create company",
        })
        return
      }

      if (onSuccess) {
        onSuccess((company?.company.create as CompanyCreateSuccess).company)
        notify({
          type: "success",
          silent: false,
          content: <NewCompanySuccessMessage />,
        })
        handleModalClose()
      }
    },
    [accountsManagerList, createCompany, notify, onSuccess, setError, handleModalClose]
  )

  const onSubmit = useMemo(() => handleSubmit(handleFormSubmit), [handleSubmit, handleFormSubmit])

  const modalStyles = useMemo(
    () => ({
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "white",
      boxShadow: 24,
      width: 576,
      borderRadius: 2,
    }),
    []
  )

  return (
    <Modal
      open={isOpen}
      onClose={handleCancel}
      disableEscapeKeyDown
      onKeyDown={(e) => {
        const target = e.target as HTMLElement
        if (e.key === "Enter" && !isCancelConfirmationOpen && target.tagName !== "BUTTON") {
          e.preventDefault()
        }
      }}
    >
      <Box sx={modalStyles}>
        {isCancelConfirmationOpen ? (
          <CancelTemplate onClose={setIsCancelConfirmationOpen} confirmCancel={confirmCancel} />
        ) : (
          <>
            <Box sx={{ display: "flex", justifyContent: "space-between", p: 2, pl: 5 }}>
              <Typography variant='h6' color='primary'>
                New Company
              </Typography>
              <Button size='small' appearance='text' onClick={handleCancel}>
                <CloseOutlined />
              </Button>
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }} />
            <FormProvider {...methods}>
              <CreateNewCompanyForm
                companyNameList={companyNameList}
                companyTypeList={companyTypeList as TreeNode<string>[]}
                accountsManagerList={accountsManagerList}
                step={step}
                onChange={handleCompanyNameChange}
                handleCancel={handleCancel}
                handleNextStep={handleNextStep}
                onSubmit={onSubmit}
              />
            </FormProvider>
          </>
        )}
      </Box>
    </Modal>
  )
}

export default memo(CreateNewCompanyModal)
