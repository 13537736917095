import { useMemo } from "react"

import { normalizeProperties } from "../utils/normalizeInstructions"

import type { RelatedProductInstruction } from "@/graphql/codegen/graphql"

export type GroupedInstruction = RelatedProductInstruction & {
  relatedInstructions: RelatedProductInstruction[]
  groupKey: string
  applicableToDisplay: string
  transportationModeDisplay: string
  includedWithDisplay: string
}

type GroupAccumulator = Record<string, GroupedInstruction>

const updateDisplayProperty = (currentValue: string, newValue: string | undefined): string => {
  if (!newValue || currentValue.includes(newValue)) return currentValue
  return currentValue ? `${currentValue}, ${newValue}` : newValue
}

const normalizeDisplayValue = (value: string): string =>
  [...new Set(value.split(", "))].sort((a, b) => a.localeCompare(b)).join(", ")

const createInitialGroup = (
  instruction: RelatedProductInstruction,
  normalizedProps: ReturnType<typeof normalizeProperties>
): GroupedInstruction => ({
  ...instruction,
  groupKey: instruction.groupId ?? "",
  relatedInstructions: [instruction],
  applicableToDisplay: normalizedProps.applicableTo,
  transportationModeDisplay: normalizedProps.transportationMode,
  includedWithDisplay: normalizedProps.includedWith,
})

const updateGroupDisplayProperties = (group: GroupedInstruction, newGroup: GroupedInstruction): void => {
  group.applicableToDisplay = updateDisplayProperty(group.applicableToDisplay, newGroup.applicableToDisplay)
  group.transportationModeDisplay = updateDisplayProperty(
    group.transportationModeDisplay,
    newGroup.transportationModeDisplay
  )
  group.includedWithDisplay = updateDisplayProperty(group.includedWithDisplay, newGroup.includedWithDisplay)
}

/**
 * Custom hook for grouping instructions based on their groupId
 */
export const useInstructionGroups = (data: RelatedProductInstruction[]): GroupedInstruction[] => {
  return useMemo(() => {
    // Group by groupId with normalized properties
    const groups = data.reduce<GroupAccumulator>((acc, instruction) => {
      const groupId = instruction.groupId ?? ""
      const normalizedProps = normalizeProperties(instruction)

      if (!acc[groupId]) {
        acc[groupId] = createInitialGroup(instruction, normalizedProps)
      } else {
        const group = acc[groupId]
        group.relatedInstructions.push(instruction)
        updateGroupDisplayProperties(group, createInitialGroup(instruction, normalizedProps))
      }
      return acc
    }, {})

    // Normalize and sort
    return Object.values(groups).map((group) => ({
      ...group,
      applicableToDisplay: normalizeDisplayValue(group.applicableToDisplay),
      transportationModeDisplay: normalizeDisplayValue(group.transportationModeDisplay),
      includedWithDisplay: normalizeDisplayValue(group.includedWithDisplay),
      relatedInstructions: [...new Set(group.relatedInstructions)].sort((a, b) =>
        (a.productInstructionId ?? "").localeCompare(b.productInstructionId ?? "")
      ),
    }))
  }, [data])
}
