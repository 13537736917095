import { zodResolver } from "@hookform/resolvers/zod"
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { FormProvider, useForm } from "react-hook-form"

import { PackagingInfoForm } from "./PackagingInfoForm"
import { packagingInfoSchema } from "./schema"
import type { PackagingInfoFormData, PackagingInfoModalProps } from "./types"

import { Button } from "@/components/common/Button"

const initialDefaultValues: Partial<PackagingInfoFormData> = {
  freightItem: null,
  freightClass: null,
  packageWeightUom: "lb",
  packageDimensionsUom: "in",
  palletWeightUom: "lb",
  palletSizeUom: "in",
  form: undefined,
}

const numericFields = [
  "packageWeight",
  "packageLength",
  "packageWidth",
  "packageHeight",
  "palletWeight",
  "palletSizeLength",
  "palletSizeWidth",
  "palletSizeHeight",
  "packagesPerPallet",
] as const

export const PackagingInfoModal = ({
  open,
  onClose,
  onSubmit,
  defaultValues: defaultValuesProp,
  loading,
}: PackagingInfoModalProps): React.ReactElement => {
  const methods = useForm<PackagingInfoFormData>({
    resolver: zodResolver(packagingInfoSchema),
    defaultValues: initialDefaultValues,
    values: defaultValuesProp
      ? ({ ...initialDefaultValues, ...defaultValuesProp } as PackagingInfoFormData)
      : undefined,
    mode: "onChange",
  })

  const {
    handleSubmit,
    formState: { isValid },
  } = methods

  const handleSave = handleSubmit((data) => {
    const { form, ...rest } = data

    // Convert numeric fields to numbers
    const submitData = Object.entries(rest).reduce(
      (acc, [key, value]) => {
        if (numericFields.includes(key as (typeof numericFields)[number]) && value) {
          acc[key] = Number(value)
        } else {
          acc[key] = value
        }
        return acc
      },
      {} as Record<string, unknown>
    )

    return onSubmit(submitData)
  })

  return (
    <Dialog open={open} onClose={onClose} maxWidth='lg' fullWidth>
      <DialogTitle>Packaging Information</DialogTitle>
      <FormProvider {...methods}>
        <DialogContent>
          <PackagingInfoForm disabled={loading} />
        </DialogContent>
        <DialogActions className='justify-between px-10 pb-10'>
          <Button appearance='outlined' onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button onClick={handleSave} disabled={!isValid || loading}>
            Save
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  )
}
