import { useMemo } from "react"
import type { OperationContext } from "urql"
import { useQuery } from "urql"

import { ProductCommpanyLocationAssociationsDocument } from "@/graphql/codegen/graphql.ts"
import type {
  CompanyGetFailure,
  CompanyGetSuccess,
  CompanyLocationAssociation,
  Query,
} from "@/graphql/codegen/graphql.ts"

export const useGetLocationAssociation = ({
  companyId,
}: {
  companyId: string
}): {
  locationsAssociationsList: CompanyLocationAssociation[]
  locationsAssociationsListFetching: boolean
  reexecuteLocationsAssociationsQuery: (opts?: Partial<OperationContext>) => void
} => {
  const [
    { data: locationAssociationsListData, fetching: locationsAssociationsListFetching },
    reexecuteLocationsAssociationsQuery,
  ] = useQuery<Pick<Query, "company">>({
    query: ProductCommpanyLocationAssociationsDocument,
    variables: { companyId },
    pause: !companyId,
  })

  const locationsAssociationsList = useMemo(() => {
    const isSuccess = (locationAssociationsListData?.company?.get as CompanyGetSuccess)?.company
    if (!isSuccess) {
      const error = (locationAssociationsListData?.company?.get as CompanyGetFailure)?.error

      if (error?.message) {
        console.error("Failed to fetch location associations", error.message)
      }
      return []
    }
    return isSuccess.locationsAssociations || []
  }, [locationAssociationsListData])

  return {
    locationsAssociationsList,
    locationsAssociationsListFetching,
    reexecuteLocationsAssociationsQuery,
  }
}
