import { zodResolver } from "@hookform/resolvers/zod"
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography } from "@mui/material"
import { CheckCircleFilledRounded, CloseOutlined } from "@mui-symbols-material/w300"
import { type FC, useEffect } from "react"
import { useForm } from "react-hook-form"

import { Button } from "@/components/common/Button"
import { PhoneNumberInput } from "@/components/common/Phone/PhoneInput"
import {
  type AddContactDialogProps,
  type AddContactQueryProps,
  AddContactQuerySchema,
} from "@/screens/Companies/types/dialogProps"

export const AddContactDialog: FC<AddContactDialogProps> = ({ data, open, onClose, onSubmit, addressDetails }) => {
  const methods = useForm<AddContactQueryProps>({
    values: data,
    resolver: zodResolver(AddContactQuerySchema),
    mode: "onBlur",
  })

  useEffect(() => {
    methods.reset(data)
  }, [data, methods])

  const handleFormSubmit = methods.handleSubmit((formData) => {
    onSubmit(formData)
    methods.reset()
  })

  return (
    <Dialog open={open} onClose={(_, reason) => onClose({ data: methods.getValues(), reason })} fullWidth>
      <DialogTitle className='pl-10 font-normal text-primary'>Add Location Contact</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={() => onClose({ data: methods.getValues(), reason: "cancel" })}
        className='absolute right-4 top-4 text-primary'
      >
        <CloseOutlined />
      </IconButton>
      <Divider />
      <DialogContent classes={{ root: "p-10" }}>
        <form className='space-y-6'>
          <Box className='space-y-4'>
            <Box className='mb-2' display='flex' alignItems='center'>
              <Typography
                variant='body1'
                fontWeight={700}
                className='leading-6 tracking-[0.15px] text-gray-700'
                display='flex'
                alignItems='center'
              >
                1. Address Details
                <CheckCircleFilledRounded className='ml-1 text-success-500' />
              </Typography>
            </Box>
            <Box className='px-10'>
              <p className='py-0 text-sm text-gray-900'>Address Line 1: {addressDetails?.address1 || "-"}</p>
              {addressDetails?.address2 && (
                <p className='py-0 text-sm text-gray-900'>Address Line 2: {addressDetails.address2 || "-"}</p>
              )}
              <p className='py-0 text-sm text-gray-900'>Country: {addressDetails?.country?.name || "-"}</p>
              {addressDetails?.region?.isoCode && (
                <p className='py-0 text-sm text-gray-900'>State: {addressDetails.region?.name || "-"}</p>
              )}
              <p className='py-0 text-sm text-gray-900'>City: {addressDetails?.city || "-"}</p>
              {addressDetails?.postalCode && (
                <p className='py-0 text-sm text-gray-900'>Zip Code(Postal Code): {addressDetails.postalCode || "-"}</p>
              )}
              <p className='py-0 text-sm text-gray-900'>Location Name: {addressDetails?.name || "-"}</p>
            </Box>
          </Box>
          <Typography variant='body1' fontWeight={700} className='pb-10 leading-6 tracking-[0.15px] text-gray-700'>
            2. Contact Details
          </Typography>
          <Box className='space-y-10'>
            <PhoneNumberInput control={methods.control} label='Phone' name='phoneNumber' />
            <PhoneNumberInput control={methods.control} label='Alt Phone' name='altPhoneNumber' />
            <PhoneNumberInput control={methods.control} label='Fax' name='faxNumber' />
          </Box>
        </form>
      </DialogContent>
      <DialogActions className={"justify-between px-10 pb-10"}>
        <Button appearance='outlined' onClick={() => onClose({ data: methods.getValues(), reason: "cancel" })}>
          Cancel
        </Button>
        <Button onClick={handleFormSubmit} disabled={!methods.formState.isValid}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
