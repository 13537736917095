import { Box, CircularProgress, Paper } from "@mui/material"
import { createFileRoute, useLoaderData } from "@tanstack/react-router"
import { Suspense, lazy, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"

import { PDFProvider } from "@/screens/Procurement/PurchaseOrder/contexts/PDFContext"
import { PurchaseOrderProvider } from "@/screens/Procurement/PurchaseOrder/contexts/PurchaseOrderContext"
import { routeDataPreload } from "@/screens/Procurement/PurchaseOrder/routeDataPreload"
import { createPDFStore } from "@/screens/Procurement/PurchaseOrder/stores/usePDFStore"
import theme from "@/styles/mui-theme"

const CommentsContainer = lazy(() =>
  import("@/screens/Procurement/PurchaseOrder/components/CommentsContainer").then((m) => ({
    default: m.CommentsContainer,
  }))
)
const NotesContainer = lazy(() =>
  import("@/screens/Procurement/PurchaseOrder/components/NotesContainer").then((m) => ({ default: m.NotesContainer }))
)
const OrderPackagingSummary = lazy(() =>
  import("@/screens/Procurement/PurchaseOrder/components/OrderPackagingSummary").then((m) => ({
    default: m.OrderPackagingSummary,
  }))
)
const PurchaseOrderHeader = lazy(() =>
  import("@/screens/Procurement/PurchaseOrder/components/PurchaseOrderHeader").then((m) => ({
    default: m.PurchaseOrderHeader,
  }))
)
const PurchaseOrderHeaderActionsContainer = lazy(() =>
  import("@/screens/Procurement/PurchaseOrder/components/PurchaseOrderHeaderActionsContainer").then((m) => ({
    default: m.PurchaseOrderHeaderActionsContainer,
  }))
)
const DeliveryTermsAndShippingLegsContainer = lazy(() =>
  import("@/screens/Procurement/PurchaseOrder/DeliveryTermsAndShippingLegs/DeliveryTermsAndShippingLegsContainer").then(
    (m) => ({ default: m.DeliveryTermsAndShippingLegsContainer })
  )
)
const MoreProductsContainer = lazy(() =>
  import("@/screens/Procurement/PurchaseOrder/MoreProducts/components/MoreProductsContainer").then((m) => ({
    default: m.MoreProductsContainer,
  }))
)
const ProductAddedContainer = lazy(() =>
  import("@/screens/Procurement/PurchaseOrder/ProductAdded/components/ProductAddedContainer").then((m) => ({
    default: m.ProductAddedContainer,
  }))
)

const LoadingSpinner = () => (
  <Box className='flex h-screen items-center justify-center'>
    <CircularProgress
      size={48}
      className='transition-opacity duration-300 ease-in-out'
      sx={{
        color: theme.palette.primary.main,
      }}
    />
  </Box>
)

const PurchaseOrderContent = () => {
  const form = useForm()

  return (
    <Box className='size-full px-6 pb-6'>
      <Box className='flex h-full flex-col gap-4'>
        <Paper
          elevation={0}
          className='flex min-h-0 grow flex-col space-y-8 overflow-y-auto overflow-x-hidden bg-white p-6'
        >
          <FormProvider {...form}>
            <Suspense fallback={<LoadingSpinner />}>
              <PurchaseOrderHeaderActionsContainer />
              <Box className='flex flex-col gap-2'>
                <PurchaseOrderHeader />
                <DeliveryTermsAndShippingLegsContainer />
              </Box>
              <ProductAddedContainer />
              <OrderPackagingSummary />
              <Box className='flex flex-col gap-16'>
                <Box className='flex flex-col gap-5'>
                  <NotesContainer />
                  <CommentsContainer />
                  <MoreProductsContainer />
                </Box>
              </Box>
            </Suspense>
          </FormProvider>
        </Paper>
      </Box>
    </Box>
  )
}

const PurchaseOrderRoute = () => {
  const { store } = useLoaderData({ from: "/procurement/_layout_/purchase-orders/$purchaseOrderId" })
  const pdfStore = useMemo(() => createPDFStore(), [])

  return (
    <PurchaseOrderProvider initialStore={store}>
      <PDFProvider initialStore={pdfStore}>
        <Suspense fallback={<LoadingSpinner />}>
          <PurchaseOrderContent />
        </Suspense>
      </PDFProvider>
    </PurchaseOrderProvider>
  )
}

export const Route = createFileRoute("/procurement/_layout_/purchase-orders/$purchaseOrderId")({
  loader: routeDataPreload,
  component: PurchaseOrderRoute,
})
