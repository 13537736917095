import { twMerge } from "tailwind-merge"

interface FieldErrorError {
  message?: string
}

interface FieldErrorProps {
  error?: FieldErrorError
  className?: string
}

/**
 * Displays an error message for a form field
 *
 * @param props The component's props
 * @param props.error The error object to display. Optional
 * @param props.className The class name to apply to the error message. Optional
 *
 * @example
 * ```tsx
 * <FieldError error={{ message: "This field is required" }} />
 * ```
 */
const FieldError = ({ error, className, ...rest }: FieldErrorProps): React.ReactElement | null => {
  const errorMessage = error?.message

  return errorMessage ? (
    <span className={twMerge("text-sm text-red-600", className)} {...rest}>
      {errorMessage}
    </span>
  ) : (
    <span className={twMerge("invisible text-sm text-red-600", className)} {...rest}>
      &nbsp;
    </span>
  )
}

export default FieldError
export { FieldError }
export type { FieldErrorProps }
