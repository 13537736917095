import { zodResolver } from "@hookform/resolvers/zod"
import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Typography } from "@mui/material"
import { AddOutlined, EditOutlined, MoreVertOutlined, WarningOutlined } from "@mui-symbols-material/w300"
import { useParams } from "@tanstack/react-router"
import { type ReactElement, useCallback, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation } from "urql"

import AddProductRegulatoryModal from "./AddProductRegulatoryModal"
import { AddProductRegulatorySchema } from "./AddProductRegulatorySchema"
import RegulatoryDetailsTable from "./RegulatoryDetailsTable"
import { useProductRegulatory } from "./useProductRegulatory"

import { InformationMissingAlert } from "@/components/common/Alerts/InformationMissingAlert"
import { Button } from "@/components/common/Button"
import type { Mutation, ProductMutationUpdateArgs, ProductUpdateFailure } from "@/graphql/codegen/graphql"
import { UpdateProductMutationDocument } from "@/graphql/codegen/graphql"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

export const RegulatoryContainer = (): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [addRegulatoryModal, setAddRegulatoryModal] = useState(false)
  const { enqueueNotification } = useNotificationsStore()
  const methods = useForm({
    defaultValues: {
      cassRegistryId: "",
      unNumber: "",
      isRohsCompliant: false,
      isReachSvhcCompliant: false,
    },
    resolver: zodResolver(AddProductRegulatorySchema),
    mode: "onChange",
  })
  const { productId: queryCompanyId } = useParams({
    from: "/product/$productId/edit",
  })

  const { data: productRegulatory, fetch: fetchProductRegulatory } = useProductRegulatory({
    productId: Number(queryCompanyId),
  })
  const [, updateProduct] = useMutation<Pick<Mutation, "product">, ProductMutationUpdateArgs>(
    UpdateProductMutationDocument
  )

  const { setError } = methods

  const {
    formState: { isValid },
    watch,
    reset,
  } = methods

  const formValues = watch()
  const hasValidValue = Boolean(
    formValues.cassRegistryId || formValues.unNumber || formValues.isRohsCompliant || formValues.isReachSvhcCompliant
  )
  const isSubmitEnabled = isValid && hasValidValue

  const showProductRegulatory =
    productRegulatory?.cassRegistryId ||
    productRegulatory?.unNumber ||
    productRegulatory?.isRohsCompliant ||
    productRegulatory?.isReachSvhcCompliant

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleEdit = () => {
    onEditHandleClick()
    handleCloseMenu()
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleSubmit = useCallback(
    async (data: {
      cassRegistryId: string
      unNumber: string
      isRohsCompliant: boolean
      isReachSvhcCompliant: boolean
    }) => {
      const { error, data: result } = await updateProduct({
        input: {
          ...data,
          productId: Number(queryCompanyId),
          cassRegistryId: data.cassRegistryId || null,
          unNumber: data.unNumber || null,
        },
      })
      if (error || (result?.product.update as ProductUpdateFailure)?.error) {
        setError("root", {
          type: "server",
          message: error?.message || (result?.product?.update as ProductUpdateFailure)?.error?.message,
        })

        enqueueNotification({
          type: "error",
          silent: false,
          content: <p>Failed to {showProductRegulatory ? "update" : "add"} the regulatory information</p>,
        })
      } else {
        enqueueNotification({
          type: "success",
          silent: false,
          content: <p>Regulatory Information successfully {showProductRegulatory ? "updated" : "added"}.</p>,
        })
      }
      reset()
      setAddRegulatoryModal(false)
      fetchProductRegulatory({ productId: Number(queryCompanyId) })
    },
    [enqueueNotification, queryCompanyId, setError, showProductRegulatory, updateProduct, fetchProductRegulatory, reset]
  )

  const onEditHandleClick = useCallback(() => {
    reset({
      cassRegistryId: productRegulatory?.cassRegistryId || "",
      unNumber: productRegulatory?.unNumber || "",
      isRohsCompliant: productRegulatory?.isRohsCompliant ?? false,
      isReachSvhcCompliant: productRegulatory?.isReachSvhcCompliant ?? false,
    })
    setAddRegulatoryModal(true)
  }, [
    reset,
    productRegulatory?.unNumber,
    productRegulatory?.isRohsCompliant,
    productRegulatory?.isReachSvhcCompliant,
    productRegulatory?.cassRegistryId,
  ])

  const handleCloseModal = useCallback(() => {
    reset()
    setAddRegulatoryModal(false)
  }, [reset])

  return (
    <Box className='flex flex-col gap-4'>
      <Paper className='border border-gray-300 p-6' elevation={0}>
        <Box className='flex items-center justify-between gap-3'>
          <Typography variant='h6' className='mb-6'>
            General Regulatory Information
          </Typography>
          <IconButton color='primary' aria-label='company actions' onClick={handleOpenMenu}>
            <MoreVertOutlined />
          </IconButton>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
            <MenuItem onClick={handleEdit}>
              <ListItemIcon>
                <EditOutlined />
              </ListItemIcon>
              <ListItemText primary='Edit' />
            </MenuItem>
          </Menu>
        </Box>
        {showProductRegulatory ? (
          <RegulatoryDetailsTable productDetails={productRegulatory} />
        ) : (
          <InformationMissingAlert
            icon={<WarningOutlined color='primary' />}
            title='Start by adding regulatory information'
            description='No regulatory information has been added yet. Once you add some, you will be able to see it here.'
            action={
              <Button
                color='inherit'
                size='small'
                appearance='outlined'
                className='text-primary-500'
                startIcon={<AddOutlined />}
                onClick={() => setAddRegulatoryModal(true)}
              >
                Add Regulatory Information
              </Button>
            }
          />
        )}
        <FormProvider {...methods}>
          <AddProductRegulatoryModal
            open={addRegulatoryModal}
            onClose={handleCloseModal}
            onSubmit={handleSubmit}
            isUpdate={!!showProductRegulatory}
            isSubmitEnabled={isSubmitEnabled}
          />
        </FormProvider>
      </Paper>
    </Box>
  )
}
