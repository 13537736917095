import { Checkbox, MenuItem } from "@mui/material"
import { memo, useRef } from "react"

import { FormField } from "@/components/Forms"
import MultiSelect from "@/components/MultiSelect/MultiSelect"
import type { AccountManager } from "@/graphql/codegen/graphql"
import { useAccountManagers } from "@/hooks/useAccountManagers"

type AccountManagerSelectProps = {
  name: string
}

const AccountManagerSelect = ({ name }: AccountManagerSelectProps) => {
  const { visibleManagers, loadMore, fetching } = useAccountManagers()
  const hasMoreRef = useRef(true)

  const options = visibleManagers.map((manager) => ({
    value: manager.accountManagerId,
    label: `${manager.firstName} ${manager.lastName}`,
  }))

  const handleChange = (selectedIds: string[]) => {
    const selectedManagers = selectedIds
      .map((id) => visibleManagers.find((manager) => manager?.accountManagerId === id))
      .filter(Boolean)
    return selectedManagers
  }

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget

    // Check if we're near the bottom and have more items to load
    if (!fetching && hasMoreRef.current && scrollHeight - scrollTop <= clientHeight * 1.5) {
      const currentLength = visibleManagers.length
      loadMore()

      // If the length is not a multiple of the page size (20), we've reached the end
      if (currentLength % 20 !== 0) {
        hasMoreRef.current = false
      }
    }
  }

  return (
    <FormField
      name={name}
      label='Account Manager'
      render={({ field, fieldState: { error } }) => (
        <MultiSelect
          id='account-manager-select'
          value={field.value?.map((manager: AccountManager) => manager.accountManagerId) || []}
          onChange={(selectedIds: string[]) => field.onChange(handleChange(selectedIds))}
          options={options}
          error={Boolean(error)}
          variant='default'
          MenuProps={{
            PaperProps: {
              onScroll: handleScroll,
              style: { maxHeight: 300 },
            },
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox
                checked={field.value?.some((manager: AccountManager) => manager.accountManagerId === option.value)}
              />
              {option.label}
            </MenuItem>
          ))}
          {fetching && <MenuItem disabled>Loading more...</MenuItem>}
        </MultiSelect>
      )}
    />
  )
}

export default memo(AccountManagerSelect)
