import { create } from "zustand"

import { MINIMUM_SEARCH_LENGTH } from "@/constants/search"
import type { Order, OrderSortField, PurchaseOrderListQuery, SortingOrder } from "@/graphql/codegen/graphql.ts"
import { PurchaseOrderListDocument } from "@/graphql/codegen/graphql.ts"
import { type SearchFilters, createSearchStore } from "@/stores/createSearchStore"

export type PurchaseOrderSearchFilters = SearchFilters & {
  category?: string
  field?: OrderSortField
  order?: SortingOrder
  start?: string
  end?: string
}

const deduplicateOrders = (newData: Order[], existingData: Order[]): Order[] => {
  const uniqueIds = new Set(existingData.map((order) => order.orderId))
  return [...existingData, ...newData.filter((order) => !uniqueIds.has(order.orderId))]
}

export const usePurchaseOrdersSearchStore = create(
  createSearchStore<Order, PurchaseOrderSearchFilters, PurchaseOrderListQuery>({
    documentNode: PurchaseOrderListDocument,
    deduplicateData: deduplicateOrders,
    initialState: {
      limit: 20,
    },
    getQueryInput: (filters: PurchaseOrderSearchFilters, offset: number, limit: number) => {
      const { category, field, order, start, end, query, ...otherFilters } = filters
      const validQuery = query && query.length >= MINIMUM_SEARCH_LENGTH ? query : undefined

      // Skip empty queries
      if (!validQuery && !Object.keys(otherFilters).length) {
        return {
          filter: {},
          offset,
          limit,
          sort: { field, order },
        }
      }

      return {
        filter: {
          query: validQuery,
          ...otherFilters,
          ...(start && {
            sentDateRange: {
              start,
              end,
            },
          }),
        },
        offset,
        limit,
        sort: { field, order },
      }
    },
    getQueryVariables: (queryInput) => ({ input: queryInput }),
    extractDataFromResponse: (data) => ({
      __typename: data.order.list.__typename,
      data: data.order.list.__typename === "OrderListSuccess" ? data.order.list.orders : [],
      error: data.order.list.__typename === "OrderListFailure" ? data.order.list.error : undefined,
    }),
  })
)
