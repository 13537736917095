import { Box, IconButton, Typography } from "@mui/material"
import {
  CheckCircleFilledOutlined,
  DeleteOutlined,
  UploadFileOutlined,
  VisibilityOutlined,
} from "@mui-symbols-material/w300"
import type { ReactElement } from "react"
import { useCallback, useEffect, useState } from "react"
import { GIFIcon } from "shared/assets/icons/GIFIcon"
import { ImageIcon } from "shared/assets/icons/ImageIcon"
import { JPEGIcon } from "shared/assets/icons/JPEGIcon"
import { JPGIcon } from "shared/assets/icons/JPGIcon"
import { PNGIcon } from "shared/assets/icons/PNGIcon"
import { twMerge } from "tailwind-merge"

import { FileUpload } from "./FileUpload"

import type { File as AerosFile, SignedUrl } from "@/graphql/codegen/graphql"
import { useDragAndDrop } from "@/hooks/useDragAndDrop"

interface SingleFileUploadProps {
  onChange: (file: File) => void
  fileUrl?: string | null
  file?: File | SignedUrl | null
  accept?: string
  maxSize?: number
  title?: string
  subtitle?: string
  dropTitle?: string
  readOnly?: boolean
}

const FILE_ICONS = {
  jpeg: <JPEGIcon className='size-12' />,
  jpg: <JPGIcon className='size-12' />,
  png: <PNGIcon className='size-12' />,
  gif: <GIFIcon className='size-12' />,
  default: <ImageIcon className='size-12' />,
} as const

const formatFileSize = (bytes: number): string => {
  if (bytes === 0) return "0 Bytes"
  const k = 1024
  const sizes = ["Bytes", "KB", "MB", "GB"]
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`
}

const EmptyUploadContent = ({ title, subtitle }: { title: string; subtitle: string }): ReactElement => (
  <>
    <UploadFileOutlined fontSize='large' className='text-primary' />
    <Typography variant='body2' color='textSecondary' className='text-black'>
      <Box component='span' className='text-primary underline'>
        {title}
      </Box>
    </Typography>
    <Typography variant='caption' color='textSecondary' className='mt-1'>
      {subtitle}
    </Typography>
  </>
)
// todo move to shared
export const FilePreview = ({
  file,
  readOnly = false,
}: {
  file: File | AerosFile | null
  readOnly?: boolean
}): ReactElement => {
  const extension = file
    ? ("name" in file ? file.name : file.label)?.split(".")?.pop()?.toLowerCase() || "default"
    : "default"
  const icon = FILE_ICONS[extension as keyof typeof FILE_ICONS] || FILE_ICONS.default
  const fileName = file
    ? "name" in file
      ? file.name
      : "originalName" in file
        ? file.originalName
        : file.label
    : undefined

  return (
    <Box className='relative flex size-full items-center justify-between'>
      <Box className='flex w-full items-center gap-4 p-4'>
        <Box className='flex flex-1 items-center gap-4'>
          <Box className='flex size-12 items-center justify-center rounded-lg bg-gray-100'>{icon}</Box>

          <Box className='flex flex-col'>
            <Typography variant='body2' className='font-medium text-gray-900'>
              {fileName || "Untitled"}
            </Typography>
            <Box className='flex items-center gap-2'>
              {file && "size" in file && file.size && (
                <>
                  <Typography variant='caption' className='text-gray-500'>
                    {formatFileSize(file.size)}
                  </Typography>
                  <Box className='size-1 rounded-full bg-gray-300' />
                </>
              )}
              <Typography variant='caption' className='text-gray-500'>
                Completed
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className='flex items-center gap-3'>
          {!readOnly ? (
            <>
              <IconButton size='small' className='text-gray-400'>
                <DeleteOutlined fontSize='medium' className='text-gray-900' />
              </IconButton>
              <Box className='flex size-6 items-center justify-center'>
                <CheckCircleFilledOutlined className='text-green-700' fontSize='large' />
              </Box>
            </>
          ) : (
            <IconButton size='small' className='text-gray-400'>
              <VisibilityOutlined fontSize='medium' className='text-gray-900' />
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  )
}

const DragOverlay = ({
  isOver,
  canDrop,
  dropTitle,
}: {
  isOver: boolean
  canDrop: boolean
  dropTitle: string
}): ReactElement => (
  <Box
    className={`pointer-events-none absolute inset-0 z-10 flex size-full items-center justify-center rounded-lg border-2 border-dashed transition-all duration-200 ${
      isOver && canDrop ? "border-primary-500 bg-white/90" : "border-transparent bg-transparent"
    }`}
  >
    <Box
      className={`flex flex-col items-center gap-4 transition-opacity duration-200 ${
        isOver && canDrop ? "opacity-100" : "opacity-0"
      }`}
    >
      <UploadFileOutlined className='text-6xl text-primary' />
      <Typography variant='body1' className='text-primary'>
        {dropTitle}
      </Typography>
    </Box>
  </Box>
)

export const SingleFileUpload = ({
  onChange,
  fileUrl,
  file = null,
  accept = ".jpg,.png,.gif,.jpeg",
  maxSize = 10 * 1024 * 1024,
  title = "Click to upload or drag and drop the file",
  subtitle = "JPG, PNG, GIF, JPEG (max. 10MB)",
  dropTitle = "Drop file here",
  readOnly = false,
}: SingleFileUploadProps): ReactElement => {
  const isSignedUrl = file && "url" in file
  const [previewUrl, setPreviewUrl] = useState<string | null>(fileUrl || null)
  const [currentFile, setCurrentFile] = useState<File | AerosFile | null>(
    isSignedUrl ? (file.file as AerosFile) : file || null
  )

  const handleFileChange = useCallback(
    (file: File) => {
      const tempUrl = URL.createObjectURL(file)
      setPreviewUrl(tempUrl)
      setCurrentFile(file)
      onChange(file)
    },
    [onChange]
  )

  const { dropRef, isOver, canDrop } = useDragAndDrop({
    onDrop: handleFileChange,
    accept,
    maxSize,
    enabled: true,
  })

  useEffect(() => {
    if (fileUrl) {
      setPreviewUrl(fileUrl)
    }
  }, [fileUrl])

  useEffect(() => {
    return () => {
      if (previewUrl && previewUrl !== fileUrl) {
        URL.revokeObjectURL(previewUrl)
      }
    }
  }, [previewUrl, fileUrl])

  return (
    <FileUpload
      onChange={handleFileChange}
      accept={accept}
      maxSize={maxSize}
      fileUrl={previewUrl}
      file={currentFile}
      enableDragAndDrop={true}
      readOnly={readOnly}
      templates={{
        content: () => (
          <Box
            ref={dropRef}
            className={twMerge(
              "flex w-full flex-col items-center justify-center gap-2",
              readOnly && "pointer-events-none",
              !previewUrl ? "h-[160px]" : ""
            )}
          >
            {!previewUrl ? (
              <EmptyUploadContent title={title} subtitle={subtitle} />
            ) : (
              <FilePreview file={currentFile} />
            )}
          </Box>
        ),
        overlay: () => <DragOverlay isOver={isOver} canDrop={canDrop} dropTitle={dropTitle} />,
        hover: () => null,
      }}
    />
  )
}
