import { create } from "zustand"

import { ContactSearchListDocument } from "@/graphql/codegen/graphql.ts"
import type { Contact, ContactSearchListQuery, ContactSortField, SortingOrder } from "@/graphql/codegen/graphql.ts"
import { type SearchFilters, createSearchStore } from "@/stores/createSearchStore"

export type ContactSearchFilters = SearchFilters & {
  category?: string
  field?: ContactSortField
  order?: SortingOrder
}

const deduplicateContacts = (newData: Contact[], existingData: Contact[]): Contact[] => {
  const uniqueIds = new Set(existingData.map((contact) => contact.contactId))
  return [...existingData, ...newData.filter((contact) => !uniqueIds.has(contact.contactId))]
}

export const useContactSearchStore = create(
  createSearchStore<Contact, ContactSearchFilters, ContactSearchListQuery>({
    documentNode: ContactSearchListDocument,
    deduplicateData: deduplicateContacts,
    getQueryInput: (filters, offset, limit) => {
      const { category, field, order, ...otherFilters } = filters
      return {
        filter: otherFilters,
        offset,
        limit,
        sort: { field, order },
      }
    },
    getQueryVariables: (queryInput) => ({ input: queryInput }),
    extractDataFromResponse: (data) => ({
      __typename: data?.contact?.list?.__typename ?? "ContactListFailure",
      data: data?.contact?.list?.__typename === "ContactListSuccess" ? (data.contact.list.contacts as Contact[]) : [],
      error: data?.contact?.list?.__typename === "ContactListFailure" ? data.contact.list.error : undefined,
    }),
  })
)
