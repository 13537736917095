import { z } from "zod"

export const AddContactGeneralInformationSchema = z.object({
  firstName: z.string().min(1, { message: "First name is mandatory" }),
  lastName: z.string().min(1, { message: "Last name is mandatory" }),
  companyDepartment: z.string().optional(),
  title: z.string().optional(),
  officeLocation: z.string().optional(),
  physicalLocationId: z.string().optional(),
})

const PhoneNumberSchema = z.object({
  number: z.string().optional(),
  type: z.enum(["Text", "Work", "Mobile", "Home", "Fax", "Other"]).optional(),
  isPreferred: z.boolean().optional(),
  contactMethodId: z.string().optional(),
})

export const AddContactMethodsSchema = z.object({
  phoneNumber: z.array(PhoneNumberSchema).optional(),
  email: z.string().email().optional().or(z.literal("")),
})

export const AddContactLocationRoleSchema = z.object({
  locationRoles: z
    .array(
      z.object({
        locationId: z.string().optional(),
        role: z.string().optional(),
        contactLocationId: z.string().optional(),
      })
    )
    .optional(),
})

export const UIAddContactLocationRoleSchema = z.object({
  locationRoles: z
    .array(
      z.object({
        locationId: z.string(),
        roles: z.array(z.string()),
      })
    )
    .optional(),
})

export const ContactModalSchema =
  AddContactGeneralInformationSchema.merge(AddContactMethodsSchema).merge(UIAddContactLocationRoleSchema)

export type ContactModalSchemaType = z.infer<typeof ContactModalSchema>
