import { Autocomplete, Box, Checkbox, Chip, InputAdornment, MenuItem, TextField, Typography } from "@mui/material"
import { CloseFilledOutlined, MoreHorizOutlined, SearchOutlined } from "@mui-symbols-material/w300"
import type { FC } from "react"
import { useCallback, useEffect, useRef, useState } from "react"
import type { Control } from "react-hook-form"
import { useController } from "react-hook-form"
import { twMerge } from "tailwind-merge"

import { useGetInstructions } from "./hooks/useGetInstructions"

import type { ProductInstruction } from "@/graphql/codegen/graphql"
import { useInfiniteScroll } from "@/hooks/useInfniniteScroll"

interface InstructionListProps {
  label: string
  name: string
  control: Control
}

const InstructionList: FC<InstructionListProps> = ({ label, name, control }) => {
  const [inputValue, setInputValue] = useState("")
  const [visibleChips, setVisibleChips] = useState<ProductInstruction[]>([])
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const measureRef = useRef<HTMLDivElement>(null)

  const { instructionsList, instructionsListFetching, loadMore } = useGetInstructions()
  const { containerRef: scrollContainerRef } = useInfiniteScroll(loadMore, {
    isLoading: instructionsListFetching,
    hasMore: instructionsList.length % 20 === 0,
    threshold: 200,
  })

  const {
    field,
    fieldState: { error },
  } = useController({ name, control })
  const selectedInstructions = getSelectedInstructions(instructionsList, field.value)

  const updateVisibleChips = useCallback(() => {
    if (measureRef.current && containerRef.current) {
      const containerWidth = containerRef.current.clientWidth - 76
      const visibleChipsArray: ProductInstruction[] = []
      let totalWidth = 0

      Array.from(measureRef.current.children).forEach((chip, index) => {
        const chipWidth = (chip as HTMLElement).offsetWidth
        if (totalWidth + chipWidth <= containerWidth) {
          visibleChipsArray.push(selectedInstructions[index])
          totalWidth += chipWidth
        }
      })

      setVisibleChips(visibleChipsArray)
      setIsOverflowing(visibleChipsArray.length < selectedInstructions.length)
    }
  }, [selectedInstructions])

  useEffect(() => {
    updateVisibleChips()
  }, [selectedInstructions, updateVisibleChips])

  return (
    <Box className='mb-4' position='relative'>
      <LabelWithRequiredIndicator label={label} />
      <Autocomplete
        multiple
        disableClearable
        freeSolo
        options={getAutocompleteOptions(inputValue, selectedInstructions, instructionsList)}
        getOptionLabel={getOptionLabel}
        value={selectedInstructions}
        onChange={(_, newValue) => handleInstructionChange(newValue, field)}
        onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
        classes={{ input: "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none min-w-0" }}
        renderInput={(params) => renderInput(params, containerRef)}
        renderTags={() => renderTags(visibleChips, isOverflowing, field)}
        slotProps={{ listbox: { ref: scrollContainerRef } }}
        renderOption={(props, option) => {
          if (typeof option === "string") return null
          return renderOption(props, option, selectedInstructions)
        }}
      />
      {error && <Typography className='text-sm text-red-600'>{error.message}</Typography>}
      <Box className='invisible absolute' ref={measureRef}>
        {selectedInstructions.map(({ name, productInstructionId }) => (
          <Chip key={productInstructionId} label={name} />
        ))}
      </Box>
    </Box>
  )
}

// Helper Functions
const getSelectedInstructions = (instructionsList: ProductInstruction[], selectedIds: string[]) => {
  return instructionsList.filter((instruction) => selectedIds.includes(instruction.productInstructionId))
}

const getAutocompleteOptions = (
  inputValue: string,
  selectedInstructions: ProductInstruction[],
  instructionsList: ProductInstruction[]
) => {
  return inputValue.length >= 3 || selectedInstructions.length > 0 ? instructionsList : []
}

const getOptionLabel = (option: string | ProductInstruction) => {
  return typeof option === "string" ? option : option.name || ""
}

const handleInstructionChange = (newValue: (string | ProductInstruction)[], field: any) => {
  const validInstructions = newValue.filter((v): v is ProductInstruction => typeof v !== "string")

  // Prevent duplicate instructions while allowing editing of the same instruction
  const uniqueInstructions = validInstructions.filter(
    (instruction, index, self) =>
      self.findIndex((i) => i.productInstructionId === instruction.productInstructionId) === index
  )

  field.onChange(uniqueInstructions.map((v) => v.productInstructionId))
}

const renderInput = (params: any, containerRef: React.RefObject<HTMLDivElement>) => (
  <TextField
    {...params}
    fullWidth
    ref={containerRef}
    variant='outlined'
    InputProps={{
      ...params.InputProps,
      endAdornment: (
        <InputAdornment position='end'>
          <SearchOutlined className='!h-5 !w-5' />
        </InputAdornment>
      ),
    }}
    sx={{ "& .MuiAutocomplete-inputRoot": { height: "42px", alignContent: "center" } }}
  />
)

const renderTags = (visibleChips: ProductInstruction[], isOverflowing: boolean, field: any) => (
  <Box className='flex overflow-hidden align-middle'>
    <Box className='flex flex-nowrap gap-1'>
      {visibleChips.map(({ name, productInstructionId }) => (
        <Chip
          key={productInstructionId}
          label={name}
          variant='filled'
          size='small'
          onDelete={() => handleDeleteChip(productInstructionId, field)}
          onClick={(e) => e.stopPropagation()}
          deleteIcon={<CloseFilledOutlined className='!h-4 !w-4' />}
          className={twMerge("!cursor-default rounded-sm", "!bg-gray-100 hover:!bg-gray-200")}
        />
      ))}
    </Box>
    {isOverflowing && (
      <Box className='mx-1 flex size-6 items-center justify-center rounded bg-gray-100'>
        <MoreHorizOutlined className='!h-4 !w-4 text-gray-500' />
      </Box>
    )}
  </Box>
)

const handleDeleteChip = (productInstructionId: string, field: any) => {
  // Simply remove the clicked instruction
  const newValue = field.value.filter((v: string) => v !== productInstructionId)
  field.onChange(newValue)
}

const renderOption = (props: any, option: ProductInstruction, selectedInstructions: ProductInstruction[]) => (
  <MenuItem
    {...props}
    key={option.productInstructionId}
    component='li'
    className='flex content-center whitespace-normal break-words'
  >
    <Checkbox
      checked={selectedInstructions.some(
        ({ productInstructionId }) => productInstructionId === option.productInstructionId
      )}
    />
    {option.printableLabel && (
      <img
        src={`data:image;base64, ${option.printableLabel}`}
        alt={option.name}
        width={48}
        height={48}
        className='mr-4'
      />
    )}
    {option.name}
  </MenuItem>
)

const LabelWithRequiredIndicator = ({ label }: { label: string }) => (
  <label className='mb-2 block text-sm font-normal text-gray-700'>
    {label}
    <span className='text-red-600'>*</span>
  </label>
)

export default InstructionList
