import { Box, IconButton, Paper, Tab, Tabs, Typography } from "@mui/material"
import { ChevronLeftOutlined, KeyboardArrowRightOutlined } from "@mui-symbols-material/w300"
import type { AnyRoute } from "@tanstack/react-router"
import { Link, Outlet, createFileRoute, useLocation, useParams, useRouter } from "@tanstack/react-router"
import type { ReactElement } from "react"
import { useEffect, useMemo, useState } from "react"
import { FormProvider } from "react-hook-form"

import { Button } from "@/components/common/Button"
import { useEditProduct } from "@/screens/Companies/products/hooks/useEditProduct.tsx"
import { usePurchaseOrderDialog } from "@/screens/Procurement/hooks/usePurchaseOrderDialog"
import EditProductModal from "@/screens/Products/components/EditProductModal"
import { ProductStartPurchaseOrder } from "@/screens/Products/components/Product/ProductStartPurchaseOrder"
import ProductHeader from "@/screens/Products/components/ProductHeader"
import {
  ProductStoreProvider,
  selectProduct,
  useCategory,
  useClassification,
  useProductActions,
  useProductManager,
  useProductStore,
} from "@/stores/useProductStore"

const createTabList = (productId: string) => [
  { label: "Information", path: `/product/${productId}/edit/information` },
  { label: "Regulatory", path: `/product/${productId}/edit/regulatory` },
  { label: "Instructions", path: `/product/${productId}/edit/instructions` },
  { label: "Pricing", path: `/product/${productId}/edit/pricing` },
]

const ProductEditContent = () => {
  const router = useRouter()
  const location = useLocation()
  const currentPath = location.pathname
  const [editProductModal, setEditProductModal] = useState(false)
  const { productId: queryProductId } = useParams({
    from: "/product/$productId/edit",
  })

  const { getProduct } = useProductActions()
  const product = useProductStore(selectProduct)
  const productManagerName = useProductManager()
  const classificationName = useClassification()
  const categoryName = useCategory()

  useEffect(() => {
    if (queryProductId) {
      getProduct(Number(queryProductId))
    }
  }, [queryProductId, getProduct])

  const tabs = useMemo(() => createTabList(queryProductId), [queryProductId])

  const { methods, handleSubmit, resetDone } = useEditProduct({ productId: Number(queryProductId) })
  const { open, fetching, handleOpen, handleClose, handleSubmit: handlePurchaseOrderSubmit } = usePurchaseOrderDialog()

  const handleFormSubmit = async (data): Promise<void> => {
    await handleSubmit(data)
    await getProduct(Number(queryProductId))
  }

  return (
    <>
      {product && (
        <ProductStartPurchaseOrder
          open={open}
          fetching={fetching}
          onClose={handleClose}
          onSubmit={handlePurchaseOrderSubmit}
          product={product}
        />
      )}
      <Box className='size-full px-6 pb-6'>
        <div className='flex h-full flex-col gap-4'>
          <Paper elevation={0} className='flex min-h-0 grow flex-col space-y-7 bg-white p-6'>
            <Box className='flex justify-between'>
              <Box className='flex items-center gap-4'>
                <IconButton
                  aria-label='back to contacts'
                  className='text-primary-500 rounded-none bg-gray-200'
                  onClick={() => router.history.back()}
                >
                  <ChevronLeftOutlined />
                </IconButton>
                <Typography variant='h5' color='primary' fontWeight='normal'>
                  Product Detail
                </Typography>
              </Box>
              <Button
                appearance='outlined'
                color='primary'
                endIcon={<KeyboardArrowRightOutlined />}
                onClick={handleOpen}
              >
                Start PO
              </Button>
            </Box>
            <Box className='space-y-2.5'>
              <ProductHeader
                id={queryProductId}
                isActive={product?.isActive || false}
                productDescription={product?.companyProductDescription ?? ""}
                vendorDescription={product?.vendorDescription ?? ""}
                vendorSku={product?.vendorSku ?? ""}
                productManager={productManagerName}
                classification={classificationName}
                category={categoryName}
                companyName={product?.company?.name ?? ""}
                onEditClick={() => setEditProductModal(true)}
              />
            </Box>
            <Box className='flex min-h-0 w-full grow flex-col'>
              <Tabs value={currentPath} variant='scrollable' scrollButtons='auto'>
                {tabs.map((tab, index) => (
                  <Tab key={index} label={tab.label} value={tab.path} component={Link} to={tab.path} />
                ))}
              </Tabs>
              <Box className='min-h-0 grow overflow-auto py-4'>
                <Outlet />
              </Box>
            </Box>
          </Paper>
        </div>
        <FormProvider {...methods}>
          <EditProductModal
            open={editProductModal}
            onClose={() => {
              setEditProductModal(false)
            }}
            onSubmit={handleFormSubmit}
            resetDone={resetDone}
          />
        </FormProvider>
      </Box>
    </>
  )
}

const ProductEditLayout = (): ReactElement => {
  return (
    <ProductStoreProvider>
      <ProductEditContent />
    </ProductStoreProvider>
  )
}

// Export the component for testing
export const ProductComponent = ProductEditLayout

export const Route: AnyRoute = createFileRoute("/product/$productId/edit/_layout")({
  component: ProductComponent,
})
