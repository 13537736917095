import dayjs from "dayjs"

import { FreightTerms, Incoterms } from "@/graphql/codegen/graphql.ts"
import type { PricingPackageTier } from "@/screens/Companies/products/types/pricingPackages"

export const pricingTiers = Object.values(FreightTerms)
export const incoterms = Object.values(Incoterms)

export const incoTermsLabels = {
  CIP: "CIP: Carriage and Insurance Paid To",
  CPT: "CPT: Carriage Paid To",
  DAP: "DAP: Delivered At Place",
  DPU: "DPU: Delivered At Place Unloaded",
  DDP: "DDP: Delivered Duty Paid",
  EXW: "EXW: Ex Works",
  FCA: "FAC: Free Carrier",
  FAS: "FAS: Free Alongside Ship",
  FOB: "FOB: Free On Board",
  CFR: "CFR: Cost and Freight",
  CIF: "CIF: Cost, Insurance and Freight",
}

// This function formats the enum values to be user friendly labels
export const formatEnumValue = (enumValue: string): string => {
  if (!enumValue) return enumValue
  return enumValue
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}

export const formattedPricingTiers = pricingTiers.map((key) => ({
  value: key,
  label: formatEnumValue(key),
}))

// Utility function to format incoterms with labels
function getFormattedIncoterms(includeSeaTerms: boolean) {
  return incoterms
    .filter((term) =>
      includeSeaTerms
        ? ["CFR", "CIF", "FAS", "FOB"].some((seaTerm) => term.includes(seaTerm))
        : !["CFR", "CIF", "FAS", "FOB"].some((seaTerm) => term.includes(seaTerm))
    )
    .map((key) => ({ value: key, label: incoTermsLabels[key] }))
}

export const formatAnyModeTransportIncoterms = getFormattedIncoterms(false)
export const formatSeaAndInlandWaterwayIncoterms = getFormattedIncoterms(true)

export const formattedTierData = (data: any): PricingPackageTier => {
  return {
    pricingPackage: {
      productId: data.productId,
      companyId: data.companyId,
      sourceCompanyLocationAssociationId: data.sourceCompanyLocationAssociationId,
      isBuySide: false,
      incoterms: data.incoterms,
      incotermsLocationId: data.incotermsLocationId,
      freightTerms: data.freightTerms,
      effectiveDate: dayjs(data.effectiveDate).toISOString(),
      expirationDate: data.expirationDate ? dayjs(data.expirationDate).toISOString() : null,
      unitOfMeasurementId: data.pricingTiersToCreate[0]?.unitOfMeasurement?.id,
    },
    pricingTiers: data.pricingTiersToCreate
      .map(({ numberOfUnits, pricePerUnit }: { numberOfUnits: string; pricePerUnit: string }) =>
        numberOfUnits && pricePerUnit
          ? {
              numberOfUnits: parseInt(numberOfUnits),
              pricePerUnit: parseFloat(pricePerUnit),
            }
          : undefined
      )
      .filter(Boolean),
  }
}
