import { Box, Typography } from "@mui/material"
import type { Row } from "@tanstack/react-table"
import type { FC } from "react"

import type { GroupedInstruction } from "../hooks/useInstructionGroups"

type InstructionGroupExpandedProps = Row<GroupedInstruction>

export const InstructionGroupExpanded: FC<InstructionGroupExpandedProps> = (row) => {
  // Filter out duplicate instructions based on productInstructionId
  const uniqueInstructions = row.original.relatedInstructions.filter(
    (instruction, index, self) =>
      index === self.findIndex((i) => i.productInstructionId === instruction.productInstructionId)
  )

  return (
    <Box className='space-y-4'>
      {uniqueInstructions.map((instruction) => (
        <Box
          key={`${row.original.groupKey}-${instruction.productInstructionId}`}
          className='flex items-center space-x-2 border-b border-gray-200 p-4 pr-6 last:border-0'
        >
          {instruction.productInstruction?.printableLabel && (
            <Box
              component='img'
              src={`data:image;base64, ${instruction.productInstruction.printableLabel}`}
              alt={`Image for ${instruction.productInstruction.name}`}
              width={48}
              height={48}
              sx={{ marginRight: 2 }}
            />
          )}
          <Box>
            <Typography variant='subtitle2'>{instruction.productInstruction?.name}</Typography>
            <Box className='whitespace-pre-wrap text-sm text-gray-600'>
              {instruction.productInstruction?.description}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  )
}
