import dayjs from "dayjs"
import { useCallback, useEffect, useState } from "react"
import type { CombinedError } from "urql"
import { useQuery } from "urql"

import type {
  ListMultipleProductsPricingPackageQueryableFields,
  ListMultipleProductsPricingPackageSuccess,
  PricingPackage,
  Query,
} from "@/graphql/codegen/graphql"
import { ListMultipleProductsPricingPackageDocument } from "@/graphql/codegen/graphql"
import type { ClassifiedPricingPackages } from "@/screens/Procurement/PurchaseOrder/types.ts"

export const useGetMultipleProductsPricing = ({
  filterSpecs,
}: {
  filterSpecs: ListMultipleProductsPricingPackageQueryableFields
  expanded?: boolean
}): {
  pricingListError: CombinedError | undefined
  pricingList: ClassifiedPricingPackages
  pricingListFetching: boolean
  reexecuteQueryWithReset: () => void
} => {
  const [pricingData, setPricingData] = useState<{
    currentPricing: PricingPackage[] | undefined
    futurePricing: PricingPackage[]
  }>({
    currentPricing: undefined,
    futurePricing: [],
  })
  const { productIds, companyId, sourceCompanyLocationAssociationId } = filterSpecs ?? {}

  const shouldPauseQueries = !productIds?.length || !companyId || !sourceCompanyLocationAssociationId

  const [
    { data: currentPricingData, fetching: fetchingCurrentPricing, error: errorCurrentPricing },
    reexecuteCurrentPricingQuery,
  ] = useQuery<Pick<Query, "pricingPackage">>({
    query: ListMultipleProductsPricingPackageDocument,
    variables: {
      input: {
        filter: {
          ...filterSpecs,
          effectiveNow: true,
        },
      },
    },
    pause: shouldPauseQueries,
  })

  const [
    { data: futurePricingData, fetching: fetchingFuturePricing, error: errorFuturePricing },
    reexecuteFuturePricingQuery,
  ] = useQuery<Pick<Query, "pricingPackage">>({
    query: ListMultipleProductsPricingPackageDocument,
    variables: {
      input: {
        filter: {
          ...filterSpecs,
          effectiveNow: null,
          effectiveDateRange: {
            startDate: dayjs().add(1, "day").format("MM/DD/YYYY"),
          },
        },
      },
    },
    pause: shouldPauseQueries,
  })

  const reexecuteQueryWithReset = useCallback(() => {
    reexecuteCurrentPricingQuery({ requestPolicy: "network-only" })
    reexecuteFuturePricingQuery({ requestPolicy: "network-only" })
  }, [reexecuteCurrentPricingQuery, reexecuteFuturePricingQuery])

  useEffect(() => {
    if (currentPricingData && futurePricingData) {
      const currentPackages =
        (
          currentPricingData.pricingPackage?.listMultipleProducts as ListMultipleProductsPricingPackageSuccess
        )?.productsAndPricingPackages
          ?.flatMap((item) => item.pricingPackages)
          ?.filter(Boolean) || []

      const futurePackages =
        (
          futurePricingData.pricingPackage?.listMultipleProducts as ListMultipleProductsPricingPackageSuccess
        )?.productsAndPricingPackages
          ?.flatMap((item) => item.pricingPackages)
          ?.filter(Boolean) || []

      setPricingData({
        currentPricing: currentPackages,
        futurePricing: futurePackages,
      })
    }
  }, [currentPricingData, futurePricingData])

  return {
    pricingList: pricingData,
    pricingListFetching: fetchingCurrentPricing || fetchingFuturePricing,
    pricingListError: errorCurrentPricing || errorFuturePricing,
    reexecuteQueryWithReset,
  }
}
