import type { Client, CombinedError, DocumentInput, OperationContext } from "urql"
import type { StateCreator } from "zustand"
import { shallow } from "zustand/shallow"

import { MINIMUM_SEARCH_LENGTH } from "@/constants/search"

/**
 * Constants for search store configuration
 */
const DEFAULT_PAGE_SIZE = 20
const DEFAULT_OFFSET = 0

/**
 * Base types for search functionality
 */
export type SearchFilters = {
  query?: string
  [key: string]: unknown
}

/**
 * Specific error types for better error handling
 */
export type SearchError =
  | { type: "network"; error: Error }
  | { type: "validation"; message: string }
  | { type: "graphql"; error: CombinedError }

export type BaseSearchState<TData, TFilters extends SearchFilters> = {
  data: TData[]
  fetching: boolean
  error: CombinedError | Error | undefined
  offset: number
  limit: number
  hasMore: boolean
  lastExecutedFilters: TFilters | undefined
  pendingRequest?: Promise<void>
}

export type SearchActions<TData, TFilters extends SearchFilters> = {
  setFetching: (fetching: boolean) => void
  setError: (error: CombinedError | Error | undefined) => void
  resetSearch: () => void
  clearStore: () => void
  setData: (data: TData[]) => void
  appendData: (data: TData[]) => void
  executeSearch: (client: Client, filters: TFilters, skipFilterCheck?: boolean) => Promise<void>
  loadMore: (client: Client, filters: TFilters) => Promise<void>
}

export type SearchStore<TData, TFilters extends SearchFilters> = BaseSearchState<TData, TFilters> &
  SearchActions<TData, TFilters>

/**
 * Type for GraphQL response handling
 */
type GraphQLResponse<T> = {
  __typename: string
  error?: { message: string }
} & T

/**
 * Configuration type for search store creation
 */
export type SearchConfig<
  TData,
  TFilters extends SearchFilters,
  TQuery,
  TInput = unknown,
  TVariables extends Record<string, unknown> = { input: TInput },
> = {
  documentNode: DocumentInput
  processData?: (data: TData[]) => TData[]
  deduplicateData?: (newData: TData[], existingData: TData[]) => TData[]
  getQueryInput: (filters: TFilters, offset: number, limit: number) => TInput
  getQueryVariables: (queryInput: TInput) => TVariables
  extractDataFromResponse: (response: TQuery) => GraphQLResponse<{ data: TData[] }>
  requestPolicy?: OperationContext["requestPolicy"]
  initialState?: Partial<BaseSearchState<TData, TFilters>>
  hasMoreData?: (response: GraphQLResponse<{ data: TData[] }>, state: BaseSearchState<TData, TFilters>) => boolean
}

// Default initial state
const DEFAULT_INITIAL_STATE = {
  data: [],
  fetching: true,
  error: undefined,
  offset: DEFAULT_OFFSET,
  limit: DEFAULT_PAGE_SIZE,
  hasMore: true,
  lastExecutedFilters: undefined,
  pendingRequest: undefined,
}

/**
 * Helper functions for search functionality
 */
const handleGraphQLError = (response: GraphQLResponse<any>) => {
  if (response.__typename.endsWith("Failure") && response.error) {
    throw new Error(response.error.message)
  }
}

const validateQuery = (query: string | undefined): string | undefined => {
  if (!query) return undefined
  return typeof query === "string" && query.length >= MINIMUM_SEARCH_LENGTH ? query : undefined
}

const areFiltersEqual = (a: SearchFilters | undefined, b: SearchFilters | undefined): boolean => {
  if (!a || !b) return a === b
  const { offset: _, ...restA } = a
  const { offset: __, ...restB } = b
  return shallow(restA, restB)
}

const buildCurrentFilters = <TFilters extends SearchFilters>(filters: TFilters): TFilters => {
  const { query, offset, ...restFilters } = filters || {}
  const validQuery = validateQuery(query)
  return {
    ...restFilters,
    ...(validQuery ? { query: validQuery } : {}),
    ...(offset !== undefined ? { offset } : {}),
  } as TFilters
}

/**
 * Handles search errors and updates state accordingly
 */
const handleSearchError = (error: Error | CombinedError) => ({
  error,
  fetching: false,
  data: [],
  hasMore: false,
  pendingRequest: undefined,
})

/**
 * Creates a search store with pagination and filtering support
 * @template TData - The type of data items
 * @template TFilters - The type of search filters
 * @param config - Search store configuration
 */
export const createSearchStore = <
  TData,
  TFilters extends SearchFilters,
  TQuery,
  TInput = unknown,
  TVariables extends Record<string, unknown> = { input: TInput },
>(
  config: SearchConfig<TData, TFilters, TQuery, TInput, TVariables>
): StateCreator<SearchStore<TData, TFilters>, [], [], SearchStore<TData, TFilters>> => {
  const {
    documentNode,
    processData = (data) => data,
    deduplicateData = (newData, existingData) => [...existingData, ...newData],
    getQueryInput,
    getQueryVariables,
    extractDataFromResponse,
    requestPolicy,
    initialState = {},
    hasMoreData = (response, state) => response.data.length === state.limit,
  } = config

  const INITIAL_STATE = {
    ...DEFAULT_INITIAL_STATE,
    ...initialState,
  }

  const executeQuery = async (client: Client, queryInput: TInput) => {
    const variables = getQueryVariables(queryInput)
    const result = await client.query(documentNode, variables, { requestPolicy }).toPromise()

    if (result.error) throw result.error

    const response = extractDataFromResponse(result.data as TQuery)
    handleGraphQLError(response)

    return response
  }

  return (set, get) => ({
    ...INITIAL_STATE,

    setFetching: (fetching: boolean) => set({ fetching }),
    setError: (error: CombinedError | Error | undefined) => set({ error }),
    resetSearch: () => set(INITIAL_STATE),
    clearStore: () => set(INITIAL_STATE),

    setData: (data: TData[]) =>
      set({
        data: deduplicateData(processData(data), []),
        error: undefined,
      }),

    appendData: (data: TData[]) =>
      set((state) => ({
        data: deduplicateData(processData(data), state.data),
        error: undefined,
      })),

    executeSearch: async (client: Client, filters: TFilters, skipFilterCheck = false) => {
      const state = get()
      const currentFilters = buildCurrentFilters(filters)

      // Skip if filters haven't changed and we're not skipping the check
      if (!skipFilterCheck && areFiltersEqual(currentFilters, state.lastExecutedFilters)) {
        set({ fetching: false })
        return
      }

      // Set initial search state
      set({
        fetching: true,
        error: undefined,
        offset: DEFAULT_OFFSET,
      })

      // Handle invalid query case
      if (filters?.query && !validateQuery(filters.query)) {
        if (state.pendingRequest) {
          set({ pendingRequest: undefined })
        }

        let searchPromise: Promise<void>
        const executeSearchOperation = async () => {
          try {
            const queryInput = getQueryInput({} as TFilters, DEFAULT_OFFSET, state.limit)
            const response = await executeQuery(client, queryInput)

            const currentState = get()
            if (currentState.pendingRequest === searchPromise) {
              const processedData = deduplicateData(processData(response.data), [])
              set({
                data: processedData,
                hasMore: hasMoreData(response, state),
                error: undefined,
                fetching: false,
                pendingRequest: undefined,
                lastExecutedFilters: skipFilterCheck ? undefined : filters,
              })
            }
          } catch (error) {
            const currentState = get()
            if (currentState.pendingRequest === searchPromise) {
              set(handleSearchError(error as Error | CombinedError))
            }
          }
        }

        searchPromise = executeSearchOperation()
        set({
          pendingRequest: searchPromise,
          error: undefined,
          offset: DEFAULT_OFFSET,
        })

        return searchPromise
      }

      // Cancel any pending operations
      if (state.pendingRequest) {
        set({ pendingRequest: undefined })
      }

      let searchPromise: Promise<void>
      const executeSearchOperation = async () => {
        try {
          const queryInput = getQueryInput(currentFilters, DEFAULT_OFFSET, state.limit)
          const response = await executeQuery(client, queryInput)

          const currentState = get()
          if (currentState.pendingRequest === searchPromise) {
            const processedData = deduplicateData(processData(response.data), [])
            set({
              data: processedData,
              offset: DEFAULT_OFFSET,
              hasMore: hasMoreData(response, state),
              lastExecutedFilters: skipFilterCheck ? undefined : currentFilters,
              fetching: false,
              error: undefined,
              pendingRequest: undefined,
            })
          }
        } catch (error) {
          const currentState = get()
          if (currentState.pendingRequest === searchPromise) {
            set(handleSearchError(error as Error | CombinedError))
          }
        }
      }

      searchPromise = executeSearchOperation()
      set({
        pendingRequest: searchPromise,
        error: undefined,
        offset: DEFAULT_OFFSET,
        fetching: true,
      })

      return searchPromise
    },

    loadMore: async (client: Client, filters: TFilters) => {
      const state = get()

      if (state.fetching || !state.hasMore || state.pendingRequest) return

      set({ fetching: true, error: undefined })

      try {
        const newOffset = state.offset + state.limit
        const queryInput = getQueryInput(filters, newOffset, state.limit)
        const response = await executeQuery(client, queryInput)

        const currentState = get()

        if (currentState.offset === state.offset) {
          const processedData = deduplicateData(processData(response.data), currentState.data)
          set({
            data: processedData,
            offset: newOffset,
            hasMore: hasMoreData(response, state),
            fetching: false,
            error: undefined,
          })
        } else {
          set({ fetching: false })
        }
      } catch (error) {
        set({
          error: error as CombinedError | Error,
          fetching: false,
        })
      }
    },
  })
}
