import { useCallback, useState } from "react"

import type { FieldGroupEnum, ReferenceTableName } from "@/graphql/codegen/graphql"
import { useFileUpload } from "@/hooks/useFileUpload"

interface UseImageUploadProps {
  referenceTableName: ReferenceTableName
  type: FieldGroupEnum
  getReferenceId: () => string
}

type UploadState = Record<FieldGroupEnum, boolean>

interface ImageUploadHook {
  uploading: UploadState
  handleGetSignedUrl: (file: File, type: FieldGroupEnum, label?: string | undefined) => Promise<string | null>
  handleUploadToS3: (signedUrl: string, type: FieldGroupEnum, file: File) => Promise<boolean>
}

const ALLOWED_IMAGE_TYPES = ["image/jpeg", "image/png", "image/gif"]

export const useImageUpload = ({ referenceTableName, getReferenceId }: UseImageUploadProps): ImageUploadHook => {
  const { getSignedUrl, uploadToS3 } = useFileUpload()
  const [uploading, setUploading] = useState<UploadState>({} as UploadState)

  const handleGetSignedUrl = useCallback(
    async (file: File, type: FieldGroupEnum, label?: string | undefined) => {
      if (!ALLOWED_IMAGE_TYPES.includes(file.type)) {
        console.error(`Invalid file type: ${file.type}. Allowed types: ${ALLOWED_IMAGE_TYPES.join(", ")}`)
        return null
      }

      try {
        const referenceId = getReferenceId()
        if (!referenceId) {
          console.error("No reference ID available")
          return null
        }

        const signedUrl = await getSignedUrl({
          file,
          type: "image",
          metadata: {
            fieldGroup: type,
            referenceId,
            referenceTableName,
          },
          label,
        })

        if (!signedUrl) {
          console.error("Failed to get signed URL for image upload")
          return null
        }

        return signedUrl
      } catch (error) {
        console.error("Failed to get signed URL for image:", error)
        return null
      }
    },
    [getSignedUrl, getReferenceId, referenceTableName]
  )

  const handleUploadToS3 = useCallback(
    async (signedUrl: string, type: FieldGroupEnum, file: File) => {
      if (!signedUrl) {
        console.error("No signed URL provided for upload")
        return false
      }

      setUploading((prev) => ({ ...prev, [type]: true }))

      try {
        const success = await uploadToS3(signedUrl, file)
        if (!success) {
          console.error("Failed to upload image to S3")
          return false
        }
        return true
      } catch (error) {
        console.error("Failed to upload image to S3:", error)
        return false
      } finally {
        setUploading((prev) => ({ ...prev, [type]: false }))
      }
    },
    [uploadToS3]
  )

  return {
    uploading,
    handleGetSignedUrl,
    handleUploadToS3,
  }
}
