import { useCallback, useEffect, useRef } from "react"

interface ScrollLoadMoreProps {
  fetching: boolean
  loadMore: () => void
  scrollContainerRef: React.RefObject<HTMLElement>
  threshold?: number
  throttleMs?: number
}

export const useScrollLoadMore = ({
  fetching,
  loadMore,
  scrollContainerRef,
  threshold = 200,
  throttleMs = 150,
}: ScrollLoadMoreProps): (() => void) => {
  const lastScrollTop = useRef(0)
  const scrollFrameId = useRef<number>()
  const isScrollingDown = useRef(false)
  const lastLoadTime = useRef(0)

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (scrollFrameId.current) {
        cancelAnimationFrame(scrollFrameId.current)
      }
    }
  }, [])

  const checkScrollPosition = useCallback(() => {
    const container = scrollContainerRef.current
    if (!container || fetching) {
      return
    }

    const { scrollTop, clientHeight, scrollHeight } = container
    isScrollingDown.current = scrollTop > lastScrollTop.current
    lastScrollTop.current = scrollTop

    // Only load more when scrolling down and near bottom
    if (!isScrollingDown.current) {
      return
    }

    const now = Date.now()
    const timeSinceLastLoad = now - lastLoadTime.current
    if (timeSinceLastLoad < throttleMs) {
      return
    }

    const distanceFromBottom = scrollHeight - scrollTop - clientHeight

    if (distanceFromBottom <= threshold) {
      lastLoadTime.current = now
      loadMore()
    }
  }, [fetching, threshold, throttleMs, loadMore, scrollContainerRef])

  const handleScroll = useCallback(() => {
    // Cancel any pending frame
    if (scrollFrameId.current) {
      cancelAnimationFrame(scrollFrameId.current)
    }

    // Schedule new check on next animation frame
    scrollFrameId.current = requestAnimationFrame(() => {
      checkScrollPosition()
      scrollFrameId.current = undefined
    })
  }, [checkScrollPosition])

  return handleScroll
}
