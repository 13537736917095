import { Box, LinearProgress, Typography } from "@mui/material"
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table"
import type { ReactElement, ReactNode } from "react"
import React, { Fragment, useCallback, useMemo } from "react"
import { twMerge } from "tailwind-merge"

import type { AerosTableProps } from "../types/table"

import { AerosDataNotFoundRow } from "./AerosDataNotFoundRow"
import { AerosSortableTableHeadCell } from "./AerosSortableTableHeadCell"
import { AerosTable } from "./AerosTable"
import { AerosTableBody } from "./AerosTableBody"
import { AerosTableCell } from "./AerosTableCell"
import { AerosTableContainer } from "./AerosTableContainer"
import { AerosTableHead } from "./AerosTableHead"
import { AerosTableHeadRow } from "./AerosTableHeadRow"
import { AerosTableLoadingRow } from "./AerosTableLoadingRow"
import { AerosTableRow } from "./AerosTableRow"

export const AerosBaseTable = <TData,>({
  error,
  features,
  slotProps,
  noDataMessage = "",
}: AerosTableProps<TData>): ReactElement => {
  const {
    data = [],
    columns = [],
    state,
    getCoreRowModel: customCoreRowModel,
    ...tanstackOptions
  } = features?.tanstackOptions || {}

  // Memoize table instance to prevent unnecessary re-renders
  const table = useReactTable<TData>({
    data,
    columns,
    state,
    getCoreRowModel: customCoreRowModel ?? getCoreRowModel<TData>(),
    ...tanstackOptions,
  })

  // Memoize header cells to prevent unnecessary re-renders
  const headerCells = useMemo(() => {
    return table
      .getFlatHeaders()
      .map((header) => (
        <AerosSortableTableHeadCell
          key={header.id}
          header={header}
          align={(header.id === "select" ? "center" : slotProps?.headerCell?.align) as "left" | "center" | "right"}
          className={slotProps?.headerCell?.className}
        />
      ))
  }, [table.getFlatHeaders(), slotProps?.headerCell, state?.sorting])

  const renderRowDetail = useCallback(
    (content: ReactNode) => {
      if (!content) return null
      return (
        <AerosTableRow
          className={twMerge("!border-t-0 border-b-0", "hover:bg-transparent", slotProps?.bodyRow?.className)}
        >
          <AerosTableCell
            colSpan={columns.length}
            className={twMerge("!border-t-0", "p-0", slotProps?.rowDetailCell?.className)}
          >
            <Box className='px-4 py-2'>{content}</Box>
          </AerosTableCell>
        </AerosTableRow>
      )
    },
    [columns.length, slotProps?.bodyRow?.className, slotProps?.rowDetailCell?.className]
  )

  const handleRowClick = useCallback(
    (e: React.MouseEvent, row: any) => {
      if (
        (e.target as HTMLElement).closest('[data-clickable="true"]') ||
        (e.target as HTMLElement).closest(".MuiBackdrop-root")
      ) {
        return
      }
      slotProps?.bodyRow?.onClick?.(row)
    },
    [slotProps?.bodyRow]
  )

  // Memoize row rendering logic
  const renderRows = useMemo(() => {
    if (table.getRowCount() === 0) {
      if (features?.tanstackOptions?.state?.isLoading) {
        return <AerosTableLoadingRow columns={columns} />
      }
      return <AerosDataNotFoundRow colSpan={columns.length}>{noDataMessage}</AerosDataNotFoundRow>
    }

    return table.getRowModel().rows.map((row) => (
      <Fragment key={row.id}>
        <AerosTableRow
          {...slotProps?.bodyRow}
          onClick={(e) => handleRowClick(e, row)}
          selected={features?.tanstackOptions?.state?.rowSelection && row.getIsSelected()}
          sx={{ cursor: features?.tanstackOptions?.state?.rowSelection ? "pointer" : "default" }}
          className={twMerge(
            !features?.tanstackOptions?.renderRowDetail && "!border-b-0",
            slotProps?.bodyRow?.className
          )}
        >
          {row.getVisibleCells().map((cell) => {
            const align = cell.column.id === "select" ? "center" : (slotProps?.bodyCell?.align ?? "left")
            const onClick = cell.column.id === "select" ? row.getToggleSelectedHandler() : undefined
            const sizeStyles = {
              width: cell.column.getSize(),
              minWidth: cell.column.getSize(),
              maxWidth: cell.column.getSize(),
            }
            const className = twMerge(
              slotProps?.bodyCell?.className,
              row.getIsExpanded() ? slotProps?.bodyCellExpanded?.className : "",
              features?.tanstackOptions?.renderRowDetail && "!border-b-0"
            )

            const isClickable = cell.column.id === "expand" || cell.column.id === "select" || !!onClick

            return (
              <AerosTableCell
                key={cell.id}
                align={align}
                onClick={onClick}
                data-clickable={isClickable}
                sx={sizeStyles}
                className={className}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </AerosTableCell>
            )
          })}
        </AerosTableRow>
        {features?.tanstackOptions?.renderRowDetail &&
          renderRowDetail(features.tanstackOptions.renderRowDetail({ row }))}
        {features?.tanstackOptions?.renderExpandedContent && row.getIsExpanded() && (
          <AerosTableRow {...slotProps?.expandedRow}>
            <AerosTableCell colSpan={columns.length} className='p-0 hover:bg-transparent' {...slotProps?.expandedCell}>
              {features.tanstackOptions.renderExpandedContent(row)}
            </AerosTableCell>
          </AerosTableRow>
        )}
      </Fragment>
    ))
  }, [
    table.getRowCount(),
    table.getRowModel().rows,
    features?.tanstackOptions?.state?.isLoading,
    features?.tanstackOptions?.state?.rowSelection,
    features?.tanstackOptions?.renderRowDetail,
    features?.tanstackOptions?.renderExpandedContent,
    columns.length,
    noDataMessage,
    handleRowClick,
    renderRowDetail,
    slotProps,
  ])

  return (
    <AerosTableContainer {...slotProps?.container} className={twMerge("relative", slotProps?.container?.className)}>
      {features?.tanstackOptions?.state?.isLoading && table.getRowCount() !== 0 && (
        <Box className='sticky top-0 z-[60]'>
          <LinearProgress />
        </Box>
      )}
      <AerosTable stickyHeader aria-label='sticky table'>
        <AerosTableHead>
          <AerosTableHeadRow {...slotProps?.headerRow}>{headerCells}</AerosTableHeadRow>
        </AerosTableHead>
        <AerosTableBody>{renderRows}</AerosTableBody>
      </AerosTable>
      {error && <Typography color='error'>{error.message}</Typography>}
    </AerosTableContainer>
  )
}
