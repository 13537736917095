import { z } from "zod"

import { casNumberSchema } from "@/utils/validation/casNumberValidation"

export const AddProductRegulatorySchema = z.object({
  cassRegistryId: z.union([z.literal(""), casNumberSchema]).optional(),
  unNumber: z.union([z.literal(""), z.string().regex(/^\d{4}$/, "UN Number must be exactly 4 digits")]).optional(),
  isRohsCompliant: z.boolean().optional(),
  isReachSvhcCompliant: z.boolean().optional(),
})
