import { zodResolver } from "@hookform/resolvers/zod"
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import { CloseOutlined } from "@mui-symbols-material/w300"
import type { FC } from "react"
import { useCallback, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"

import { Button } from "@/components/common/Button"
import { LocationAddressDetailsForm } from "@/screens/Companies/edit/components/locations/LocationAddressDetailsForm"
import { useLocations } from "@/screens/Companies/hooks/useLocations"
import {
  type EditAddressDialogProps,
  type EditAddressQueryProps,
  EditAddressQuerySchema,
} from "@/screens/Companies/types/dialogProps"

export const EditAddressDialog: FC<EditAddressDialogProps> = ({
  open,
  onClose,
  onSubmit,
  activeEditLocation,
  openedDialog,
}) => {
  const methods = useForm<EditAddressQueryProps>({
    values: {
      ...activeEditLocation,
      countryIsoCode: activeEditLocation?.country?.isoCode,
      regionIsoCode: activeEditLocation?.region?.isoCode,
    } as EditAddressQueryProps,
    resolver: zodResolver(EditAddressQuerySchema),
  })

  const { countryList, regionList, setSelectedCountry } = useLocations({
    countryIsoCode: activeEditLocation?.country?.isoCode,
  })
  const countryIsoCode = methods.watch("countryIsoCode")

  useEffect(() => {
    if (openedDialog === null) {
      methods.reset({
        ...activeEditLocation,
        countryIsoCode: activeEditLocation?.country?.isoCode,
        regionIsoCode: activeEditLocation?.region?.isoCode,
      } as EditAddressQueryProps)
    }
  }, [activeEditLocation, openedDialog])

  // TODO: exlore how to set the country on the form
  useEffect(() => {
    if (countryIsoCode) {
      setSelectedCountry(countryIsoCode)
    }
  }, [countryIsoCode, setSelectedCountry])

  const handleSave = useCallback(
    async (value: EditAddressQueryProps) => {
      const isValid = await methods.trigger()
      if (isValid) {
        onSubmit(value)
        onClose("success")
      }
    },
    [onSubmit, onClose, methods]
  )

  return (
    <Dialog open={open} onClose={(_, reason) => onClose(reason)} fullWidth>
      <DialogTitle className='pl-10'>
        <span className='font-normal text-primary'>Edit Location Address</span>
      </DialogTitle>
      <IconButton aria-label='close' onClick={() => onClose("cancel")} className='absolute right-4 top-4 text-primary'>
        <CloseOutlined />
      </IconButton>
      <Divider />
      <DialogContent classes={{ root: "p-10" }}>
        <FormProvider {...methods}>
          <LocationAddressDetailsForm
            countryList={countryList}
            regionList={regionList}
            locationType={activeEditLocation?.externalLocationType ?? undefined}
          />
        </FormProvider>
      </DialogContent>
      <DialogActions className='justify-between px-10 pb-10'>
        <Button variant='primary' appearance='outlined' onClick={() => onClose("cancel")}>
          Cancel
        </Button>
        <Button variant='primary' onClick={methods.handleSubmit(handleSave)} disabled={!methods.formState.isValid}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
