import { Box, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material"
import { KeyboardArrowDownOutlined } from "@mui-symbols-material/w300"
import { Controller, useFormContext } from "react-hook-form"

import type { Country, Region } from "@/graphql/codegen/graphql.ts"
import { ExternalLocationType } from "@/graphql/codegen/graphql.ts"
import type { AddAddressQueryProps } from "@/screens/Companies/types/dialogProps"

type Props = {
  countryList: Country[]
  regionList: Region[]
  locationType?: ExternalLocationType | undefined
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const InputFieldClasses = "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2"

export const LocationAddressDetailsForm = ({
  countryList = [],
  regionList = [],
  locationType,
}: Props): React.ReactElement => {
  const { control, watch } = useFormContext<AddAddressQueryProps>()

  const countryIsoCode = watch("countryIsoCode")

  return (
    <form>
      <Typography
        data-testid='location-address-details-form-title'
        variant='body1'
        fontWeight={700}
        className='leading-6 tracking-[0.15px] text-gray-700'
      >
        Address Details
      </Typography>
      <Box className='space-y-10'>
        <Controller
          name='address1'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Box className='relative' data-testid='location-address-details-form-address1'>
              <label htmlFor='address1' className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>
                Address Line 1<span className='text-red-600'>*</span>
              </label>
              <TextField
                {...field}
                id='address1'
                InputProps={{
                  classes: {
                    input: InputFieldClasses,
                  },
                }}
                placeholder='Avenue or Street #'
                error={!!error}
                fullWidth
                variant='outlined'
                inputProps={{
                  id: "address1",
                }}
              />
              {error?.message && <span className='text-sm text-red-600'>{error?.message}</span>}
            </Box>
          )}
        />
        <Controller
          name='address2'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Box className='relative mt-0' data-testid='location-address-details-form-address2'>
              <label htmlFor='address2' className='absolute -top-6 mb-[7px] block text-sm font-thin text-gray-700'>
                Address Line 2
              </label>
              <TextField
                {...field}
                id='address2'
                InputProps={{
                  classes: {
                    input: InputFieldClasses,
                  },
                }}
                placeholder='Suite, apartment #'
                error={!!error}
                fullWidth
                variant='outlined'
              />
              {error?.message && <span className='text-sm text-red-600'>{error?.message}</span>}
            </Box>
          )}
        />
        <Controller
          name='countryIsoCode'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Box className='relative' data-testid='location-address-details-form-country'>
              <InputLabel id='countryIsoCode' className='absolute -top-6 mb-2 block text-sm font-thin text-gray-700'>
                Country<span className='text-red-600'>*</span>
              </InputLabel>
              <Select
                {...field}
                labelId='countryIsoCode'
                displayEmpty
                input={<OutlinedInput />}
                value={field.value ?? ""}
                SelectDisplayProps={{
                  className:
                    "content-center h-6 px-4 py-2 focus:border-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-gray-200 text-sm leading-5",
                }}
                classes={{
                  icon: "text-gray-600",
                }}
                IconComponent={KeyboardArrowDownOutlined}
                renderValue={(selected: string) => {
                  if (!selected) {
                    return <span className='text-gray-500'>Select One</span>
                  }

                  const selectedCountry = countryList.find((c: Country) => c.isoCode === selected)
                  return selectedCountry ? selectedCountry.name : ""
                }}
                error={!!error}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
                variant='outlined'
                fullWidth
              >
                {countryList.map(({ isoCode, name }) => (
                  <MenuItem key={isoCode} value={isoCode}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              {error?.message && <span className='text-sm text-red-600'>{error?.message}</span>}
            </Box>
          )}
        />
        <Controller
          name='regionIsoCode'
          control={control}
          render={({ field }) => (
            <Box className='relative' data-testid='location-address-details-form-region'>
              <InputLabel id='regionIsoCode' className='absolute -top-6 mb-2 block text-sm font-thin text-gray-700'>
                State (Province / Department / Region)
              </InputLabel>
              <Select
                {...field}
                labelId='regionIsoCode'
                displayEmpty
                input={<OutlinedInput />}
                disabled={!countryIsoCode || countryIsoCode?.length === 0}
                value={field.value && regionList.some((r) => r.isoCode === field.value) ? field.value : ""}
                SelectDisplayProps={{
                  className: "content-center h-6 px-4 py-2 text-sm leading-5",
                }}
                IconComponent={KeyboardArrowDownOutlined}
                classes={{
                  disabled: "cursor-not-allowed bg-gray-200 text-gray-500",
                  icon: "text-gray-600",
                }}
                renderValue={(selected: string) => {
                  if (!selected) {
                    return <span className='text-gray-500'>Select One</span>
                  }

                  return regionList.find((r) => r.isoCode === selected)?.name ?? ""
                }}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
                variant='outlined'
                fullWidth
              >
                {regionList.map((region) => (
                  <MenuItem key={region.isoCode} value={region.isoCode ?? ""}>
                    {region.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
        />
        <Controller
          name='city'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Box className='relative' data-testid='location-address-details-form-city'>
              <label htmlFor='city' className='absolute -top-6 mb-[7px] block text-sm font-thin text-gray-700'>
                City<span className='text-red-600'>*</span>
              </label>
              <TextField
                {...field}
                id='city'
                InputProps={{
                  classes: {
                    input: InputFieldClasses,
                  },
                }}
                placeholder='City, Town, Village'
                error={!!error}
                fullWidth
                variant='outlined'
              />
              {error?.message && <span className='text-sm text-red-600'>{error?.message}</span>}
            </Box>
          )}
        />
        <Controller
          name='postalCode'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Box className='relative' data-testid='location-address-details-form-postal-code'>
              <label htmlFor='postalCode' className='absolute -top-6 mb-[7px] block text-sm font-thin text-gray-700'>
                ZIP Code (Postal Code)
              </label>
              <TextField
                {...field}
                id='postalCode'
                value={field.value || ""}
                InputProps={{
                  classes: {
                    input: InputFieldClasses,
                  },
                }}
                placeholder='00000'
                error={!!error}
                fullWidth
                variant='outlined'
              />
              {error?.message && <span className='text-sm text-red-600'>{error?.message}</span>}
            </Box>
          )}
        />
        <Controller
          name='name'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Box className='relative' data-testid='location-address-details-form-location-name'>
              <label htmlFor='name' className='absolute -top-6 mb-[7px] block text-sm font-thin text-gray-700'>
                Location Name
                <span className='text-red-600'>*</span>
              </label>
              <TextField
                {...field}
                id='name'
                InputProps={{
                  classes: {
                    input: InputFieldClasses,
                  },
                }}
                placeholder='Descriptive location nickname'
                error={!!error}
                fullWidth
                variant='outlined'
                disabled={locationType === ExternalLocationType.Remit}
              />
              {error?.message && <span className='text-sm text-red-600'>{error?.message}</span>}
            </Box>
          )}
        />
      </Box>
    </form>
  )
}
