import { createFileRoute, useParams } from "@tanstack/react-router"

import { ProductPricingTerms } from "@/screens/Companies/products/PricingPackage/ProductPricingTerms"
import { selectProduct, selectSourceLocationId, useProductStore } from "@/stores/useProductStore"

export const Route = createFileRoute("/product/$productId/edit/_layout/pricing")({
  component: RouteComponent,
})

function RouteComponent() {
  const { productId } = useParams({
    from: "/product/$productId/edit/_layout/pricing",
  })

  const product = useProductStore(selectProduct)
  const sourceLocationId = useProductStore(selectSourceLocationId)

  return (
    <ProductPricingTerms
      productId={Number(productId)}
      sourceId={sourceLocationId ?? ""}
      companyId={product?.company?.companyId ?? ""}
    />
  )
}
