import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material"
import { CloseOutlined, InfoOutlined, KeyboardArrowDownOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import { useCallback, useMemo } from "react"
import { Controller, useFormContext } from "react-hook-form"

import { Button } from "@/components/common/Button"
import SelectIconWithClear from "@/components/SelectIconWithClearProps/SelectIconWithClear.tsx"
import type { Company, CompanyLocationAssociationOutput, Location } from "@/graphql/codegen/graphql"

type EditAssociatedLocationModalProps = {
  open: boolean
  locations: Location[]
  companyNameList: Company[]
  loadMore: () => void
  onClose: (action: "cancel" | "success" | "backdropClick" | "escapeKeyDown") => void
  onSubmit: (data: CompanyLocationAssociationOutput) => void
}

const EditAssociatedLocations: FunctionComponent<EditAssociatedLocationModalProps> = ({
  open,
  locations,
  companyNameList,
  loadMore,
  onClose,
  onSubmit,
}) => {
  const methods = useFormContext<{
    shipping: string
    billing: string
    order: string
    office: string
    shippingVia: string
  }>()

  const handleSubmit = useCallback(
    (value: CompanyLocationAssociationOutput) => {
      onSubmit(value)
      onClose("success")
    },
    [onClose]
  )

  const isFieldTouched = methods.formState.isDirty

  const handleResetClick = useCallback(() => {
    methods.reset()
    onClose("cancel")
  }, [onClose])

  const genericOptionList = useMemo(() => {
    return locations.map(({ address1, city, regionIsoCode, locationId, companyId }) => ({
      label: (
        <Box>
          <div>{address1}</div>
          <div>{`${city}, ${regionIsoCode}`}</div>
        </Box>
      ),
      locationId,
      companyId,
    }))
  }, [locations])

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLUListElement>) => {
      const { scrollTop, offsetTop, scrollHeight } = event.currentTarget
      if (offsetTop > scrollHeight - scrollTop) {
        loadMore()
      }
    },
    [loadMore]
  )

  return (
    <>
      {open && (
        <Dialog open={open} onClose={(_, reason) => onClose(reason)} fullWidth>
          <DialogTitle className='pl-10 font-normal text-primary'>Edit Association of Locations</DialogTitle>
          <IconButton
            aria-label='close'
            onClick={() => onClose("cancel")}
            className='absolute right-4 top-4 text-primary'
          >
            <CloseOutlined />
          </IconButton>
          <Divider />
          <DialogContent classes={{ root: "px-10 space-y-4" }}>
            <Box className='w-full'>
              <Controller
                name='shipping'
                control={methods.control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <label className='mb-2 block text-sm font-normal text-gray-700'>
                      Shipping<span className='text-red-600'>*</span>
                    </label>
                    <Select
                      {...field}
                      id='shipping-select'
                      fullWidth
                      value={field.value || ""}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                          },
                          onScroll: handleScroll,
                        },
                      }}
                      IconComponent={KeyboardArrowDownOutlined}
                      className='h-[42px] border-none hover:border-gray-400'
                      sx={{
                        "& .MuiSelect-select": {
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        },
                      }}
                      renderValue={(selected: string) =>
                        locations.find(({ locationId }) => locationId === selected)?.address1
                      }
                    >
                      {genericOptionList.map(({ label, locationId }) => (
                        <MenuItem key={locationId} value={locationId} divider>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                    {error && <span className='text-sm text-red-600'>{error.message}</span>}
                  </>
                )}
              />
            </Box>
            <Box className='w-full'>
              <Controller
                name='billing'
                control={methods.control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <label className='mb-2 block text-sm font-normal text-gray-700'>
                      Billing<span className='text-red-600'>*</span>
                    </label>
                    <Select
                      {...field}
                      disabled
                      id='billing-select'
                      fullWidth
                      value={field.value || ""}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                          },
                          onScroll: handleScroll,
                        },
                      }}
                      IconComponent={KeyboardArrowDownOutlined}
                      endAdornment={
                        <Tooltip title='Updating this address will update the billing addresses for other associations'>
                          <InfoOutlined className='absolute right-10 text-gray-600' />
                        </Tooltip>
                      }
                      className='h-[42px] border-none hover:border-gray-400'
                      sx={{
                        "& .MuiSelect-select": {
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        },
                      }}
                      renderValue={(selected: string) =>
                        locations.find(({ locationId }) => locationId === selected)?.address1
                      }
                    >
                      {genericOptionList.map(({ label, locationId }) => (
                        <MenuItem key={locationId} value={locationId} divider>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                    {error && <span className='text-sm text-red-600'>{error.message}</span>}
                  </>
                )}
              />
            </Box>
            <Box className='w-full'>
              <Controller
                name='order'
                control={methods.control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <label className='mb-2 block text-sm font-normal text-gray-700'>
                      Order<span className='text-red-600'>*</span>
                    </label>
                    <Select
                      {...field}
                      id='order-select'
                      fullWidth
                      value={field.value || ""}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                          },
                          onScroll: handleScroll,
                        },
                      }}
                      IconComponent={KeyboardArrowDownOutlined}
                      className='h-[42px] border-none hover:border-gray-400'
                      sx={{
                        "& .MuiSelect-select": {
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        },
                      }}
                      renderValue={(selected: string) =>
                        locations.find(({ locationId }) => locationId === selected)?.address1
                      }
                    >
                      {genericOptionList.map(({ label, locationId }) => (
                        <MenuItem key={locationId} value={locationId} divider>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                    {error && <span className='text-sm text-red-600'>{error.message}</span>}
                  </>
                )}
              />
            </Box>
            <Box className='w-full'>
              <Controller
                name='office'
                control={methods.control}
                render={({ field }) => (
                  <>
                    <label className='mb-2 block text-sm font-normal text-gray-700'>Office</label>
                    <Select
                      {...field}
                      id='office-select'
                      fullWidth
                      value={field.value || ""}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                          },
                          onScroll: handleScroll,
                        },
                      }}
                      IconComponent={({ className }) => (
                        <SelectIconWithClear
                          value={field.value}
                          onClear={() => field.onChange("")}
                          className={className}
                        />
                      )}
                      className='h-[42px] border-none hover:border-gray-400'
                      sx={{
                        "& .MuiSelect-select": {
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        },
                      }}
                      renderValue={(selected: string) =>
                        locations.find(({ locationId }) => locationId === selected)?.address1
                      }
                    >
                      {genericOptionList.map(({ label, locationId }) => (
                        <MenuItem key={locationId} value={locationId} divider>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              />
            </Box>
            <Box className='w-full'>
              <Controller
                name='shipVia'
                control={methods.control}
                render={({ field }) => (
                  <>
                    <label className='mb-2 block text-sm font-normal text-gray-700'>Ship Via</label>
                    <Select
                      {...field}
                      id='shipVia-select'
                      value={field.value || ""}
                      fullWidth
                      IconComponent={({ className }) => (
                        <SelectIconWithClear
                          value={field.value}
                          onClear={() => field.onChange("")}
                          className={className}
                        />
                      )}
                      className='h-[42px] border-none hover:border-gray-400'
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                          },
                          onScroll: handleScroll,
                        },
                      }}
                      sx={{
                        "& .MuiSelect-select": {
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        },
                      }}
                    >
                      {companyNameList.map(({ name, companyId }) => (
                        <MenuItem key={companyId} value={companyId} divider>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              />
            </Box>
          </DialogContent>
          <DialogActions className={"justify-between px-10 pb-10"}>
            <Button appearance='outlined' onClick={handleResetClick}>
              Cancel
            </Button>
            <Button
              appearance='contained'
              type='submit'
              disabled={!isFieldTouched}
              onClick={methods.handleSubmit(handleSubmit)}
            >
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default EditAssociatedLocations
