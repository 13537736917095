import { useNavigate, useSearch } from "@tanstack/react-router"
import type { OnChangeFn, SortingState } from "@tanstack/react-table"
import { getCoreRowModel } from "@tanstack/react-table"
import type { FC } from "react"
import { useCallback, useEffect, useMemo, useRef } from "react"

import { AerosBaseTable } from "@/components/Tables/Aeros/components/AerosBaseTable"
import type { CompanySortField } from "@/graphql/codegen/graphql"
import { SortingOrder } from "@/graphql/codegen/graphql"
import { useScrollLoadMore } from "@/hooks/useScrollLoadMore"
import { useThrottle } from "@/hooks/useThrottle"
import { queryClient } from "@/providers/GraphqlRouterProvider"
import {
  type SortableAccessorKey,
  accessorKeyToCompanySortField,
  companySortFieldToAccessoryKey,
  useCompanyColumns,
} from "@/screens/Companies/components/tables/companies.const.tsx"
import { useCompanySearchStore } from "@/screens/Companies/stores/useCompanySearchStore.ts"
import type { CompanySearchFilters } from "@/screens/Companies/stores/useCompanySearchStore.ts"
import { getRefinedSearchParams } from "@/screens/Companies/utils.ts"
import useDebounce from "@/utils/useDebounce"

export const CompaniesTable: FC = () => {
  const navigate = useNavigate()
  const currentSearch = useSearch({ from: "/companies/" })
  const { category, query, field: searchField, order, ...restSearchParams } = currentSearch

  const data = useCompanySearchStore(({ data }) => data)
  const fetching = useCompanySearchStore(({ fetching }) => fetching)
  const error = useCompanySearchStore(({ error }) => error)
  const executeSearch = useCompanySearchStore(({ executeSearch }) => executeSearch)
  const loadMore = useCompanySearchStore(({ loadMore }) => loadMore)
  const clearStore = useCompanySearchStore(({ clearStore }) => clearStore)

  const tableContainerRef = useRef<HTMLDivElement>(null)

  const debouncedExecuteSearch = useDebounce((filters: CompanySearchFilters) => {
    executeSearch(queryClient, filters)
  }, 300)

  useEffect(() => {
    const searchFilters: CompanySearchFilters = {
      ...restSearchParams,
      query,
      category,
      field: searchField as CompanySortField | undefined,
      order,
    }
    debouncedExecuteSearch(searchFilters)
  }, [debouncedExecuteSearch, query, category, searchField, order, restSearchParams])

  const sorting = useMemo(() => {
    if (!searchField) return []

    const tableSortField = companySortFieldToAccessoryKey.get(searchField as CompanySortField)
    if (!tableSortField) return []

    return [
      {
        id: tableSortField,
        desc: order === SortingOrder.Desc,
      },
    ]
  }, [searchField, order])

  const handleSortingChange: OnChangeFn<SortingState> = (updaterFn) => {
    // @ts-expect-error - updaterFn is know fn
    const newSorting = updaterFn(sorting)
    if (newSorting.length > 0) {
      const sort = newSorting[0]
      const key = sort.id as SortableAccessorKey
      const field = accessorKeyToCompanySortField.get(key)
      const params = {
        ...restSearchParams,
        query,
        category,
        field,
        order: sort.desc ? SortingOrder.Desc : SortingOrder.Asc,
      }
      const refinedParams = getRefinedSearchParams(params)
      void navigate({
        search: refinedParams.search,
        to: "/companies",
        replace: true,
      })
    }
  }

  const [throttledLoadMore] = useThrottle(() => {
    const searchFilters: CompanySearchFilters = {
      ...restSearchParams,
      query,
      category,
      field: searchField as CompanySortField | undefined,
      order,
    }
    loadMore(queryClient, searchFilters)
  }, 200)

  const scrollHandler = useScrollLoadMore({
    fetching,
    loadMore: throttledLoadMore,
    scrollContainerRef: tableContainerRef,
  })

  const onRowClick = useCallback((row: { original: { companyId: string } }) => {
    void navigate({ from: "/companies", to: `${row.original.companyId}/edit` })
  }, [])

  const columns = useCompanyColumns()

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      clearStore()
    }
  }, [clearStore])

  return (
    <AerosBaseTable
      error={error}
      features={{
        table: {
          stickyHeader: true,
        },
        tanstackOptions: {
          data,
          columns,
          state: {
            sorting,
            isLoading: fetching,
          },
          onSortingChange: handleSortingChange,
          manualSorting: true,
          enableSorting: true,
          enableMultiSort: false,
          enableSortingRemoval: false,
          getCoreRowModel: getCoreRowModel(),
        },
      }}
      slotProps={{
        container: {
          ref: tableContainerRef,
          onScroll: scrollHandler,
        },
        bodyRow: {
          className: "hover:bg-primary-100 cursor-pointer",
          onClick: (row) => {
            if ("original" in row) {
              onRowClick(row)
            }
          },
        },
      }}
      noDataMessage='No companies found'
    />
  )
}

export default CompaniesTable
