import { Box, CircularProgress } from "@mui/material"
import type { ColumnDef } from "@tanstack/react-table"
import type { ReactElement } from "react"
import { twMerge } from "tailwind-merge"

import { AerosTableCell } from "./AerosTableCell"
import { AerosTableRow } from "./AerosTableRow"

type AerosTableLoadingRowProps = {
  columns: ColumnDef<any, any>[]
  className?: string
}

export const AerosTableLoadingRow = ({ columns, className }: AerosTableLoadingRowProps): ReactElement => {
  return (
    <AerosTableRow className={twMerge("hover:bg-transparent", className)}>
      <AerosTableCell colSpan={columns.length} align='center' className='py-8'>
        <Box className='flex flex-col items-center gap-2'>
          <CircularProgress size={48} />
        </Box>
      </AerosTableCell>
    </AerosTableRow>
  )
}
