import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from "@mui/material"
import { CloseOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import { useCallback } from "react"
import { useFormContext } from "react-hook-form"

import { ProductConditionsContent } from "./ProductConditionsContent"

import { Button } from "@/components/common/Button"

type AddProductConditionsProps = {
  open: boolean
  onClose: (action: "cancel" | "success" | "backdropClick" | "escapeKeyDown") => void
  onSubmit: (data) => void
}

const AddProductConditionsModal: FunctionComponent<AddProductConditionsProps> = ({ open, onClose, onSubmit }) => {
  const methods = useFormContext()

  const handleSubmit = useCallback(
    (value) => {
      onSubmit(value)
      onClose("success")
    },
    [onClose]
  )

  const isGeneralStepDisabled = !methods.formState.isValid

  const handleResetClick = useCallback(() => {
    methods.reset()
    onClose("cancel")
  }, [onClose])

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        methods.reset()
        onClose(reason)
      }}
      fullWidth
    >
      <DialogTitle className='pl-10'>
        <span className='font-normal text-primary'>Product Conditions</span>
      </DialogTitle>
      <IconButton aria-label='close' onClick={() => onClose("cancel")} className='absolute right-4 top-4 text-primary'>
        <CloseOutlined />
      </IconButton>
      <Divider />
      <DialogContent classes={{ root: "p-10" }}>
        <ProductConditionsContent />
      </DialogContent>
      <DialogActions className={"justify-between px-10 pb-10"}>
        <Button appearance='outlined' onClick={handleResetClick}>
          Cancel
        </Button>
        <Button onClick={methods.handleSubmit(handleSubmit)} disabled={isGeneralStepDisabled}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddProductConditionsModal
