import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import { CloseFilledOutlined, WarningOutlined } from "@mui-symbols-material/w300"
import { useNavigate } from "@tanstack/react-router"
import type { FC } from "react"

import { Button } from "@/components/common/Button"
import type { DialogCloseAction } from "@/screens/Companies/types/dialogProps"

interface NoLocationActivationModalProps {
  open: boolean
  onClose: (reason: DialogCloseAction) => void
  companyId: string
}

const messages = {
  title: "Location Required",
  description:
    "A company must have at least one associated location before it can be activated. Would you like to add a location now?",
}

export const NoLocationActivationModal: FC<NoLocationActivationModalProps> = ({ open, onClose, companyId }) => {
  const navigate = useNavigate({ from: "/companies/$companyId/edit" })

  const handleAddLocation = () => {
    navigate({
      to: "/companies/$companyId/edit",
      params: { companyId },
      search: { tab: "locations" },
    })
    onClose("success")
  }

  return (
    <Dialog open={open} onClose={() => onClose("close")} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Box display='flex' justifyContent='flex-end'>
          <Button size='small' appearance='text' onClick={() => onClose("close")}>
            <CloseFilledOutlined className='text-blue-gray' />
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box textAlign='center'>
          <WarningOutlined className='size-[48px] text-secondary-600' />
          <Typography variant='h5' color='primary' className='mt-6 font-normal'>
            {messages.title}
          </Typography>
          <Typography variant='h6' className='mt-6 px-3 text-base leading-[150%] tracking-[-0.85px] text-gray-700'>
            {messages.description}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display='flex' justifyContent='flex-end' width='100%' p={2}>
          <Button appearance='text' variant='secondary' className='mr-2' onClick={() => onClose("cancel")}>
            Cancel
          </Button>
          <Button variant='primary' onClick={handleAddLocation}>
            Add Location
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
