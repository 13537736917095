import { z } from "zod"

import { ApplicableToEnum, IncludeWithEnum, TransportationModeEnum } from "@/graphql/codegen/graphql"

export const AddInstructionsSchema = z.object({
  applicableTo: z.array(z.nativeEnum(ApplicableToEnum)).min(1, "Please select at least one applicable option"),
  transportationMode: z
    .array(z.nativeEnum(TransportationModeEnum))
    .min(1, "Please select at least one transportation mode"),
  includedWith: z.array(z.nativeEnum(IncludeWithEnum)).min(1, "Please select at least one inclusion option"),
  productInstructionId: z.array(z.string()).min(1, "Please select at least one instruction"),
  groupId: z.string(),
})
