import { Box, MenuItem, OutlinedInput, Popover, Select, TextField, Typography } from "@mui/material"
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view"
import {
  CheckCircleFilledRounded,
  ChevronRightOutlined,
  ExpandMoreOutlined,
  KeyboardArrowDownOutlined,
} from "@mui-symbols-material/w300"
import type { ReactNode } from "@tanstack/react-router"
import React, { type FC, useMemo, useRef, useState } from "react"
import { Controller, useController, useFormContext } from "react-hook-form"

import { useGetCategoryList } from "../hooks/useGetCategoryList"
import { useGetUnitOfMeasurementList } from "../hooks/useGetUnitOfMeasurementList"

import type { AddProductModalType } from "./AddProductSchema"

import type { Category } from "@/graphql/codegen/graphql"
import { ProductFormInfoItem } from "@/graphql/codegen/graphql"
import { useInfiniteScroll } from "@/hooks/useInfniniteScroll"
import { useScrollLoadMore } from "@/hooks/useScrollLoadMore"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const productFormLabels: Record<keyof typeof ProductFormInfoItem, string> = {
  Bead: "Bead",
  Granule: "Granule",
  Liquid: "Liquid",
  LiquidDispersion: "Liquid Dispersion",
  Pellet: "Pellet",
  Powder: "Powder",
}

type SelectComponentProps = {
  name: string
  label: string
  placeholder?: string
  control: any
  children?: ReactNode
  required?: boolean
  ref?: any
  renderValue?: (value: any) => React.ReactNode
}

export const SelectComponent: FC<SelectComponentProps> = (props: SelectComponentProps) => {
  const { name, control, children, label, placeholder, required, ref, renderValue } = props
  const {
    field,
    fieldState: { error },
  } = useController({ name, control })

  return (
    <Box className='relative'>
      <label className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>
        {label}
        {required && <span className='text-red-600'>*</span>}
      </label>
      <Select
        {...field}
        ref={ref}
        displayEmpty
        input={<OutlinedInput />}
        SelectDisplayProps={{
          className:
            "content-center h-6 px-4 py-2 focus:border-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-gray-200 text-sm leading-5",
        }}
        classes={{
          icon: "text-gray-600",
        }}
        IconComponent={KeyboardArrowDownOutlined}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
        variant='outlined'
        fullWidth
        value={field.value || ""}
        renderValue={
          renderValue ||
          ((value: string | undefined) => {
            if (!value) {
              return <Typography color='textSecondary'>{placeholder || `Select ${label}`}</Typography>
            }
            return value
          })
        }
      >
        <MenuItem value='' divider>
          <Typography color='textSecondary'>{placeholder || `Select ${label}`}</Typography>
        </MenuItem>
        {children}
      </Select>
      {error?.message && <span className='text-sm text-red-600'>{error?.message}</span>}
    </Box>
  )
}

interface CategoryNode extends Category {
  name: string
  children?: CategoryNode[]
}

export const AddProductSpecifics: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const { control, getValues, setValue } = useFormContext<AddProductModalType>()
  const formData = useMemo(() => getValues(), [getValues]) as AddProductModalType
  const { categoryList, categoryListFetching, loadMore } = useGetCategoryList()
  const {
    unitOfMeasurementList,
    unitOfMeasurementListFetching,
    loadMore: loadMoreUnitOfMeasurement,
  } = useGetUnitOfMeasurementList()
  const categoryListRef = useRef<HTMLUListElement>(null)

  const onScroll = useScrollLoadMore({
    fetching: categoryListFetching,
    loadMore,
    scrollContainerRef: categoryListRef,
  })

  const { containerRef: unitOfMeasurementListRef } = useInfiniteScroll(loadMoreUnitOfMeasurement, {
    isLoading: unitOfMeasurementListFetching,
    hasMore: unitOfMeasurementList.length % 20 === 0,
    threshold: 200,
  })

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleNodeSelect = (event: React.MouseEvent<HTMLElement>, nodeId: string) => {
    const findNode = (nodes: CategoryNode[]): CategoryNode | null => {
      for (const node of nodes) {
        if (node.id === nodeId) return node
        if (node.children) {
          const found = findNode(node.children)
          if (found) return found
        }
      }
      return null
    }
    const selected = (event.target as HTMLElement).innerText ? findNode(categoryList as CategoryNode[]) : null
    setValue("classification", { id: "", name: "" })
    if (selected && (!selected.children || selected.children.length === 0)) {
      setValue(
        "classification",
        { id: selected.id, name: selected.name },
        { shouldValidate: true, shouldDirty: true, shouldTouch: true }
      )
      handleClose()
    }
  }

  const renderTree = (
    nodes: CategoryNode[],
    handleNodeSelect: (event: React.MouseEvent<HTMLElement>, nodeId: string) => void
  ) =>
    nodes.map((node, index) => (
      <TreeItem
        key={node.id + index}
        itemId={node.id + index}
        label={node.name}
        onClick={(event) => (!node.children || node.children.length === 0 ? handleNodeSelect(event, node.id) : null)}
      >
        {node.children ? renderTree(node.children, handleNodeSelect) : null}
      </TreeItem>
    ))

  return (
    <form className='space-y-6'>
      <Box className='space-y-4'>
        <Box className='mb-2' display='flex' alignItems='center'>
          <Typography
            variant='body1'
            fontWeight={700}
            className='leading-6 tracking-[0.15px] text-gray-700'
            display='flex'
            alignItems='center'
          >
            1. Product Information
            <CheckCircleFilledRounded className='ml-1 text-success-500' />
          </Typography>
        </Box>
        <Box className='px-10'>
          <p className='truncate py-0 text-sm text-gray-900'>Vendor Product SKU: {formData.vendorSku || "Not added"}</p>
          <p className='truncate py-0 text-sm text-gray-900'>Vendor Description: {formData.vendorDescription}</p>
          <p className='truncate py-0 text-sm text-gray-900'>
            Akrochem Description: {formData.companyProductDescription}
          </p>
          <p className='py-0 text-sm text-gray-900'>Product Manager: {formData.productManager?.name || "Not added"}</p>
          <p className='truncate py-0 text-sm text-gray-900'>
            Product Ship From:{" "}
            {formData.locationsAssociations?.length
              ? formData.locationsAssociations
                  .map(({ address1, city, regionIsoCode }) => `${address1} - ${city} ${regionIsoCode}`)
                  .join(", ")
              : "Not added"}
          </p>
        </Box>
      </Box>
      <Typography variant='body1' fontWeight={700} className='leading-6 tracking-[0.15px] text-gray-700'>
        2. Product Specifics
      </Typography>
      <Box className='space-y-10'>
        <Controller
          name='classification'
          control={control}
          render={({ field }) => (
            <Box className='relative'>
              <label className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>
                Product Classification <span className='text-red-600'>*</span>
              </label>
              <TextField
                onClick={handleClick}
                InputProps={{
                  classes: {
                    input: "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2",
                  },
                }}
                value={field.value?.name || ""}
                placeholder='Add Classification Type'
                fullWidth
                variant='outlined'
                onChange={field.onChange}
              />
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <SimpleTreeView
                  ref={categoryListRef}
                  onScrollCapture={onScroll}
                  slots={{
                    expandIcon: ChevronRightOutlined,
                    collapseIcon: ExpandMoreOutlined,
                  }}
                  sx={{ width: 520, maxHeight: 400, overflowY: "auto" }}
                >
                  {renderTree(categoryList as CategoryNode[], handleNodeSelect)}
                </SimpleTreeView>
              </Popover>
            </Box>
          )}
        />
        <Controller
          name='productFormInfo'
          control={control}
          render={() => (
            <SelectComponent
              name='productFormInfo'
              control={control}
              label='Product Form'
              placeholder='Add Product Physical Form'
              required
              renderValue={(value: string | undefined) => {
                if (!value) {
                  return <Typography color='textSecondary'>Select Product Form</Typography>
                }
                return productFormLabels[value as keyof typeof ProductFormInfoItem] || value
              }}
            >
              {Object.keys(ProductFormInfoItem).map((productInfo) => (
                <MenuItem key={productInfo} value={productInfo}>
                  {productFormLabels[productInfo as keyof typeof ProductFormInfoItem]}
                </MenuItem>
              ))}
            </SelectComponent>
          )}
        />
        <Controller
          name='uomId'
          control={control}
          render={({ field }) => (
            <SelectComponent
              {...field}
              name='uomId'
              control={control}
              label='Product Unit of Measurement (UoM)'
              placeholder='Add UoM'
              required
              ref={unitOfMeasurementListRef}
              renderValue={(value: string | undefined) => {
                if (!value) {
                  return <Typography color='textSecondary'>Select Unit of Measurement</Typography>
                }
                const uom = unitOfMeasurementList.find(({ id }) => id === value)
                return uom?.name || "Select Unit of Measurement"
              }}
            >
              {unitOfMeasurementList.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </SelectComponent>
          )}
        />
      </Box>
    </form>
  )
}
