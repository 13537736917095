import type { CompanyListFilter } from "@/graphql/codegen/graphql"
import { validateSearchQuery } from "@/utils/search"

type SearchParams<T = CompanyListFilter> = {
  [key: string]: any
  filter?: T
}

export const getRefinedSearchParams = <T extends SearchParams>(
  currentSearch: T,
  newFilters?: Partial<T>
): { search: Partial<T>; replace: true } => {
  // Merge the current search parameters with the new filters
  const updatedSearchParams = {
    ...currentSearch,
    ...newFilters,
  }

  // Remove any keys with empty values
  const refinedSearchParams = Object.entries(updatedSearchParams).reduce<Partial<T>>((acc, [key, value]) => {
    if (key === "query") {
      const validQuery = validateSearchQuery(value as string)
      if (validQuery) {
        acc = { ...acc, [key]: validQuery }
      }
    } else if (Array.isArray(value)) {
      if (value.length > 0) {
        acc = { ...acc, [key]: value }
      }
    } else if (typeof value === "string") {
      if (value.trim() !== "") {
        acc = { ...acc, [key]: value }
      }
    } else if (value !== null && value !== undefined) {
      acc = { ...acc, [key]: value }
    }
    return acc
  }, {})

  return {
    search: refinedSearchParams,
    replace: true, // Replace the current entry in history
  }
}
