import { zodResolver } from "@hookform/resolvers/zod"
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material"
import { AddOutlined, CloseOutlined, KeyboardArrowDownOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import React, { useCallback, useMemo } from "react"
import { type Control, Controller, type FieldArrayWithId, useFieldArray, useForm } from "react-hook-form"

import { useLocations } from "../hooks/useLocations"

import { Button } from "@/components/common/Button"
import MultiSelect from "@/components/MultiSelect/MultiSelect"
import type { ContactLocationCreateInput, Location } from "@/graphql/codegen/graphql"
import { ContactRole } from "@/graphql/codegen/graphql"
import { UIAddContactLocationRoleSchema } from "@/screens/Companies/create/components/AddContactSchema.tsx"
import { useCreateNewContactModal } from "@/screens/Companies/hooks/useCreateNewContact"
import { convertToBackendFormat, convertToUIFormat } from "@/screens/Contacts/utils/dataMappers.ts"
import { formatLocationAddress } from "@/utils/utils"

export type SelectedLocationRole = { locationId: string; role: ContactRole; contactLocationId: string }

export type UILocationRoles = {
  locationId: string
  roles: string[]
}

export type UIFormData = {
  locationRoles: UILocationRoles[]
}

type LocationRolesDialogProps = {
  open: boolean
  name: string
  contactId: string
  companyId: string
  companyName?: string
  selectedLocationRoles?: SelectedLocationRole[]
  onClose: (action: "cancel" | "success" | "backdropClick" | "escapeKeyDown") => void
  onSubmit: (data: SelectedLocationRole[]) => void
  children: React.ReactNode
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

type LocationRolesFieldsProps = {
  fields: FieldArrayWithId<UIFormData, "locationRoles">[]
  control: Control<any>
  locations: Location[]
  currentLocationRoles: UILocationRoles[]
  addRoleAtLocation: () => void
}

export const LocationRolesFields = ({
  fields,
  control,
  locations,
  currentLocationRoles,
  addRoleAtLocation,
}: LocationRolesFieldsProps): React.ReactNode => {
  const isLocationDisabled = (locId: string, selfIndex: number) => {
    return currentLocationRoles.some((item, i) => i !== selfIndex && item.locationId === locId)
  }
  return (
    <Box className='space-y-10'>
      {fields.map((field, index) => (
        <div key={field.id}>
          <Controller
            name={`locationRoles.${index}.locationId`}
            control={control}
            render={({ field }) => (
              <Box className='relative'>
                <label className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>Location</label>
                <Select
                  {...field}
                  displayEmpty
                  input={<OutlinedInput />}
                  SelectDisplayProps={{
                    className:
                      "content-center h-6 px-4 py-2 focus:border-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-gray-200 text-sm leading-5",
                  }}
                  classes={{
                    icon: "text-gray-600",
                  }}
                  IconComponent={KeyboardArrowDownOutlined}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                  variant='outlined'
                  fullWidth
                >
                  {locations.map((location) => {
                    const disabled = isLocationDisabled(location.locationId, index)
                    return (
                      <MenuItem key={location.locationId} value={location.locationId} disabled={disabled}>
                        {formatLocationAddress(location)}
                      </MenuItem>
                    )
                  })}
                </Select>
              </Box>
            )}
          />
          <Controller
            name={`locationRoles.${index}.roles`}
            control={control}
            render={({ field }) => (
              <Box className='relative'>
                <label className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>Roles</label>
                <MultiSelect
                  id={`locationRoles.${index}.roles`}
                  value={field.value || []}
                  onChange={(selectedValues) => field.onChange(selectedValues)}
                  options={Object.keys(ContactRole).map((roleKey) => {
                    const roleValue = ContactRole[roleKey as keyof typeof ContactRole]
                    return {
                      value: roleValue,
                      label: roleKey,
                    }
                  })}
                  MenuProps={{
                    PaperProps: {
                      style: { maxHeight: 300 },
                    },
                  }}
                  children={Object.keys(ContactRole).map((roleKey) => {
                    const roleValue = ContactRole[roleKey as keyof typeof ContactRole]
                    const isChecked = field.value?.includes(roleValue)
                    return (
                      <MenuItem key={roleKey} value={roleValue}>
                        <Checkbox checked={!!isChecked} />
                        {roleKey}
                      </MenuItem>
                    )
                  })}
                />
              </Box>
            )}
          />
        </div>
      ))}
      <Box className='flex w-full justify-end'>
        <Button variant='primary' appearance='text' startIcon={<AddOutlined />} onClick={addRoleAtLocation}>
          Role At Location
        </Button>
      </Box>
    </Box>
  )
}

const LocationRolesDialog: FunctionComponent<LocationRolesDialogProps> = ({
  open,
  name,
  contactId,
  companyId,
  companyName,
  selectedLocationRoles = [],
  onClose,
  onSubmit,
  children,
}) => {
  const { createContactLocation, deleteContactLocation } = useCreateNewContactModal()
  const { locations } = useLocations({ companyId })
  const uiDefaultValues = useMemo(() => convertToUIFormat(selectedLocationRoles), [selectedLocationRoles])

  const { control, formState, reset, watch, handleSubmit } = useForm<UIFormData>({
    defaultValues: uiDefaultValues,
    resolver: zodResolver(UIAddContactLocationRoleSchema),
    mode: "all",
  })

  const { fields, append } = useFieldArray({
    control,
    name: "locationRoles",
  })

  const canSave = formState.isValid && formState.isDirty

  const currentLocationRoles = watch("locationRoles")

  const addRoleAtLocation = () => append({ locationId: "", roles: [] })

  const handleOnSubmit = useCallback(
    async (data: UIFormData) => {
      const newItems = convertToBackendFormat(data)

      const oldItemsMap = new Map<string, SelectedLocationRole>(
        selectedLocationRoles.map((oldItem) => [`${oldItem.locationId}-${oldItem.role}`, oldItem])
      )

      const newItemsMap = new Map<string, SelectedLocationRole>(
        newItems.map((newItem) => [`${newItem.locationId}-${newItem.role}`, newItem])
      )

      for (const [key, oldItem] of oldItemsMap) {
        if (!newItemsMap.has(key) && oldItem.contactLocationId) {
          await deleteContactLocation({
            input: { contactLocationId: oldItem.contactLocationId },
          })
        }
      }

      for (const [key, newItem] of newItemsMap) {
        if (!oldItemsMap.has(key)) {
          const formattedContactLocation: ContactLocationCreateInput = {
            contactId,
            locationId: newItem.locationId,
            contactRole: newItem.role,
          }
          await createContactLocation({ input: formattedContactLocation })
        }
      }

      onSubmit(newItems)
      onClose("success")
      reset()
    },
    [contactId, createContactLocation, deleteContactLocation, onSubmit, onClose, reset, selectedLocationRoles]
  )

  const handleResetClick = useCallback(() => {
    reset()
    onClose("cancel")
  }, [onClose, reset])

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        onClose(reason)
        reset()
      }}
      fullWidth
    >
      <form>
        <DialogTitle className='pb-3 pl-10 font-normal text-primary'>
          {name}
          {companyName && (
            <Typography variant='subtitle1' className='text-gray-700'>
              {companyName}
            </Typography>
          )}
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={() => {
            onClose("cancel")
            reset()
          }}
          className='absolute right-4 top-4 text-primary'
        >
          <CloseOutlined />
        </IconButton>
        <Divider />
        <DialogContent classes={{ root: "p-10" }}>
          {children}
          <LocationRolesFields
            addRoleAtLocation={addRoleAtLocation}
            control={control}
            fields={fields}
            locations={locations}
            currentLocationRoles={currentLocationRoles}
          />
        </DialogContent>
        <DialogActions className={"justify-between px-10 pb-10"}>
          <Button appearance='outlined' onClick={handleResetClick}>
            Cancel
          </Button>
          <Button type='submit' onClick={handleSubmit(handleOnSubmit)} disabled={!canSave}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default LocationRolesDialog
