import { AddOutlined, WarningOutlined } from "@mui-symbols-material/w300"
import type { FC } from "react"

import InstructionGroups from "../InsturctionGroups/InstructionGroups"

import { InformationMissingAlert } from "@/components/common/Alerts/InformationMissingAlert"
import { Button } from "@/components/common/Button"
import type { RelatedProductInstruction } from "@/graphql/codegen/graphql"

interface InstructionsContentProps {
  instructions: RelatedProductInstruction[]
  onAddInstruction: () => void
  onEditInstruction: (instruction: RelatedProductInstruction) => void
  onDeleteInstruction: (instruction: RelatedProductInstruction) => void
}

export const InstructionsContent: FC<InstructionsContentProps> = ({
  instructions,
  onAddInstruction,
  onEditInstruction,
  onDeleteInstruction,
}) => {
  if (instructions.length === 0) {
    return (
      <InformationMissingAlert
        icon={<WarningOutlined color='primary' />}
        title='Start by adding instruction groups'
        description='No instructions have been added yet. Once you add some, you will be able to see them here.'
        action={
          <Button
            color='inherit'
            size='small'
            appearance='outlined'
            className='text-primary-500'
            startIcon={<AddOutlined />}
            onClick={onAddInstruction}
          >
            Instruction Group
          </Button>
        }
      />
    )
  }

  return (
    <InstructionGroups
      data={instructions}
      onEditInstruction={onEditInstruction}
      onDeleteInstruction={onDeleteInstruction}
    />
  )
}
