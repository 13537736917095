import { ButtonGroup, LinearProgress, Menu } from "@mui/material"
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view"
import { ArrowDropDownOutlined, ExpandLessOutlined, ExpandMoreOutlined } from "@mui-symbols-material/w300"
import { useNavigate, useSearch } from "@tanstack/react-router"
import type { MouseEvent, ReactElement } from "react"
import { useEffect, useMemo, useState } from "react"

import { Button } from "@/components/common/Button"
import { getRefinedSearchParams } from "@/screens/Companies/utils.ts"
import type { TreeNode } from "@/types/tree.ts"

type SelectedValues = { [key: string]: boolean | string | null }

enum FilterType {
  All = "all",
  CommodityVendor = "COMMODITY_VENDOR",
  Customer = "CUSTOMER",
}

const singleSelectTypes = [FilterType.CommodityVendor, FilterType.Customer]

const buildNodeMap = <T,>(nodes: TreeNode<T>[]): Record<string, TreeNode<T>> => {
  const map: Record<string, TreeNode<T>> = {}

  const traverse = (node: TreeNode<T>) => {
    map[node.id] = node
    if (node.children) {
      node.children.forEach(traverse)
    }
  }

  nodes.forEach(traverse)
  return map
}

const getAllDescendants = <T,>(node: TreeNode<T>, map: Record<string, TreeNode<T>>): string[] => {
  const result: string[] = [node.id]

  if (node.children) {
    for (const child of node.children) {
      result.push(...getAllDescendants(map[child.id], map))
    }
  }

  return result
}

const renderTreeItems = <T,>({
  nodes,
  selectedValues,
  level = 0,
}: {
  nodes: TreeNode<T>[]
  selectedValues: SelectedValues
  level?: number
}): ReactElement => {
  return (
    <>
      {nodes.map((node) => (
        <TreeItem
          key={node.id}
          itemId={node.id}
          label={<>{node.name}</>}
          classes={{
            iconContainer: (!node.children || node.children.length === 0) && level === 0 ? "w-0" : "",
          }}
        >
          {node.children &&
            node.children.length > 0 &&
            renderTreeItems({ nodes: node.children, selectedValues, level: level + 1 })}
        </TreeItem>
      ))}
    </>
  )
}

export const CompanyTypeButtonGroup = ({
  companyTypes,
  fetching,
}: {
  companyTypes: TreeNode<string>[]
  fetching: boolean
}): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isDropdownOpen = Boolean(anchorEl)

  const navigate = useNavigate()
  const currentSearch = useSearch({
    from: "/companies/",
    strict: true,
  })

  const [selectedValues, setSelectedValues] = useState<SelectedValues>({})

  const nodeMap = useMemo(() => buildNodeMap(companyTypes), [companyTypes])

  const handleCheckboxChange = (_event: unknown, newSelectedIds: string[]) => {
    const oldSelectedIds = Object.keys(selectedValues)
    const oldSet = new Set(oldSelectedIds)
    const newSet = new Set(newSelectedIds)

    const added = [...newSet].filter((id) => !oldSet.has(id))
    const removed = [...oldSet].filter((id) => !newSet.has(id))

    for (const id of added) {
      const node = nodeMap[id]
      if (!node) continue
      const descendants = getAllDescendants(node, nodeMap)
      for (const descId of descendants) {
        newSet.add(descId)
      }
    }

    for (const id of removed) {
      const node = nodeMap[id]
      if (!node) continue
      const descendants = getAllDescendants(node, nodeMap)
      for (const descId of descendants) {
        newSet.delete(descId)
      }
    }

    const finalSelectedValues = {} as SelectedValues
    for (const id of newSet) {
      finalSelectedValues[id] = id
    }
    setSelectedValues(finalSelectedValues)

    const refinedParams = getRefinedSearchParams(currentSearch, { types: [...newSet] })
    // Update the search params
    void navigate({
      search: (prev) => {
        const updatedSearch = { ...prev, ...refinedParams.search }
        // Remove the 'types' parameter if no types are selected
        if (newSet.size === 0) {
          delete updatedSearch.types
        } else {
          updatedSearch.types = [...newSet]
        }
        return updatedSearch
      },
      replace: true,
      to: "/companies/",
    })
  }

  const handleDropdownClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = () => {
    setAnchorEl(null)
  }

  const handleButtonClick = (type: string) => {
    let newTypes = [...(currentSearch.types || [])]

    if (type === FilterType.All) {
      newTypes = [] // Clear all filters
    } else if (newTypes.includes(type)) {
      newTypes = newTypes.filter((t) => t !== type) // Remove the type if already selected
    } else {
      if (singleSelectTypes.includes(type as FilterType)) {
        newTypes = newTypes.filter((t) => !singleSelectTypes.includes(t as FilterType))
      }
      newTypes.push(type) // Add the type if not selected
    }

    // Get the refined search params
    const refinedParams = getRefinedSearchParams(currentSearch, { types: newTypes })

    // Update the search params
    void navigate({
      search: (prev) => {
        const updatedSearch = { ...prev, ...refinedParams.search }
        // Remove the 'types' parameter if no types are selected
        if (newTypes.length === 0) {
          delete updatedSearch.types
        } else {
          // Ensure types is an array of strings, not an object
          updatedSearch.types = newTypes
        }
        return updatedSearch
      },
      replace: true,
      to: "/companies/",
    })
  }

  // Determine if a special button ("all", "suppliers", "customers") is selected
  const isSelected = (type: string) => {
    if (type === FilterType.All) {
      return !currentSearch.types || currentSearch.types.length === 0
    }
    return currentSearch.types?.includes(type)
  }

  // Initial loading from query params
  useEffect(() => {
    const types = currentSearch.types
    if (types && Array.isArray(types)) {
      const initialSelectedValues = types.reduce((acc, id) => {
        acc[id] = id
        return acc
      }, {} as SelectedValues)
      setSelectedValues(initialSelectedValues)
    } else {
      setSelectedValues({})
    }
  }, [currentSearch])

  return (
    <ButtonGroup data-testid='company-type-button-group' color='inherit' orientation='horizontal' variant='outlined'>
      <Button
        appearance='outlined'
        className={`rounded-none rounded-l-md border-gray-400 uppercase text-gray-700 ${isSelected(FilterType.All) ? "bg-gray-300" : ""}`}
        onClick={() => handleButtonClick(FilterType.All)}
      >
        ALL
      </Button>
      <Button
        appearance='outlined'
        className={`rounded-none border-l-0 border-gray-400 uppercase text-gray-700 ${isSelected(FilterType.CommodityVendor) ? "bg-gray-300" : ""}`}
        onClick={() => handleButtonClick(FilterType.CommodityVendor)}
      >
        SUPPLIERS
      </Button>
      <Button
        appearance='outlined'
        className={`rounded-none border-l-0 border-gray-400 uppercase text-gray-700 ${isSelected(FilterType.Customer) ? "bg-gray-300" : ""}`}
        onClick={() => handleButtonClick(FilterType.Customer)}
      >
        CUSTOMERS
      </Button>
      <Button
        appearance='outlined'
        className='rounded-none rounded-r-md border-l-0 border-gray-400 text-gray-700'
        onClick={handleDropdownClick}
      >
        <ArrowDropDownOutlined />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={isDropdownOpen}
        onClose={handleDropdownClose}
        slotProps={{
          paper: {
            style: { maxHeight: 400 },
          },
        }}
      >
        {fetching ? (
          <LinearProgress />
        ) : (
          <SimpleTreeView
            slots={{
              collapseIcon: ExpandLessOutlined,
              expandIcon: ExpandMoreOutlined,
            }}
            checkboxSelection
            multiSelect
            onSelectedItemsChange={handleCheckboxChange}
            selectedItems={Object.keys(selectedValues)}
          >
            {renderTreeItems({ nodes: companyTypes, selectedValues })}
          </SimpleTreeView>
        )}
      </Menu>
    </ButtonGroup>
  )
}
