import { Box, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material"
import { KeyboardArrowDownOutlined } from "@mui-symbols-material/w300"
import { type FC, useRef } from "react"
import { Controller, useFormContext } from "react-hook-form"

import type { AddProductModalType } from "./AddProductSchema"
import ProductShipLocationSelect from "./ProductShipLocationSelect"

import { useAccountManagers } from "@/hooks/useAccountManagers"
import { useScrollLoadMore } from "@/hooks/useScrollLoadMore.ts"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuStyleProps = {
  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
  width: 250,
}

const InputFieldClasses = "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2"

interface ProductManagerValue {
  id: string
  name: string
}

interface FormFieldProps {
  name: keyof AddProductModalType
  label: string
  placeholder: string
  required?: boolean
}

const FormTextField: FC<FormFieldProps> = ({ name, label, placeholder, required }) => {
  const { control } = useFormContext<AddProductModalType>()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Box className='relative'>
          <label className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>
            {label}
            {required && <span className='text-red-600'>*</span>}
          </label>
          <TextField
            {...field}
            InputProps={{
              classes: {
                input: InputFieldClasses,
              },
            }}
            error={!!error}
            placeholder={placeholder}
            fullWidth
            variant='outlined'
          />
          {error?.message && <span className='text-sm text-red-600'>{error?.message}</span>}
        </Box>
      )}
    />
  )
}

const ProductManagerSelect: FC = () => {
  const { control } = useFormContext<AddProductModalType>()
  const { visibleManagers, fetching, loadMore } = useAccountManagers()
  const productManagerRef = useRef<HTMLDivElement>(null)

  const onScroll = useScrollLoadMore({
    fetching,
    loadMore,
    scrollContainerRef: productManagerRef,
  })

  return (
    <Controller<AddProductModalType>
      name='productManager'
      control={control}
      render={({ field: { value, onChange, ...field }, fieldState: { error } }) => {
        const productManagerValue = value as ProductManagerValue
        return (
          <Box className='relative'>
            <label className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>
              Product Manager<span className='text-red-600'>*</span>
            </label>
            <Select
              {...field}
              displayEmpty
              input={<OutlinedInput />}
              SelectDisplayProps={{
                className:
                  "content-center h-6 px-4 py-2 focus:border-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-gray-200 text-sm leading-5",
              }}
              classes={{
                icon: "text-gray-600",
              }}
              IconComponent={KeyboardArrowDownOutlined}
              onScrollCapture={onScroll}
              MenuProps={{
                PaperProps: {
                  ref: productManagerRef,
                  style: MenuStyleProps,
                },
              }}
              inputProps={{ "aria-label": "Without label" }}
              variant='outlined'
              fullWidth
              onChange={(event) => {
                const manager = visibleManagers.find(({ accountManagerId }) => accountManagerId === event.target.value)
                if (manager) {
                  onChange({
                    id: manager.accountManagerId,
                    name: `${manager.firstName} ${manager.lastName}`.trim(),
                  })
                }
              }}
              value={productManagerValue?.id || ""}
              renderValue={(selected) => {
                if (!selected || selected === "") {
                  return <Typography color='textSecondary'>Select Product Manager</Typography>
                }
                const manager = visibleManagers.find(({ accountManagerId }) => accountManagerId === selected)
                return manager ? (
                  <Typography key={`selected-${manager.accountManagerId}`}>
                    {`${manager.firstName} ${manager.lastName}`}
                  </Typography>
                ) : productManagerValue?.name ? (
                  <Typography key={`selected-${productManagerValue.id}`}>{productManagerValue.name}</Typography>
                ) : (
                  <Typography color='textSecondary'>Select Product Manager</Typography>
                )
              }}
            >
              {visibleManagers.map(({ accountManagerId, firstName, lastName }) => (
                <MenuItem key={accountManagerId} value={accountManagerId}>
                  {`${firstName} ${lastName}`}
                </MenuItem>
              ))}
            </Select>
            {error?.message && <span className='text-sm text-red-600'>{error?.message}</span>}
          </Box>
        )
      }}
    />
  )
}

export const AddProductInformation: FC = () => {
  const { control } = useFormContext<AddProductModalType>()

  return (
    <form>
      <Typography variant='body1' fontWeight={700} className='leading-6 tracking-[0.15px] text-gray-700'>
        1. Product information
      </Typography>
      <Box className='space-y-10'>
        <FormTextField name='vendorSku' label='Vendor Product SKU' placeholder='Add SKU' />
        <FormTextField
          name='vendorDescription'
          label='Vendor Description'
          placeholder='Add Vendor Product Description'
          required
        />
        <FormTextField
          name='companyProductDescription'
          label='Akrochem Description'
          placeholder='Add Akrochem Product Description'
          required
        />
        <ProductManagerSelect />
        <Controller
          name='locationsAssociations'
          control={control}
          render={() => <ProductShipLocationSelect name='locationsAssociations' control={control} />}
        />
      </Box>
    </form>
  )
}
