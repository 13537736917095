import type { ApiInstructionFormat, BaseInstruction, InstructionGroup } from "../types"

import type {
  ApplicableToEnum,
  IncludeWithEnum,
  RelatedProductInstruction,
  TransportationModeEnum,
} from "@/graphql/codegen/graphql"

// Define a type alias for the input type
type Normalizable<T> = T | T[] | null | undefined

const extractBaseInstruction = (instruction: RelatedProductInstruction): Partial<BaseInstruction> => ({
  groupId: instruction.groupId || "",
  applicableTo: instruction.applicableTo ?? undefined,
  transportationMode: instruction.transportationMode ?? undefined,
  includedWith: instruction.includedWith ?? undefined,
  productInstructionId: instruction.productInstructionId || "",
})

const isValidBaseInstruction = (instruction: Partial<BaseInstruction>): instruction is BaseInstruction => {
  return Boolean(
    instruction.groupId &&
      instruction.applicableTo &&
      instruction.transportationMode &&
      instruction.includedWith &&
      instruction.productInstructionId
  )
}

type InstructionCombination = {
  productInstructionId?: string
  applicableTo?: ApplicableToEnum | ApplicableToEnum[] | null
  transportationMode?: TransportationModeEnum | TransportationModeEnum[] | null
  includedWith?: IncludeWithEnum | IncludeWithEnum[] | null
}

/**
 * Checks if two instruction combinations are duplicates
 * A duplicate is defined as having the same combination of:
 * - applicableTo
 * - transportationMode
 * - includedWith
 */
export const hasDuplicateInstructions = (
  newCombination: InstructionCombination,
  existingCombination: InstructionCombination
): boolean => {
  if (!newCombination || !existingCombination) {
    return false
  }

  // Helper to normalize arrays for comparison
  const normalizeArray = <T>(value: Normalizable<T>): T[] => {
    if (!value) return []
    const arr = Array.isArray(value) ? value : [value]
    return [...arr].sort((a, b) => String(a).localeCompare(String(b)))
  }

  // Helper to check if arrays are exactly equal
  const areArraysEqual = <T>(arr1: T | T[] | null | undefined, arr2: T | T[] | null | undefined): boolean => {
    if (!arr1 || !arr2) return false
    const normalized1 = normalizeArray(arr1)
    const normalized2 = normalizeArray(arr2)

    if (normalized1.length !== normalized2.length) return false
    return normalized1.every((val, idx) => val === normalized2[idx])
  }

  // Compare each property for exact matches
  return (
    areArraysEqual(newCombination.applicableTo, existingCombination.applicableTo) &&
    areArraysEqual(newCombination.transportationMode, existingCombination.transportationMode) &&
    areArraysEqual(newCombination.includedWith, existingCombination.includedWith)
  )
}

export const formatInstructionsForApi = (groups: InstructionGroup[]): ApiInstructionFormat[] => {
  return groups.flatMap((group) =>
    group.instructions
      .map((instruction) => {
        const baseInstruction = extractBaseInstruction(instruction)
        return isValidBaseInstruction(baseInstruction)
          ? {
              groupId: group.groupId, // Use group's ID instead of instruction's
              applicableTo: baseInstruction.applicableTo,
              transportationMode: baseInstruction.transportationMode,
              includedWith: baseInstruction.includedWith,
              productInstructionId: baseInstruction.productInstructionId,
            }
          : null
      })
      .filter((instruction): instruction is ApiInstructionFormat => instruction !== null)
  )
}

export const groupInstructions = (instructions: RelatedProductInstruction[]): InstructionGroup[] => {
  // First filter out invalid instructions
  const validInstructions = instructions.filter((instruction) => {
    const baseInstruction = extractBaseInstruction(instruction)
    return isValidBaseInstruction(baseInstruction)
  })

  // Group solely by groupId for rendering
  const groupedByGroupId = validInstructions.reduce<Record<string, RelatedProductInstruction[]>>((acc, instruction) => {
    // Always use the instruction's groupId - this is what determines the grouping
    const groupId = instruction.groupId || ""

    if (!acc[groupId]) {
      acc[groupId] = []
    }
    acc[groupId].push(instruction)
    return acc
  }, {})

  // Convert to InstructionGroup[] format
  return Object.entries(groupedByGroupId).map(([groupId, groupInstructions]) => {
    // Use properties from the first instruction in the group
    const baseInstruction = extractBaseInstruction(groupInstructions[0])
    return {
      groupId,
      instructions: groupInstructions,
      properties: {
        applicableTo: baseInstruction.applicableTo as ApplicableToEnum,
        transportationMode: baseInstruction.transportationMode as TransportationModeEnum,
        includedWith: baseInstruction.includedWith as IncludeWithEnum,
      },
    }
  })
}

// Delete entire group by groupId
export const deleteInstructionGroup = (groups: InstructionGroup[], groupId: string): InstructionGroup[] => {
  return groups.filter((group) => group.groupId !== groupId)
}

/**
 * Converts an array of RelatedProductInstruction to InstructionCombination[]
 * Groups instructions by their groupId and merges their properties into arrays
 */
export const getInstructionCombinations = (instructions: RelatedProductInstruction[]): InstructionCombination[] => {
  const groups = groupInstructions(instructions)

  return groups.map((group) => {
    const properties = ["applicableTo", "transportationMode", "includedWith"] as const

    const mergedProperties = properties.reduce((acc, prop) => {
      const values = [...new Set(group.instructions.map((inst) => inst[prop]).filter(Boolean))]
      return {
        ...acc,
        [prop]: values.length === 1 ? values[0] : values,
      }
    }, {} as InstructionCombination)

    return mergedProperties
  })
}
