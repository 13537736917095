import type { Client } from "urql"
import { create } from "zustand"

import {
  type Product,
  ProductListDocument,
  type ProductListQuery,
  ProductSortField,
  SortingOrder,
} from "@/graphql/codegen/graphql.ts"
import { queryClient } from "@/providers/GraphqlRouterProvider"
import { type SearchFilters, type SearchStore, createSearchStore } from "@/stores/createSearchStore"

export type ProductSearchFilters = SearchFilters & {
  category?: string
  field?: ProductSortField
  order?: SortingOrder
  companyId: string
  expanded?: boolean
}

type ProductSearchStoreState = SearchStore<Product, ProductSearchFilters> & {
  setCompanyId: (companyId: string) => void
  clearStore: () => void
  companyId: string
}

const searchStore = createSearchStore<Product, ProductSearchFilters, ProductListQuery>({
  documentNode: ProductListDocument,
  getQueryInput: (filters: ProductSearchFilters, offset: number, limit: number) => {
    const { category, field, order, companyId, expanded, ...otherFilters } = filters
    return {
      filter: { ...otherFilters, companyId },
      offset,
      limit,
      sort: { field: field ?? ProductSortField.CreatedOn, order: order ?? SortingOrder.Desc },
    }
  },
  getQueryVariables: (queryInput) => ({
    input: queryInput,
    skip: false,
  }),
  extractDataFromResponse: (data) => ({
    __typename: data.product.list.__typename,
    data: data.product.list.__typename === "ProductListSuccess" ? (data.product.list.products as Product[]) : [],
    error: data.product.list.__typename === "ProductListFailure" ? data.product.list.error : undefined,
  }),
  requestPolicy: "network-only",
})

export const useProductSearchStore = create<ProductSearchStoreState>()((set, get, api) => {
  const store = searchStore(set, get, api)
  return {
    ...store,
    companyId: "",
    setCompanyId: (companyId: string) => {
      if (!companyId) {
        return
      }
      set({ companyId })
      void store.executeSearch(queryClient, { companyId })
    },
    executeSearch: async (client: Client, filters: Partial<ProductSearchFilters>, skipFilterCheck?: boolean) => {
      const { companyId } = get()
      if (!companyId) {
        return
      }
      return store.executeSearch(client, { ...filters, companyId }, skipFilterCheck)
    },
    clearStore: () =>
      set({
        data: [],
        fetching: false,
        error: undefined,
        offset: 0,
        hasMore: true,
        lastExecutedFilters: undefined,
        companyId: "",
      }),
  }
})
