import type { ContactRole } from "@/graphql/codegen/graphql.ts"
import type {
  SelectedLocationRole,
  UIFormData,
  UILocationRoles,
} from "@/screens/Contacts/edit/components/LocationRolesDialog.tsx"

export const convertToUIFormat = (selectedLocationRoles: SelectedLocationRole[]): UIFormData => {
  const mapByLocation = new Map<string, string[]>()

  for (const { locationId, role } of selectedLocationRoles) {
    if (!mapByLocation.has(locationId)) {
      mapByLocation.set(locationId, [])
    }
    mapByLocation.get(locationId)!.push(role)
  }

  const locationRoles: UILocationRoles[] = []
  mapByLocation.forEach((rolesArr, locId) => {
    locationRoles.push({
      locationId: locId,
      roles: rolesArr,
    })
  })

  if (locationRoles.length === 0) {
    locationRoles.push({
      locationId: "",
      roles: [],
    })
  }

  return { locationRoles }
}

export const convertToBackendFormat = (uiData: UIFormData): SelectedLocationRole[] => {
  const result: SelectedLocationRole[] = []
  uiData.locationRoles.forEach((locBlock) => {
    const { locationId, roles } = locBlock
    roles.forEach((r) => {
      result.push({
        locationId,
        role: r as ContactRole,
        contactLocationId: "",
      })
    })
  })
  return result
}
