import { useMutation } from "urql"

import {
  type CompanyMutationUpdateArgs,
  CompanyStatus,
  type CompanyUpdateFailure,
  type CompanyUpdateInput,
  type UpdateNewCompanyMutationMutation,
} from "@/graphql/codegen/graphql"
import { UpdateNewCompanyMutationDocument } from "@/graphql/codegen/graphql"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

interface UpdateCompanyStatusInput {
  companyId: string
  status: CompanyStatus
  locationsCount: number
  types: string[]
}

type UseCompanyStatusUpdateReturn = {
  updateCompanyStatus: (props: UpdateCompanyStatusInput) => Promise<{ success: boolean; noLocations?: boolean }>
  isUpdating: boolean
}

export const useCompanyStatusUpdate = (): UseCompanyStatusUpdateReturn => {
  const [{ fetching }, updateCompany] = useMutation<UpdateNewCompanyMutationMutation, CompanyMutationUpdateArgs>(
    UpdateNewCompanyMutationDocument
  )
  const triggerNotification = useNotificationsStore((store) => store.enqueueNotification)

  const updateCompanyStatus = async ({ companyId, status, locationsCount, types }: UpdateCompanyStatusInput) => {
    if (status === CompanyStatus.Active && locationsCount === 0) {
      return { success: false, noLocations: true }
    }

    const input: CompanyUpdateInput = {
      companyId,
      status,
      types,
    }

    const { data, error } = await updateCompany({
      input,
    })

    if (error) {
      triggerNotification({
        type: "error",
        message: error.message,
      })
      return { success: false }
    }

    if (!data?.company?.update || data.company.update.__typename === "CompanyUpdateFailure") {
      const failureResponse = data?.company?.update as CompanyUpdateFailure
      triggerNotification({
        type: "error",
        message: failureResponse?.error.message || "Failed to update company status",
      })
      return { success: false }
    }

    if (data.company.update.__typename === "CompanyUpdateSuccess") {
      triggerNotification({
        type: "success",
        message: `Company status successfully updated to ${status.toLowerCase()}`,
      })
      return { success: true }
    }

    return { success: false }
  }

  return {
    updateCompanyStatus,
    isUpdating: fetching,
  }
}
