import { create } from "zustand"

import {
  type Company,
  type CompanySortField,
  type Location,
  type SortingOrder,
  SupplierListDocument,
  type SupplierListQuery,
} from "@/graphql/codegen/graphql.ts"
import type { CompanySearchFilters } from "@/screens/Companies/stores/useCompanySearchStore"
import { type SearchFilters, createSearchStore } from "@/stores/createSearchStore"

type CompanyWithLocation = Company & { currentLocation?: Location }

export type SupplierSearchFilters = SearchFilters & {
  category?: string
  field?: CompanySortField
  order?: SortingOrder
}

const processCompanyData = (companies: Company[]): CompanyWithLocation[] => {
  return companies.flatMap((company) => {
    if (company.locationsAssociations && company.locationsAssociations.length > 0) {
      return company.locationsAssociations.map((location) => ({
        ...company,
        currentLocation: location,
      }))
    }
    return [company]
  })
}

const deduplicateCompanies = (
  newData: CompanyWithLocation[],
  existingData: CompanyWithLocation[] = []
): CompanyWithLocation[] => {
  // Create a map of existing companies to avoid duplicates
  const uniqueCompanies: Record<string, CompanyWithLocation> = {}

  // First, add existing data to the map
  existingData.forEach((company) => {
    const key = company.companyId + "-" + (company.currentLocation?.locationId || "default")
    uniqueCompanies[key] = company
  })

  // Then add new data, overwriting any duplicates
  newData.forEach((company) => {
    const key = company.companyId + "-" + (company.currentLocation?.locationId || "default")
    uniqueCompanies[key] = company
  })

  // Return all unique companies
  return Object.values(uniqueCompanies)
}

export const useSupplierReportSearchStore = create(
  createSearchStore<CompanyWithLocation, SupplierSearchFilters, SupplierListQuery>({
    documentNode: SupplierListDocument,
    deduplicateData: deduplicateCompanies,
    processData: processCompanyData,
    getQueryInput: (filters: CompanySearchFilters, offset: number, limit: number) => {
      const { category, field, order, ...otherFilters } = filters
      return {
        filter: otherFilters,
        offset,
        limit,
        sort: { field, order },
      }
    },
    getQueryVariables: (queryInput) => ({ input: queryInput }),
    extractDataFromResponse: (data) => ({
      __typename: data?.company?.list?.__typename,
      data: data?.company?.list?.__typename === "CompanyListSuccess" ? (data.company.list.companies as Company[]) : [],
      error: data?.company?.list?.__typename === "CompanyListFailure" ? data.company.list.error : undefined,
    }),
  })
)
