import { IconButton, Tooltip } from "@mui/material"
import { MoreHorizOutlined, VisibilityOutlined } from "@mui-symbols-material/w300"
import { Link } from "@tanstack/react-router"
import type { CellContext, ColumnDef } from "@tanstack/react-table"
import React, { useMemo } from "react"
import type { ReactElement } from "react"

import Chip from "@/components/common/Chip/Chip.tsx"
import LimitedSizeChipGroup from "@/components/common/Chip/LimitedSizeChipGroup.tsx"
import { StatusIndicator } from "@/components/common/StatusIndicator/StatusIndicator.tsx"
import { CompanyStatus, ContactSortField } from "@/graphql/codegen/graphql.ts"
import type { CompanyDepartment, ContactSubscription } from "@/graphql/codegen/graphql.ts"
import { createStatusMap } from "@/utils/statusMap.ts"

export type ContactSearchResult = {
  __typename?: "Contact"
  contactId?: string | null
  companyId?: string | null
  firstName?: string | null
  lastName?: string | null
  companyDepartment?: CompanyDepartment | null
  company?: { __typename: "Company"; companyId: string; name?: string | null } | null
  contactSubscriptions: Array<{ __typename: "ContactSubscription"; name: string } | null>
  isActive?: boolean | null
}

export const companyStatusMap = createStatusMap<
  CompanyStatus,
  "primary" | "secondary" | "default" | "error" | "info" | "success" | "warning"
>([
  [CompanyStatus.Active, "success"],
  [CompanyStatus.Pending, "warning"],
  [CompanyStatus.Rejected, "error"],
  [CompanyStatus.Inactive, "secondary"],
])

export type SortableAccessorKey = "company_name" | "isActive"

export const accessorKeyToContactSortField: Map<SortableAccessorKey, ContactSortField> = new Map([
  ["company_name", ContactSortField.CompanyName],
  ["isActive", ContactSortField.IsActive],
])

export const contactSortFieldToAccessoryKey: Map<ContactSortField, SortableAccessorKey> = new Map([
  [ContactSortField.CompanyName, "company_name"],
  [ContactSortField.IsActive, "isActive"],
])

const MoreChip = (hiddenChipsCount: number): ReactElement => (
  <Tooltip title={`${hiddenChipsCount} more items hidden`}>
    <div className='chip'>
      <Chip icon={<MoreHorizOutlined />} />
    </div>
  </Tooltip>
)

const SubscriptionCell: React.FC<{ types: ContactSubscription[] }> = ({ types }) => {
  const chips = useMemo(() => types.map((type, index) => ({ id: `${type}-${index}`, name: type.name })), [types])

  return (
    <LimitedSizeChipGroup
      chips={chips}
      maxSize={400}
      renderChip={(chip) => (
        <Chip
          className='mr-2 rounded bg-tertiary-yellow-300'
          key={chip.id}
          label={chip.name}
          icon={chip?.icon}
          color={"warning"}
          style={{ maxWidth: chip?.maxWidth ?? "auto" }}
        />
      )}
      renderMoreChip={MoreChip}
    />
  )
}

export const columns: ColumnDef<ContactSearchResult>[] = [
  {
    id: "isActive",
    accessorKey: "isActive",
    header: "",
    sortingFn: "alphanumeric",
    enableSorting: true,
    cell: ({ cell }: CellContext<ContactSearchResult, unknown>): ReactElement => (
      <StatusIndicator
        data-testid={`${cell.id}-${cell.row.original.isActive}`}
        status={cell.row.original.isActive ? CompanyStatus.Active : CompanyStatus.Inactive}
        map={companyStatusMap}
      />
    ),
    size: 24,
  },
  {
    id: "company_name",
    accessorKey: "company.name",
    header: "Company",
    sortingFn: "alphanumeric",
    enableSorting: true,
  },
  {
    id: "contact_name",
    accessorKey: "",
    header: "Contact",
    cell: ({ row }: { row: { original: ContactSearchResult } }): string => {
      const { firstName, lastName } = row.original
      return `${firstName ?? ""} ${lastName ?? ""}`
    },
    enableSorting: false,
  },
  {
    id: "department",
    accessorKey: "companyDepartment",
    header: "Department",
    enableSorting: false,
  },
  {
    id: "subscriptions",
    accessorKey: "contactSubscriptions",
    header: "Subscriptions",
    cell: ({ row }: { row: { original: ContactSearchResult } }): ReactElement => (
      <SubscriptionCell types={row.original.contactSubscriptions.filter(Boolean) as ContactSubscription[]} />
    ),
    enableSorting: false,
  },
  {
    id: "actions",
    accessorKey: "",
    header: "",
    cell: ({ row }: { row: { original: ContactSearchResult } }): ReactElement => (
      <div className='flex justify-end'>
        <Link to={`/companies/contacts/${row.original.contactId}`}>
          <IconButton>
            <VisibilityOutlined />
          </IconButton>
        </Link>
      </div>
    ),
    enableSorting: false,
    size: 48,
  },
]
