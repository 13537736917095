import { create } from "zustand"

import {
  GetProductListWithOrdersDocument,
  OrderSortField,
  ProductSortField,
  SortingOrder,
} from "@/graphql/codegen/graphql.ts"
import type {
  GetProductListWithOrdersQuery,
  Order,
  OrderLine,
  OrderListInput,
  Product,
  ProductListInput,
} from "@/graphql/codegen/graphql.ts"
import {
  orderSortFieldToAccessoryKey,
  productOrderSortFieldToAccessoryKey,
} from "@/screens/Procurement/PurchaseOrders/types"
import type { SearchFilters } from "@/stores/createSearchStore"
import { createSearchStore } from "@/stores/createSearchStore"

// Types
export type ProductWithOrder = Omit<Product, "__typename"> &
  Omit<Order, "__typename"> &
  Omit<OrderLine, "__typename"> & {
    __typename: string
  }

export type ProductPurchaseOrderSearchFilters = SearchFilters & {
  category?: string
  field?: OrderSortField | ProductSortField
  order?: SortingOrder
  start?: string
  end?: string
}

// Constants
const PRODUCT_LIMIT = 10
const ORDER_LIMIT = 50

// Helper functions
const isProductSortField = (value: unknown): value is ProductSortField =>
  typeof value === "string" && productOrderSortFieldToAccessoryKey.has(value as ProductSortField)

const isOrderSortField = (value: unknown): value is OrderSortField =>
  typeof value === "string" && orderSortFieldToAccessoryKey.has(value as OrderSortField)

const createQueryInput = (
  filters: ProductPurchaseOrderSearchFilters,
  offset: number,
  limit: number
): { input: ProductListInput; orderInput: OrderListInput } => {
  const { category, field, order, start, end, query, ...otherFilters } = filters

  return {
    input: {
      filter: { hasOrders: true, query },
      offset,
      limit,
      sort: {
        field: isProductSortField(field) ? field : ProductSortField.CreatedOn,
        order: order ?? SortingOrder.Desc,
      },
    },
    orderInput: {
      filter: {
        ...otherFilters,
        ...(start && {
          sentDateRange: {
            start,
            end,
          },
        }),
      },
      offset: 0,
      limit: ORDER_LIMIT,
      sort: {
        field: isOrderSortField(field) ? field : OrderSortField.UpdatedOn,
        order,
      },
    },
  }
}

const extractProductsWithOrders = (data: GetProductListWithOrdersQuery): ProductWithOrder[] => {
  if (data?.product?.list?.__typename !== "ProductListSuccess") return []

  return (data.product.list.products ?? []).flatMap((product) =>
    product?.orders?.__typename === "OrderListSuccess"
      ? product.orders.orders.map(
          (order) =>
            ({
              ...product,
              ...order,
              ...order.orderLines?.[0],
              __typename: "Product",
            }) as ProductWithOrder
        )
      : []
  )
}

const checkHasMoreData = (response: { data: ProductWithOrder[] }, state: { limit: number }) => {
  // Since we're flattening products with their orders,
  // we need to check if we got a full page of products
  const productsCount = new Set(response.data.map((item: ProductWithOrder) => item.productId)).size
  return productsCount === state.limit
}

// Store creation
export const useProductPurchaseOrdersSearchStore = create(
  createSearchStore<
    ProductWithOrder,
    ProductPurchaseOrderSearchFilters,
    GetProductListWithOrdersQuery,
    { input: ProductListInput; orderInput: OrderListInput }
  >({
    documentNode: GetProductListWithOrdersDocument,
    initialState: {
      limit: PRODUCT_LIMIT,
    },
    getQueryInput: createQueryInput,
    getQueryVariables: (queryInput) =>
      queryInput as unknown as { input: { input: ProductListInput; orderInput: OrderListInput } },
    extractDataFromResponse: (data) => ({
      __typename: data?.product?.list?.__typename ?? "ProductListFailure",
      data: extractProductsWithOrders(data),
      error: data?.product?.list?.__typename === "ProductListFailure" ? data.product.list.error : undefined,
    }),
    hasMoreData: checkHasMoreData,
  })
)
