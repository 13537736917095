import { create } from "zustand"

import {
  type Company,
  CompanyListDetailsDocument,
  type CompanyListDetailsQuery,
  type CompanySortField,
  type SortingOrder,
} from "@/graphql/codegen/graphql.ts"
import { type SearchFilters, createSearchStore } from "@/stores/createSearchStore"

export type CompanySearchFilters = SearchFilters & {
  category?: string
  field?: CompanySortField
  order?: SortingOrder
}

const deduplicateCompanies = (newData: Company[], existingData: Company[]): Company[] => {
  const uniqueIds = new Set(existingData.map((company) => company.companyId))
  return [...existingData, ...newData.filter((company) => !uniqueIds.has(company.companyId))]
}

export const useCompanySearchStore = create(
  createSearchStore<Company, CompanySearchFilters, CompanyListDetailsQuery>({
    documentNode: CompanyListDetailsDocument,
    deduplicateData: deduplicateCompanies,
    getQueryInput: (filters: CompanySearchFilters, offset: number, limit: number) => {
      const { category, field, order, ...otherFilters } = filters
      return {
        filter: otherFilters,
        offset,
        limit,
        sort: { field, order },
      }
    },
    getQueryVariables: (queryInput) => ({ input: queryInput }),
    extractDataFromResponse: (data) => ({
      __typename: data.company.list.__typename,
      data: data.company.list.__typename === "CompanyListSuccess" ? (data.company.list.companies as Company[]) : [],
      error: data.company.list.__typename === "CompanyListFailure" ? data.company.list.error : undefined,
    }),
  })
)
