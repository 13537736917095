import type { InputBaseProps } from "@mui/material"
import { Box } from "@mui/material"
import { MuiTelInput } from "mui-tel-input"
import { forwardRef } from "react"
import type { Control, FieldPath, FieldValues } from "react-hook-form"
import { Controller } from "react-hook-form"
import { twMerge } from "tailwind-merge"

type PhoneNumberInputProps<TFieldValues extends FieldValues = FieldValues> = {
  control: Control<TFieldValues>
  name: FieldPath<TFieldValues>
  label: string
  required?: boolean
  containerProps?: React.ComponentProps<typeof Box>
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>
  inputProps?: InputBaseProps["inputProps"]
}

export const PhoneNumberInput = forwardRef<HTMLInputElement, PhoneNumberInputProps>(
  ({ control, name, label, required = false, containerProps = {}, labelProps = {}, inputProps = {} }, ref) => {
    return (
      <Controller
        name={name}
        control={control}
        rules={{
          required: required ? "Phone number is required" : false,
          validate: (value) => {
            if (!value) return true
            return value.trim() !== "" || "Phone number is invalid"
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <Box className='relative w-full' {...containerProps}>
            <label
              className='absolute -top-6 mb-[7px] block text-sm font-thin text-gray-800'
              htmlFor={field.name}
              {...labelProps}
            >
              {label} {required && <span className='text-red-600'>*</span>}
            </label>
            <MuiTelInput
              id={field.name}
              {...field}
              preferredCountries={["US", "CA", "IN", "CN", "GB", "IT", "MY", "AX", "KR", "MX", "AL"]}
              forceCallingCode
              defaultCountry='US'
              error={!!error}
              helperText={error?.message}
              fullWidth
              inputRef={ref}
              inputProps={{
                ...inputProps,
                className: twMerge(
                  "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2",
                  inputProps.className ?? ""
                ),
              }}
              onChange={field.onChange}
            />
          </Box>
        )}
      />
    )
  }
)

PhoneNumberInput.displayName = "PhoneNumberInput"
