import { Box } from "@mui/material"
import type { FC } from "react"
import { memo, useCallback, useEffect } from "react"
import { useFormContext } from "react-hook-form"

import AutoComplete from "@/components/common/Autocomplete/AutoComplete"
import { Button } from "@/components/common/Button"
import { Form } from "@/components/Forms"
import type { AccountManager } from "@/graphql/codegen/graphql"
import AccountManagerSelect from "@/screens/Companies/components/AccountManagerSelect"
import CompanyTypeSelect from "@/screens/Companies/components/CompanyTypeSelect"
import type { TreeNode } from "@/types/tree"

interface Props {
  companyNameList: string[]
  companyTypeList: TreeNode<string>[]
  accountsManagerList: AccountManager[]
  step: number
  onChange: (name: string) => void
  handleCancel: () => void
  handleNextStep: () => void
  onSubmit: () => void
}

const CreateNewCompanyForm: FC<Props> = ({
  companyNameList,
  companyTypeList,
  accountsManagerList,
  step,
  onChange,
  handleCancel,
  handleNextStep,
  onSubmit,
}) => {
  const {
    formState: { errors },
    watch,
    resetField,
    trigger,
    control,
  } = useFormContext()

  const companyName = watch("name")
  const types = watch("types")

  const handleCompanyNameChange = useCallback(
    (value: string) => {
      onChange(value)
      if (!value?.trim()) {
        resetField("types")
        resetField("accountManagers")
      }
    },
    [onChange, resetField]
  )

  useEffect(() => {
    if (!companyName?.trim()) {
      resetField("types")
      resetField("accountManagers")
    }
  }, [companyName, resetField])

  useEffect(() => {
    // Trigger validation when company name changes
    if (companyName?.trim()) {
      trigger("name")
    }
  }, [companyName, trigger])

  const isNameInvalid = !companyName?.trim() || !!errors.name
  const isStep2Invalid = isNameInvalid || !types?.length || !!errors.types

  const showCompanyType = step !== 1 && companyName?.trim()
  const showAccountManager = step === 2

  return (
    <Form className='gap-4 p-10' onSubmit={(e) => e.preventDefault()}>
      <Box>
        <AutoComplete
          onInputChange={handleCompanyNameChange}
          label='Company Name'
          placeholder='Company Name'
          id='company-name-field'
          options={companyNameList}
          name='name'
          control={control}
          required
        />
      </Box>
      {showCompanyType && (
        <Box>
          <CompanyTypeSelect companyTypeTree={companyTypeList} name='types' />
        </Box>
      )}
      {showAccountManager && (
        <Box>
          <AccountManagerSelect accountManagers={accountsManagerList} name='accountManagers' />
        </Box>
      )}
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Button appearance='outlined' onClick={handleCancel}>
          Cancel
        </Button>
        {step === 1 && (
          <Button appearance='contained' onClick={handleNextStep} disabled={isNameInvalid}>
            Continue
          </Button>
        )}
        {step === 2 && (
          <Button appearance='contained' type='submit' onClick={onSubmit} disabled={isStep2Invalid}>
            Submit
          </Button>
        )}
      </Box>
    </Form>
  )
}

export default memo(CreateNewCompanyForm)
