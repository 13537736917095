import { Fade } from "@mui/material"
import { useParams } from "@tanstack/react-router"
import type { ReactElement } from "react"
import { FormProvider } from "react-hook-form"

import InstructionsModal from "./AddInstructionsModal"
import { InstructionsContent } from "./components/InstructionsContent"
import { InstructionsHeader } from "./components/InstructionsHeader"
import { InstructionsLoading } from "./components/InstructionsLoading"
import { useInstructionModal } from "./hooks/useInstructionModal"
import { useInstructions } from "./hooks/useInstructions"
import type { FormValues } from "./types"

import { SectionContainer } from "@/components/SectionContainer/SectionContainer"
import type { RelatedProductInstruction } from "@/graphql/codegen/graphql"

/**
 * Container component for managing product instructions.
 * Handles the orchestration of instruction management operations and UI state.
 */
export const InstructionsContainer = (): ReactElement => {
  const { productId } = useParams({ strict: false })

  // Instructions state and operations
  const { instructions, isLoading, createInstructionsFromFormData, updateInstructions, deleteInstructionGroup } =
    useInstructions({
      productId: Number(productId),
      onError: (message: string) => {
        throw new Error(message)
      },
    })

  // Modal state and operations
  const { modal, methods, openAddModal, openEditModal, closeModal } = useInstructionModal({
    instructions,
  })

  // Form submission handler
  const handleSubmit = async (formData: FormValues) => {
    const newInstructions = createInstructionsFromFormData(formData)

    // Get current instructions excluding edited group if editing
    const currentInstructions =
      modal.type === "edit"
        ? instructions.filter((inst: RelatedProductInstruction) => inst.groupId !== formData.groupId)
        : instructions

    const success = await updateInstructions(currentInstructions, newInstructions, modal.type === "edit")

    if (success) {
      closeModal()
    }
  }

  if (isLoading) {
    return <InstructionsLoading />
  }

  const hasInstructions = instructions.length > 0

  return (
    <FormProvider {...methods}>
      <SectionContainer className='rounded-md border border-solid border-gray-300'>
        <Fade in>
          <div className='flex flex-col gap-6'>
            <InstructionsHeader hasInstructions={hasInstructions} onAddClick={openAddModal} />
            <InstructionsContent
              instructions={instructions}
              onAddInstruction={openAddModal}
              onEditInstruction={openEditModal}
              onDeleteInstruction={(instruction) => deleteInstructionGroup(instruction.groupId || "")}
            />
          </div>
        </Fade>
        <InstructionsModal
          isOpen={modal.isOpen}
          type={modal.type}
          onClose={closeModal}
          onSubmit={handleSubmit}
          existingInstructions={instructions}
        />
      </SectionContainer>
    </FormProvider>
  )
}
