import { useCallback, useMemo } from "react"
import type { CombinedError, OperationContext, OperationResult } from "urql"
import { useMutation, useQuery } from "urql"

import type {
  ContactSubscription,
  ContactSubscriptionListSuccess,
  ContactSubscriptionsListSuccess,
  EditContactSubscriptionMutationMutationVariables,
  Mutation,
  Query,
} from "@/graphql/codegen/graphql"
import {
  ContactSubscriptionGetQueryDocument,
  EditContactSubscriptionMutationDocument,
  SubscriptionListQueryDocument,
} from "@/graphql/codegen/graphql"

export const useGetSubscription = (
  contactId: string
): {
  subscriptionList: ContactSubscription[]
  subscriptionListDetailsFetching: boolean
  subscriptionListDetailsError: CombinedError | undefined
  selectedSubscriptionList: ContactSubscription[]
  selectedSubscriptionDetailsFetching: boolean
  selectedSubscriptionDetailsError: CombinedError | undefined
  reexecuteQueryWithReset: () => void
  updateSubscriptions: (
    variables: EditContactSubscriptionMutationMutationVariables,
    context?: Partial<OperationContext>
  ) => Promise<OperationResult<Pick<Mutation, "contactSubscription">, EditContactSubscriptionMutationMutationVariables>>
} => {
  const [
    {
      data: selectedSubscriptionDetails,
      fetching: selectedSubscriptionDetailsFetching,
      error: selectedSubscriptionDetailsError,
    },
    reexecuteSelectedSubscriptionListQuery,
  ] = useQuery<Pick<Query, "contactSubscription">>({
    pause: !contactId,
    query: ContactSubscriptionGetQueryDocument,
    variables: { input: { contactId } },
    requestPolicy: "network-only",
  })

  const [
    { data: subscriptionListDetails, fetching: subscriptionListDetailsFetching, error: subscriptionListDetailsError },
  ] = useQuery<Pick<Query, "contactSubscription">>({
    pause: !contactId,
    query: SubscriptionListQueryDocument,
  })

  // Mutation to update subscriptions
  const [, updateSubscriptions] = useMutation<
    Pick<Mutation, "contactSubscription">,
    EditContactSubscriptionMutationMutationVariables
  >(EditContactSubscriptionMutationDocument)

  const selectedSubscriptionList = useMemo(
    () =>
      (selectedSubscriptionDetails?.contactSubscription.listContactSubscriptions as ContactSubscriptionsListSuccess)
        ?.contactSubscriptions,
    [selectedSubscriptionDetails]
  )

  const reexecuteQueryWithReset = useCallback(() => {
    reexecuteSelectedSubscriptionListQuery({ requestPolicy: "network-only" })
  }, [reexecuteSelectedSubscriptionListQuery])

  const subscriptionList = useMemo(
    () =>
      (subscriptionListDetails?.contactSubscription.listSubscriptions as ContactSubscriptionListSuccess)
        ?.contactSubscriptions,
    [subscriptionListDetails]
  )

  return {
    subscriptionList,
    subscriptionListDetailsFetching,
    subscriptionListDetailsError,
    selectedSubscriptionList,
    selectedSubscriptionDetailsFetching,
    selectedSubscriptionDetailsError,
    reexecuteQueryWithReset,
    updateSubscriptions,
  }
}
