import { cva } from "class-variance-authority"

export const chipVariants = cva("", {
  variants: {
    variant: {
      default: "",
    },
  },
  defaultVariants: {
    variant: "default",
  },
})

export const chipStackVariants = cva("relative flex items-center gap-2", {
  variants: {
    state: {
      default: "",
      error: "text-error-500",
    },
    disabled: {
      true: "text-gray-500",
    },
  },
  defaultVariants: {
    state: "default",
    disabled: false,
  },
})
