import { zodResolver } from "@hookform/resolvers/zod"
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material"
import { InfoOutlined, WarningOutlined } from "@mui-symbols-material/w300"
import { type ReactElement, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"

import { useGetStartPurchaseOrderData } from "../hooks/useGetStartPurchaseOrderData"
import type { PurchaseOrderDialogFormValues, PurchaseOrderDialogProps } from "../types"
import { PurchaseOrderDialogSchema } from "../types"

import { ProductsAdded } from "./ProductsAdded"

import { Button } from "@/components/common/Button"
import { SelectField } from "@/components/common/SelectField.tsx"
import { FieldRow, Form, FormField } from "@/components/Forms"
import type { Product } from "@/graphql/codegen/graphql"
import { formatLocationAddress } from "@/utils/utils"

export const PurchaseOrderDialog = ({
  open,
  fetching,
  onClose,
  products,
  onSubmit,
  company,
}: PurchaseOrderDialogProps): ReactElement => {
  const {
    shipToLocations,
    shipToLocationListFetching,
    loadMore,
    freightTerms,
    incoterms,
    incotermsLocation,
    shippingViaCompanyId,
  } = useGetStartPurchaseOrderData({
    selectedProducts: products,
    selectedCompany: company,
  })

  const methods = useForm<PurchaseOrderDialogFormValues>({
    resolver: zodResolver(PurchaseOrderDialogSchema),
    defaultValues: {
      shipTo: "",
    },
  })

  const handleClose = () => {
    onClose()
    methods.reset()
  }

  const handleFormSubmit = (data: PurchaseOrderDialogFormValues) => {
    onSubmit(
      {
        ...data,
        freightTerms: freightTerms,
        deliveryTerms: incoterms, // TODO: Add Delivery Terms ; Currently blocked by Feature Rework & BE
        shipViaCompanyId: shippingViaCompanyId,
        deliveryLocationId: incotermsLocation?.locationId,
      },
      products
    )
  }

  const shipToOptions = useMemo(() => {
    return shipToLocations.map((location) => ({
      label: formatLocationAddress(location),
      value: location.locationAssociationId,
    }))
  }, [shipToLocations])

  const hasInactiveProducts = useMemo(() => products.some((product) => !product.isActive), [products])

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
      <DialogTitle className='px-[38px] font-normal text-primary'>Start Purchase Order</DialogTitle>
      {fetching ? (
        <CircularProgress size={48} className='mx-auto' />
      ) : (
        <>
          <DialogContent className='px-[38px]'>
            <Box className='space-y-4 rounded-md'>
              <ProductsAdded products={products as unknown as Product[]} />
              {hasInactiveProducts && (
                <Alert severity='warning' icon={<WarningOutlined />} className='mb-4'>
                  <Box className='flex flex-col gap-1'>
                    <Typography variant='body2'>This Purchase Order Contains Inactive Products</Typography>
                  </Box>
                </Alert>
              )}
              <Box className='text-sm text-gray-600'>
                <Box component='p' className='flex h-8 items-center'>
                  <Box component='span' className='text-gray-900'>
                    Supplier:
                  </Box>
                  &nbsp;
                  <Box component='span' className='text-gray-700'>
                    {company?.name || "-"}
                  </Box>
                </Box>
                <Box component='p' className='flex h-8 items-center'>
                  <Box component='span' className='text-gray-900'>
                    Ship From:
                  </Box>
                  &nbsp;
                  <Box component='span' className='text-gray-700'>
                    {products?.length > 0
                      ? products[0]?.locationOrigin?.name ||
                        products[0]?.company?.locationsAssociations?.[0]?.shipping?.name ||
                        "-"
                      : "-"}
                  </Box>
                </Box>
                <Box className='flex h-8 items-center'>
                  <Box component='span' className='text-gray-900'>
                    Incoterms:
                  </Box>
                  &nbsp;
                  <Box component='span' className='text-gray-700'>
                    {incoterms || "-"}
                  </Box>
                  <Tooltip title='More info about Incoterms' arrow>
                    <IconButton size='small' className='ml-1'>
                      <InfoOutlined fontSize='small' />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box className='flex h-8 items-center'>
                  <Box component='span' className='text-gray-900'>
                    Freight Terms:
                  </Box>
                  &nbsp;
                  <Box component='span' className='text-gray-700'>
                    {freightTerms || "-"}
                  </Box>
                  <Tooltip title='Terms can be changed later' arrow>
                    <IconButton size='small' className='ml-1'>
                      <InfoOutlined fontSize='small' />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box className='flex h-8 items-center'>
                  <Box component='span' className='text-gray-900'>
                    Incoterms Location:
                  </Box>
                  &nbsp;
                  <Box component='span' className='text-gray-700'>
                    {incotermsLocation ? formatLocationAddress(incotermsLocation) : "-"}
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Typography variant='body1' fontWeight={700} className='text-gray-700'>
                Shipping Information
              </Typography>
              <FormProvider {...methods}>
                <Form className='space-y-4'>
                  <FieldRow>
                    <FormField
                      name='shipTo'
                      label='Ship To'
                      required
                      render={({ field, fieldState }) => (
                        <SelectField
                          field={field}
                          fieldState={fieldState}
                          options={shipToOptions}
                          loadMore={loadMore}
                          fetching={shipToLocationListFetching}
                        />
                      )}
                    />
                  </FieldRow>
                </Form>
              </FormProvider>
            </Box>
          </DialogContent>
          <DialogActions className='justify-between p-10'>
            <Button onClick={handleClose} appearance='outlined'>
              Cancel
            </Button>
            <Box className='flex items-center space-x-4'>
              <Button
                type='submit'
                onClick={methods.handleSubmit(handleFormSubmit)}
                variant='primary'
                disabled={!methods.formState.isValid || fetching}
                endIcon={
                  fetching ? (
                    <Box className='flex items-center'>
                      <CircularProgress size={16} className={fetching ? "text-white disabled:text-primary" : ""} />
                      &nbsp;
                    </Box>
                  ) : null
                }
              >
                Continue
              </Button>
            </Box>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
