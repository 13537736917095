import { useCallback } from "react"
import { useQuery } from "urql"

import type { PackagingInfoFormData } from "./types"

import { ProductPackagingInformationDocument } from "@/graphql/codegen/graphql"

interface UsePackagingInfoParams {
  productId: number
}

interface UsePackagingInfoResult {
  data: PackagingInfoFormData | null
  loading: boolean
  error: Error | null
  fetch: (params: UsePackagingInfoParams) => void
}

const transformToFormData = (data: any): PackagingInfoFormData | null => {
  if (!data) return null

  return {
    freightItem: data.freightItem || null,
    freightClass: data.freightClass || null,
    packagingType: data.packagingType || "",
    packageWeight: String(data.packageWeight || ""),
    packageWeightUom: data.packageWeightUom || "lb",
    packageLength: data.packageLength ? String(data.packageLength) : undefined,
    packageWidth: data.packageWidth ? String(data.packageWidth) : undefined,
    packageHeight: data.packageHeight ? String(data.packageHeight) : undefined,
    packageDimensionsUom: data.packageDimensionsUom || "in",
    palletWeight: String(data.palletWeight || ""),
    palletWeightUom: data.palletWeightUom || "lb",
    palletSizeLength: data.palletSizeLength ? String(data.palletSizeLength) : undefined,
    palletSizeWidth: data.palletSizeWidth ? String(data.palletSizeWidth) : undefined,
    palletSizeHeight: data.palletSizeHeight ? String(data.palletSizeHeight) : undefined,
    palletSizeUom: data.palletSizeUom || "in",
    packagesPerPallet: data.packagesPerPallet ? String(data.packagesPerPallet) : undefined,
  }
}

export const usePackagingInfo = ({ productId }: UsePackagingInfoParams): UsePackagingInfoResult => {
  const [{ data, fetching: loading, error }, reexecuteQuery] = useQuery({
    query: ProductPackagingInformationDocument,
    variables: { productId },
    pause: !productId,
  })

  const fetch = useCallback(
    (params: UsePackagingInfoParams) => {
      reexecuteQuery({ requestPolicy: "network-only", variables: { productId: params.productId } })
    },
    [reexecuteQuery]
  )

  const productData = data?.product?.get.__typename === "ProductGetSuccess" ? data.product.get.product : null

  return {
    data: transformToFormData(productData),
    loading,
    error: error || null,
    fetch,
  }
}
