import { Box, IconButton } from "@mui/material"
import { CloseOutlined, KeyboardArrowDownOutlined } from "@mui-symbols-material/w300"
import React from "react"

interface SelectIconWithClear {
  value: any
  onClear: () => void
  className?: string
}

const SelectIconWithClear: React.FC<SelectIconWithClear> = ({ value, onClear, className }) => {
  return (
    <Box className='relative'>
      {value && (
        <IconButton onClick={onClear} className='absolute right-9' sx={{ top: "-20px" }}>
          <CloseOutlined />
        </IconButton>
      )}
      <Box className={`-mt-0.5 flex items-center justify-center gap-1 ${className}`}>
        <KeyboardArrowDownOutlined />
      </Box>
    </Box>
  )
}

export default SelectIconWithClear
