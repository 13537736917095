import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from "@mui/material"
import { CloseOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import { useCallback } from "react"
import { useFormContext } from "react-hook-form"

import { ProductRegulatoryContent } from "./ProductRegulatoryContent"

import { Button } from "@/components/common/Button"

type RegulatoryFormData = {
  cassRegistryId: string
  unNumber: string
  isRohsCompliant: boolean
  isReachSvhcCompliant: boolean
}

type AddProductRegulatoryProps = {
  open: boolean
  isUpdate?: boolean
  isSubmitEnabled: boolean
  onClose: (action: "cancel" | "success" | "backdropClick" | "escapeKeyDown") => void
  onSubmit: (data: RegulatoryFormData) => void
}

const AddProductRegulatoryModal: FunctionComponent<AddProductRegulatoryProps> = ({
  open,
  isUpdate = false,
  isSubmitEnabled,
  onClose,
  onSubmit,
}) => {
  const { handleSubmit } = useFormContext<RegulatoryFormData>()

  const onSubmitHandler = useCallback(
    (value: RegulatoryFormData) => {
      onSubmit(value)
      onClose("success")
    },
    [onClose, onSubmit]
  )

  return (
    <Dialog open={open} onClose={(_, reason) => onClose(reason)} fullWidth>
      <DialogTitle className='pl-10'>
        <span className='font-normal text-primary'>{isUpdate ? "Edit" : "Add"} Regulatory Information</span>
      </DialogTitle>
      <IconButton aria-label='close' onClick={() => onClose("cancel")} className='absolute right-4 top-4 text-primary'>
        <CloseOutlined />
      </IconButton>
      <Divider />
      <DialogContent classes={{ root: "p-10 py-4" }}>
        <ProductRegulatoryContent />
      </DialogContent>
      <DialogActions className={"justify-between px-10 pb-10"}>
        <Button appearance='outlined' onClick={() => onClose("cancel")}>
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSubmitHandler)} disabled={!isSubmitEnabled}>
          {isUpdate ? "Update" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddProductRegulatoryModal
