import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { flexRender } from "@tanstack/react-table"
import type { FunctionComponent } from "react"
import React from "react"

import type { ContactLocation } from "@/graphql/codegen/graphql"
import LocationRolesDialog from "@/screens/Contacts/edit/components/LocationRolesDialog.tsx"
import { useContactRolesTable } from "@/screens/Contacts/edit/hooks/useContactRolesTable.tsx"

type AddContactLocationRolesProps = {
  open: boolean
  name: string
  contactId: string
  companyId: string
  companyName?: string
  onClose: (action: "cancel" | "success" | "backdropClick" | "escapeKeyDown") => void
  onSubmit: () => void
  contactRoles: ContactLocation[]
}

const AddContactLocationRoles: FunctionComponent<AddContactLocationRolesProps> = ({
  open,
  name,
  contactId,
  companyId,
  companyName,
  onClose,
  onSubmit,
  contactRoles,
}) => {
  const table = useContactRolesTable(contactRoles)

  return (
    <LocationRolesDialog
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      name={name}
      contactId={contactId}
      companyId={companyId}
      companyName={companyName}
    >
      {table.getRowModel().rows.length > 0 && (
        <>
          <Typography variant='body1' fontWeight={700} className='leading-6 tracking-[0.15px] text-gray-700'>
            Current Locations’ Roles
          </Typography>
          <div className='mb-6 flex size-full flex-col'>
            <div className='min-h-0 w-full flex-1 overflow-y-auto'>
              <TableContainer component={Paper} className='mt-2 px-3 shadow-none'>
                <Table>
                  <TableHead className='h-10'>
                    <TableRow>
                      {table.getHeaderGroups().map((headerGroup) =>
                        headerGroup.headers.map((header) => (
                          <TableCell
                            key={header.id}
                            className='h-[32px] border-b border-gray-500'
                            data-testid={header.id}
                            padding='none'
                          >
                            <Typography variant='subtitle2' className='text-gray-900'>
                              {header.isPlaceholder
                                ? null
                                : flexRender(header.column.columnDef.header, header.getContext())}
                            </Typography>
                          </TableCell>
                        ))
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody data-testid='tableBody' className='table-row-group'>
                    {table.getRowModel().rows.map((row) => (
                      <TableRow
                        key={row.id}
                        className={row.index === table.getRowModel().rows.length - 1 ? "" : "border-b border-gray-500"}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell
                            key={cell.id}
                            data-testid={`${row.id}-${cell.id}`}
                            padding='none'
                            className='h-[32px] border-0 text-gray-700'
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </>
      )}
      <Typography variant='body1' fontWeight={700} className='leading-6 tracking-[0.15px] text-gray-700'>
        Additional Locations’ Roles
      </Typography>
    </LocationRolesDialog>
  )
}

export default AddContactLocationRoles
