import { includedWithLabelLookup } from "../../constants"

import type { RelatedProductInstruction } from "@/graphql/codegen/graphql"

export type NormalizedProperties = {
  applicableTo: string
  transportationMode: string
  includedWith: string
}

type InstructionLike = {
  applicableTo?: RelatedProductInstruction["applicableTo"]
  transportationMode?: RelatedProductInstruction["transportationMode"]
  includedWith?: RelatedProductInstruction["includedWith"]
}

/**
 * Normalizes a comma-separated string value by trimming, sorting, and removing duplicates
 */
export const normalizeValue = (value: string | null | undefined): string =>
  (value ?? "")
    .split(",")
    .map((v) => v.trim())
    .filter(Boolean)
    .sort((a, b) => a.localeCompare(b))
    .join(", ")

/**
 * Normalizes instruction properties for consistent comparison
 */
export const normalizeProperties = (instruction: InstructionLike): NormalizedProperties => {
  const applicableTo = normalizeValue(instruction.applicableTo ?? "")
  const transportationMode = normalizeValue(instruction.transportationMode ?? "")
  const includedWith = instruction.includedWith ? includedWithLabelLookup[instruction.includedWith] : ""

  return {
    applicableTo,
    transportationMode,
    includedWith,
  }
}
