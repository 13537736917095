import { useCallback, useState } from "react"
import type { ReactNode } from "react"
import { useMutation, useQuery } from "urql"
import type { CombinedError } from "urql"

import type { ApiInstructionFormat, FormValues, InstructionInput } from "../types"
import { deleteInstructionGroup, formatInstructionsForApi, groupInstructions } from "../utils/instructionManagement"

import type {
  ApplicableToEnum,
  IncludeWithEnum,
  Mutation,
  ProductMutationUpdateArgs,
  ProductUpdateFailure,
  RelatedProductInstruction,
  TransportationModeEnum,
} from "@/graphql/codegen/graphql"
import { ProductRelatedInstructionsDocument, UpdateProductMutationDocument } from "@/graphql/codegen/graphql"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

interface UseInstructionsProps {
  productId: number
  onError: (message: string) => void
}

interface InstructionsHook {
  instructions: RelatedProductInstruction[]
  isLoading: boolean
  createInstructionsFromFormData: (formData: FormValues) => InstructionInput[]
  updateInstructions: (
    currentInstructions: RelatedProductInstruction[],
    newInstructions: InstructionInput[],
    isEditing: boolean
  ) => Promise<boolean>
  deleteInstructionGroup: (groupId: string) => Promise<boolean>
}

/**
 * Hook for managing product instructions state and operations
 */
export const useInstructions = ({ productId, onError }: UseInstructionsProps): InstructionsHook => {
  const [optimisticData, setOptimisticData] = useState<RelatedProductInstruction[]>([])
  const notify = useNotificationsStore(({ enqueueNotification }) => enqueueNotification)

  // Query current instructions
  const [{ data: queryResult, fetching }, fetchQuery] = useQuery({
    query: ProductRelatedInstructionsDocument,
    variables: { productId },
  })

  // Mutation for updating instructions
  const [, updateProduct] = useMutation<Pick<Mutation, "product">, ProductMutationUpdateArgs>(
    UpdateProductMutationDocument
  )

  const getCurrentInstructions = (
    queryResult: any,
    optimisticData: RelatedProductInstruction[]
  ): RelatedProductInstruction[] => {
    if (optimisticData.length > 0) return optimisticData
    if (queryResult?.product?.get.__typename === "ProductGetSuccess") {
      return queryResult.product.get.product.relatedProductInstructions
    }
    return []
  }

  // Get current instructions, preferring optimistic data if available
  const instructions = getCurrentInstructions(queryResult, optimisticData)

  // Handle API errors
  const handleError = useCallback(
    (error: CombinedError | null, result: any) => {
      const errorMessage =
        error?.message ??
        (result?.product?.update as ProductUpdateFailure)?.error?.message ??
        "An unknown error occurred"

      onError(errorMessage)
      notify({
        type: "error",
        content: `Failed to update instructions: ${errorMessage}` as ReactNode,
      })
      setOptimisticData([])
    },
    [notify, onError]
  )

  // Helper function to combine arrays
  const combineArrays = <T>(acc: T[][], arr: T[]): T[][] => acc.flatMap((x) => arr.map((y) => [...x, y]))

  // Function to compute the combinations
  const computeCombinations = <T>(...arrays: T[][]): T[][] => {
    return arrays.reduce((acc, arr) => combineArrays(acc, arr), [[]] as T[][])
  }

  const createInstructionsFromFormData = useCallback((formData: FormValues): InstructionInput[] => {
    const groupId = formData.groupId || crypto.randomUUID()

    type InstructionTuple = [ApplicableToEnum, TransportationModeEnum, IncludeWithEnum, string]
    const combinations = computeCombinations<ApplicableToEnum | TransportationModeEnum | IncludeWithEnum | string>(
      formData.applicableTo,
      formData.transportationMode,
      formData.includedWith,
      formData.productInstructionId
    ) as InstructionTuple[]

    return combinations.map(([applicableTo, transportationMode, includedWith, productInstructionId]) => ({
      applicableTo,
      transportationMode,
      includedWith,
      productInstructionId,
      groupId,
      createdOn: new Date().toISOString(),
    }))
  }, [])

  const handleApiUpdate = async (formattedInstructions: ApiInstructionFormat[]) => {
    const { error, data: result } = await updateProduct({
      input: {
        productId,
        relatedProductInstructions: formattedInstructions,
      },
    })

    if (error || (result?.product.update as ProductUpdateFailure)?.error) {
      handleError(error || null, result)
      return false
    }

    notify({
      type: "success",
      content: "Instructions successfully updated" as ReactNode,
    })

    // Refresh data
    fetchQuery({ variables: { productId } })
    setOptimisticData([])
    return true
  }

  // Update instructions
  const updateInstructions = useCallback(
    async (currentInstructions: RelatedProductInstruction[], newInstructions: InstructionInput[]) => {
      try {
        const updatedGroups = groupInstructions([
          ...currentInstructions,
          ...(newInstructions as RelatedProductInstruction[]),
        ])
        const formattedInstructions = formatInstructionsForApi(updatedGroups)
        const success = await handleApiUpdate(formattedInstructions)
        return success
      } catch (err) {
        console.error("Error updating instructions:", err)
        notify({
          type: "error",
          content: "An unexpected error occurred while updating instructions" as ReactNode,
        })
        setOptimisticData([])
        return false
      }
    },
    [productId, notify, updateProduct, fetchQuery, handleError]
  )

  // Delete instruction group
  const handleDeleteInstructionGroup = useCallback(
    async (groupId: string) => {
      try {
        const currentGroups = groupInstructions(instructions)
        const updatedGroups = deleteInstructionGroup(currentGroups, groupId)
        const formattedInstructions = formatInstructionsForApi(updatedGroups)
        const success = await handleApiUpdate(formattedInstructions)
        return success
      } catch (err) {
        console.error("Error deleting instruction group:", err)
        notify({
          type: "error",
          content: "An unexpected error occurred while deleting instruction group" as ReactNode,
        })
        setOptimisticData([])
        return false
      }
    },
    [productId, instructions, notify, updateProduct, fetchQuery, handleError]
  )

  return {
    instructions,
    isLoading: fetching,
    createInstructionsFromFormData,
    updateInstructions,
    deleteInstructionGroup: handleDeleteInstructionGroup,
  }
}
